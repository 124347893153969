import { IObject } from "nebula-galaxy";

export const roles = [
  { id: "owner", name: "Owner" },
  { id: "admin", name: "Admin" },
  { id: "projectLead", name: "Project Lead" },
  { id: "developer", name: "Developer" },
  { id: "financeManager", name: "Finance Manager" },
  { id: "viewer", name: "Viewer" },
];

export const statusMap: IObject = {
  "": "Neutral",
  accepted: "Success",
  active: "Success",
  success: "Success",
  granted: "Success",
  pending: "Warning",
  inactive: "Critical",
  denied: "Critical",
  stopped: "Critical",
  fail: "Critical",
  shutdown: "Critical",
  failed: "Critical",
  init: "Neutral",
  in_progress: "Warning",
  "1 of 2 operational": "Warning",
  "2 of 2 down": "Critical",
  "2 of 2 operational": "Success",
};

export const textMap: IObject = {
  init: "Initialized",
  fail: "Failed",
  in_progress: "In Progress",
  success: "Active",
};

export const closeAccountReasons = [
  "Nebula services are expensive.",
  "I am not satisfied with Nebula's services and performance.",
  "I am migrating to a different provider.",
  "I am not satisfied with Nebula's security measures.",
  "I have completed my project.",
  "Others",
];
