export const migrationGuides = [
  {
    icon: "Zap",
    title: "Getting started with Nebula",
    description:
      "Detailed guides on the steps to take to get started with Nebula.",
    link: "https://docs.usenebula.io/get-started-with-nebula",
    linkText: "Read guides",
  },
  {
    icon: "ArrowLeftRight",
    title: "Migrate your services to Nebula",
    description:
      "Explore our comprehensive resources for migrating your data and infrastructure to Nebula.",
    link: "https://docs.usenebula.io/migrate-to-nebula",
    linkText: "Learn more",
  },
  {
    icon: "Play",
    title: "Watch product tutorials",
    description:
      "Access a library of product tutorials for the Nebula product suite to help with key concepts.",
    link: "https://www.youtube.com/@Usenebula_HQ",
    linkText: "Watch tutorials",
  },
  {
    icon: "Mail",
    title: "Contact support",
    description:
      "Need help? Contact our support team through our support desk.",
    link: "mailto:support@usenebula.io",
    linkText: "Contact support",
  },
];

export const additionalResources = [
  {
    icon: "BookOpen",
    title: "Nebula docs",
    link: "https://docs.usenebula.io",
  },
  {
    icon: "HardDrive",
    title: "Status",
    link: "https://status.usenebula.io/",
  },
];
