import { IObject } from "nebula-galaxy";
import { api } from "config";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";

export const fetchMembers = async (body?: IObject) => {
  return await api({ url: "users/search", body });
};

export const fetchInvitedUsers = async (body: IObject) => {
  return await api({ url: "users/invitation/search", body });
};

export const fetchMember = async (id: string) => {
  return await api({ url: `users/${id}`, method: "GET" });
};

export const updateProjects = async (
  body: IObject,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: "access-manager/users/role",
    method: "PUT",
    body,
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const removeMemberFromProject = async (
  { projectId, userId }: { projectId: string; userId: string },
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `access-manager/projects/${projectId}/users/${userId}`,
    method: "DELETE",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const removeMemberFromOrganization = async (
  { userId }: { userId: string },
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `access-manager/users/${userId}`,
    method: "DELETE",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const removeInvitedUser = async (
  { inviteId }: { inviteId: string },
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `users/invitation/${inviteId}`,
    method: "DELETE",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};
