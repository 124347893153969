import cn from "classnames";

import { IObject } from "nebula-galaxy";

import { getFormValues, getMonthlyEstimate } from "utils/functions";

import styles from "./create-instance.module.scss";

interface CreateInstanceSummaryProps {
  computeDetails: IObject;
  formData: IObject;
}

const CreateInstanceSummary = ({
  computeDetails,
  formData,
}: CreateInstanceSummaryProps) => {
  const { os, instance } = computeDetails || {};

  const { serverName, operatingSystem, osId, instanceId } =
    getFormValues(formData);

  const currentOS = os?.find((item: IObject) => item._id === operatingSystem);
  const osDetails = currentOS?.options?.find(
    (item: IObject) => item.osId === osId
  );
  const instanceDetails = instance?.find(
    (item: IObject) => item.instanceId === instanceId
  );

  return (
    <div className={styles.instanceSummary}>
      <div className={styles.instanceSummaryHeader}>
        <span className="p3 medium">Summary</span>
      </div>
      <div className={styles.instanceSummaryContent}>
        <div className={styles.instanceSummaryItem}>
          <span className={cn("cap1", styles.summaryItemTitle)}>
            Instance name
          </span>
          <span className={cn("p5", styles.summaryItemDescription)}>
            {serverName || "-"}
          </span>
        </div>

        <div className={styles.instanceSummaryItem}>
          <span className={cn("cap1", styles.summaryItemTitle)}>
            Software image
          </span>
          <span className={cn("p5", styles.summaryItemDescription)}>
            {osDetails?.name || "-"}
          </span>
        </div>

        <div className={styles.instanceSummaryItem}>
          <span className={cn("cap1", styles.summaryItemTitle)}>
            Instance type
          </span>
          {instanceId ? (
            <>
              <span className={cn("p5", styles.summaryItemDescription)}>
                {instanceId} - ₦{instanceDetails?.cost} per hour
              </span>

              <ul className={cn("p5", styles.summaryFeatureList)}>
                <li>
                  vCPU - <span>{instanceDetails?.vCPU}</span>
                </li>
                <li>
                  Memory (GiB) - <span>{instanceDetails?.memory}</span>
                </li>
                <li>
                  Bandwidth
                  <span>{instanceDetails?.bandwidth}</span>
                </li>
              </ul>
            </>
          ) : (
            <span className={cn("p5", styles.summaryItemDescription)}>-</span>
          )}
        </div>

        <div className={styles.instanceSummaryItem}>
          <span className={cn("cap1", styles.summaryItemTitle)}>
            Monthly estimate
          </span>
          <span className={cn("p2 medium", styles.summaryItemTotal)}>
            ₦{getMonthlyEstimate(instanceDetails?.cost)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreateInstanceSummary;
