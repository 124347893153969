import type { IObject } from "nebula-galaxy";

import { countriesWithStates, months } from "../data";

export const generateCountriesOptions = () =>
  countriesWithStates.map(({ name }) => ({
    value: name,
    label: name,
  }));

export const generateStatesOptions = (country: string) =>
  countriesWithStates
    .find(({ name }) => name === country)
    ?.states.map((state) => ({
      value: state.name,
      label: state.name,
    })) || [];

export const generateMonthsOptions = () =>
  months.map(({ label, value }) => ({
    label: `${label} ${new Date().getFullYear()}`,
    value,
  }));

export const generateSelectOptions = (
  data: IObject[],
  value: string,
  label: string
) =>
  data.map((d) => ({
    value: d[value],
    label: d[label],
  }));

export const generateSoftwareImageOptions = (osId: string, data: IObject[]) => {
  const currentOS = data.find(({ _id }) => _id === osId);

  return currentOS?.options?.map((item: IObject) => ({
    label: item.name,
    value: item.osId,
  }));
};

export const removeKeysWithEmptyValues = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") delete obj[key]
  })
  return obj
}
