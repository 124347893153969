import type { ReactNode } from "react";
import cn from "classnames";

import MiniPageLoader from "../mini-page-loader";

import styles from "./details-page-content.module.scss";

type Step = {
  label: ReactNode;
  url?: string;
  onClick?: () => void;
  urlComponent?: (step: Step) => ReactNode;
  disabled?: boolean;
};

interface DetailsPageContentProps {
  className?: string;
  children: ReactNode;
  isLoading?: boolean;
}

const DetailsPageContent = ({
  className,
  children,
  isLoading,
}: DetailsPageContentProps) => (
  <div className={cn(className, styles.container)}>
    {isLoading ? <MiniPageLoader /> : children}
  </div>
);

export default DetailsPageContent;
