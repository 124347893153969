import { useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { Button, StatusChip } from "nebula-galaxy";

import { routes } from "config";
import { useAuth } from "context";
import { VirtualMachineIcon, StorageIcon, DatabaseIcon } from "assets";

import CreateInstance from "../neb-compute/instances/create-instance";
import CreateBucket from "../neb-store/buckets/create-bucket";

import styles from "./overview.module.scss";

const Features = () => {
  const navigate = useNavigate();
  const [isInstanceFormActive, setIsInstanceFormActive] = useState(false);
  const [isBucketFormActive, setIsBucketFormActive] = useState(false);

  const { isLoading: isLoadingRegionProviders, regionProviders } = useAuth();

  return (
    <div className={styles.features}>
      <div className={styles.featureItem}>
        <span className={styles.featureItemIcon}>
          <VirtualMachineIcon />
        </span>
        <div>
          <h6 className={cn("medium", styles.featureItemTitle)}>
            Launch virtual machines
          </h6>
          <span className={cn("p5", styles.featureItemDescription)}>
            Provision and manage virtual servers for your applications and
            workloads.
          </span>

          <Button
            className={styles.featureItemAction}
            variant="PrimaryLink"
            size="MD"
            rightIcon="ArrowRight"
            onClick={(e) => {
              e.stopPropagation();
              setIsInstanceFormActive(true);
            }}
            disabled={isLoadingRegionProviders}
          >
            Create an instance
          </Button>
        </div>
      </div>

      <div className={styles.featureItem}>
        <span className={styles.featureItemIcon}>
          <StorageIcon />
        </span>
        <div>
          <h6 className={cn("medium", styles.featureItemTitle)}>
            Scale your storage
          </h6>
          <span className={cn("p5", styles.featureItemDescription)}>
            Store and organize your data securely with scalable storage
            solutions.
          </span>

          <Button
            className={styles.featureItemAction}
            variant="PrimaryLink"
            size="MD"
            rightIcon="ArrowRight"
            onClick={(e) => {
              e.stopPropagation();
              setIsBucketFormActive(true);
            }}
            disabled={isLoadingRegionProviders}
          >
            Create a bucket
          </Button>
        </div>
      </div>

      <div className={cn(styles.featureItem, styles.featureItemDisabled)}>
        <StatusChip
          className={styles.featureItemComing}
          variant="Informational"
          size="SM"
          text="COMING SOON"
        />

        <span className={styles.featureItemIcon}>
          <DatabaseIcon />
        </span>
        <div>
          <h6 className={cn("medium", styles.featureItemTitle)}>
            Optimize your database
          </h6>
          <span className={cn("p5", styles.featureItemDescription)}>
            Manage, query, and optimize databases for efficient data storage and
            retrieval.
          </span>
        </div>
      </div>

      {isInstanceFormActive && (
        <CreateInstance
          queryKey={"instances"}
          providerId={regionProviders?.[0]?.providerId}
          close={() => setIsInstanceFormActive(false)}
          handleOnSuccess={() => navigate(routes.console.nebCompute.instances)}
        />
      )}

      {isBucketFormActive && (
        <CreateBucket
          queryKey={"buckets"}
          providerId={regionProviders?.[0]?.providerId}
          close={() => setIsBucketFormActive(false)}
          handleOnSuccess={() => navigate(routes.console.nebStore)}
        />
      )}
    </div>
  );
};

export default Features;
