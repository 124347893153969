import { useState } from "react";
import cn from "classnames";
import { Button, Divider, IObject, Icon, Popper } from "nebula-galaxy";

import { useAuth, useProjects } from "context";

import styles from "./navigation.module.scss";

interface ProjectSelectorProps {
  openProjectForm: () => void;
}

const ProjectSelector = ({ openProjectForm }: ProjectSelectorProps) => {
  const { userProfile } = useAuth();
  const { setCurrentProject } = useProjects();
  const [isActive, setIsActive] = useState(false);

  const { project, projects } = userProfile || {};
  const { name } = project || {};

  return (
    <>
      <Popper
        className={styles.projectSelectorPopper}
        anchorElement={
          <button
            className={styles.selector}
            onClick={() => setIsActive(!isActive)}
          >
            <div className={styles.selectorDisplay}>
              <span className={styles.projectAvatar}>
                <Icon name="Layers" size="SM" />
              </span>
              <span className={cn("p5", styles.selectorDisplayText)}>
                {name}
              </span>
            </div>

            <Icon className={styles.selectorDisplayIcon} name="ChevronDown" />
          </button>
        }
        isOpen={isActive}
        onClose={() => setIsActive(false)}
      >
        <div className={styles.selectorMenu}>
          <ul className={styles.selectorMenuList}>
            {projects?.map(({ projectId, name }: IObject) => (
              <li key={`project-${projectId}`}>
                <button
                  className={cn("p5", styles.selectorMenuItem)}
                  onClick={() => {
                    setCurrentProject(projectId);
                    setIsActive(false);
                  }}
                >
                  {name}
                </button>
              </li>
            ))}
          </ul>

          <Divider className={styles.divider} />

          <Button
            className={styles.selectorMenuAction}
            leftIcon="Plus"
            size="SM"
            onClick={openProjectForm}
          >
            Add new Project
          </Button>
        </div>
      </Popper>
    </>
  );
};

export default ProjectSelector;
