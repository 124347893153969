import { ChangeEvent, FormEvent, useState } from "react";
import cn from "classnames";

import {
  SelectEvent,
  IObject,
  Icon,
  Callout,
  Radio,
  StatusChip,
} from "nebula-galaxy";
import { Button, Select, Divider } from "nebula-galaxy";

import { generateSelectOptions, getFormValues } from "utils/functions";
import { CreateKeyPair } from "../../key-pairs";
import styles from "./create-instance.module.scss";
import { useAuth } from "context";

interface NetworkSecurityProps {
  computeDetails: IObject;
  formData: IObject;
  goBack: () => void;
  submit: (e: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid?: boolean
  ) => void;
}

const NetworkSecurity = ({
  computeDetails,
  formData,
  goBack,
  submit,
  isLoading,
  handleOnChange,
}: NetworkSecurityProps) => {
  const [isKeyPairFormActive, setIsKeyPairFormActive] = useState(false);

  const { vpc, subnet } = computeDetails || {};
  const { vpcId, subnetId } = getFormValues(formData);

  const { isLoading: isLoadingRegionProviders, regionProviders } =
  useAuth();

  return (
    <form onSubmit={submit} className={styles.instanceForm}>
      <div className={styles.instanceFormGroup}>
        <div className={styles.instanceFormHeader}>
          <div>
            <span className={cn("p4 medium", styles.formHeaderTitle)}>VPC</span>
            <span className={cn("p5", styles.formHeaderDescription)}>
              Select the VPC where you want to launch your instance.
            </span>
          </div>

          {/* <Button
            className={styles.formHeaderHelp}
            variant="TertiaryIcon"
            size="SM"
          >
            <Icon name="CircleHelp" />
          </Button> */}
        </div>

        <Select
          label="VPC"
          placeholder="Choose option"
          name="vpc"
          value={vpcId}
          onChange={handleOnChange}
          options={generateSelectOptions(vpc, "vpcId", "name")}
          required
          disabled
        />
      </div>

      <Divider className={styles.divider} />

      <div className={styles.instanceFormGroup}>
        <div className={styles.instanceFormHeader}>
          <div>
            <span className={cn("p4 medium", styles.formHeaderTitle)}>
              Subnet
            </span>
            <span className={cn("p5", styles.formHeaderDescription)}>
              Choose the subnet where the network interface will be located.
            </span>
          </div>

          {/* <Button
            className={styles.formHeaderHelp}
            variant="TertiaryIcon"
            size="SM"
          >
            <Icon name="CircleHelp" />
          </Button> */}
        </div>

        <Select
          label="Subnet"
          placeholder="Choose option"
          name="subnet"
          value={subnetId}
          onChange={handleOnChange}
          options={generateSelectOptions(subnet, "subnetId", "name")}
          required
          disabled
        />
      </div>

      <Divider className={styles.divider} />

      <div className={styles.instanceFormGroup}>
        <div className={styles.instanceFormHeader}>
          <div>
            <div className={styles.formHeaderTitle}>
              <span className="p4 medium">Security group</span>

              <StatusChip
                variant="Informational"
                text="COMING SOON"
                size="SM"
              />
            </div>
            <span className={cn("p5", styles.formHeaderDescription)}>
              Use security group as a firewall to manage access to and from your
              instance. Create a new security group if you don't already have
              one.
            </span>
            {/* <Button variant="PrimaryLink" rightIcon="ArrowUpRight" size="MD">
            Create security group
          </Button> */}
          </div>

          {/* <Button
            className={styles.formHeaderHelp}
            variant="TertiaryIcon"
            size="SM"
          >
            <Icon name="CircleHelp" />
          </Button> */}
        </div>
      </div>

      <Divider className={styles.divider} />

      <div className={styles.instanceFormGroup}>
        <div className={styles.instanceFormHeader}>
          <div>
            <div className={styles.formHeaderTitle}>
              <span className="p4 medium">Key pair login</span>

              <StatusChip
                variant="Informational"
                text="COMING SOON"
                size="SM"
              />
            </div>
            <span className={cn("p5", styles.formHeaderDescription)}>
              Your key pair is a unique identity that grants you access to your
              instance. Create a new key pair if you don't already have one.
            </span>
            <Button
            variant="PrimaryLink"
            size="MD"
            onClick={() => setIsKeyPairFormActive(true)}
            disabled={
              isLoadingRegionProviders || !regionProviders?.length
            }
          >
            Create key pair
          </Button>
          </div>

          {/* <Button
            className={styles.formHeaderHelp}
            variant="TertiaryIcon"
            size="SM"
          >
            <Icon name="CircleHelp" />
          </Button> */}
        </div>
      </div>

      {/* <Divider className={styles.divider} />

      <div className={styles.instanceFormHeader}>
        <div>
          <span className={cn("p4 medium", styles.formHeaderTitle)}>
            Auto-assign public IP
          </span>
          <span className={cn("p5", styles.formHeaderDescription)}>
            Automatically assign an IP address to the primary network interface
            of your instance.
          </span>
        </div>

        <Button
          className={styles.formHeaderHelp}
          variant="TertiaryIcon"
          size="SM"
        >
          <Icon name="CircleHelp" />
        </Button>
      </div>

      <div className={cn(styles.inputsWrapper, styles.noMargin)}>
        <Radio
          size="MD"
          name="autoAssignIP"
          value="enable"
          label={<span className={cn("p5", styles.radioLabel)}>Enable</span>}
          onChange={handleOnChange}
        />
        <Radio
          size="MD"
          name="autoAssignIP"
          value="disable"
          label={<span className={cn("p5", styles.radioLabel)}>Disable</span>}
          onChange={handleOnChange}
        />
      </div> */}

      <div className={styles.instanceFormActions}>
        <Button variant="Tertiary" size="SM" onClick={goBack}>
          Previous
        </Button>
        <Button size="SM" type="submit" loading={isLoading}>
          Launch server
        </Button>
      </div>

      <CreateKeyPair
        active={isKeyPairFormActive}
        providerId={regionProviders?.[0]?.providerId}
        close={() => setIsKeyPairFormActive(false)}
      />
    </form>
  );
};

export default NetworkSecurity;
