const VirtualMachine = () => {
  return (
    <svg
      width="68"
      height="79"
      viewBox="0 0 68 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.2595 77.5192C29.2595 77.5192 29.2595 77.5192 29.2595 77.5192L1.53882 62.8005C0.843581 62.4305 0.471376 61.8203 0.501741 61.3408L0.502741 61.325L0.502741 61.3092L0.502569 46.809H65.7322V61.3092C65.7322 61.8563 65.4375 62.4171 64.813 62.7503L37.0066 77.5192C37.0065 77.5192 37.0065 77.5192 37.0065 77.5192C34.6131 78.7899 31.6529 78.7899 29.2595 77.5192Z"
        fill="currentColor"
        stroke="#A277FF"
      />
      <ellipse
        cx="61.8436"
        cy="57.0638"
        rx="1.19692"
        ry="1.195"
        fill="#03D74A"
      />
      <ellipse
        cx="57.8537"
        cy="59.454"
        rx="1.19692"
        ry="1.195"
        fill="#03D74A"
      />
      <path
        d="M64.797 47.8741L64.7969 47.8741L36.9212 64.0677C36.9212 64.0677 36.9212 64.0678 36.9211 64.0678C34.5301 65.4562 31.5782 65.4562 29.1872 64.0677L1.39785 47.9295C0.20503 47.2366 0.20503 45.5098 1.39785 44.8169L1.39788 44.8169L29.7763 28.3309C31.9396 27.0767 34.601 27.0767 36.7643 28.3309L64.797 44.6114L65.0481 44.179L64.797 44.6114C66.0448 45.3359 66.0448 47.1496 64.797 47.8741Z"
        fill="#F6EDFF"
        stroke="#8957EF"
      />
      <path
        d="M29.2595 63.8789C29.2595 63.8789 29.2595 63.8789 29.2595 63.8789L1.53882 49.1602C0.843581 48.7903 0.471376 48.18 0.501741 47.7005L0.502741 47.6847L0.502741 47.6689L0.502569 33.1687H65.7322V47.6689C65.7322 48.216 65.4375 48.7768 64.813 49.11L37.0066 63.8789C37.0065 63.8789 37.0065 63.8789 37.0065 63.8789C34.6131 65.1496 31.6529 65.1496 29.2595 63.8789Z"
        fill="currentColor"
        stroke="#A277FF"
      />
      <ellipse
        cx="61.8436"
        cy="43.424"
        rx="1.19692"
        ry="1.195"
        fill="#03D74A"
      />
      <ellipse
        cx="57.8537"
        cy="45.8138"
        rx="1.19692"
        ry="1.195"
        fill="#03D74A"
      />
      <path
        d="M64.7968 34.2335L64.7967 34.2336L36.921 50.4272C36.9209 50.4272 36.9209 50.4272 36.9209 50.4272C34.5298 51.8156 31.578 51.8156 29.1869 50.4272L1.39761 34.2889C0.204785 33.596 0.204785 31.8692 1.39761 31.1764L1.39763 31.1764L29.7761 14.6904C31.9393 13.4361 34.6008 13.4361 36.7641 14.6904L64.7967 30.9708L65.0479 30.5384L64.7968 30.9708C66.0445 31.6953 66.0445 33.509 64.7968 34.2335Z"
        fill="#F6EDFF"
        stroke="#8957EF"
      />
      <path
        d="M29.2595 50.6331C29.2595 50.6331 29.2595 50.6331 29.2595 50.6331L1.53882 35.9144C0.843581 35.5445 0.471376 34.9342 0.501741 34.4547L0.502741 34.4389L0.502741 34.4231L0.502569 19.9229H65.7322V34.4231C65.7322 34.9702 65.4375 35.531 64.813 35.8642L37.0066 50.6331C37.0065 50.6331 37.0065 50.6331 37.0065 50.6331C34.6131 51.9038 31.6529 51.9038 29.2595 50.6331Z"
        fill="currentColor"
        stroke="#A277FF"
      />
      <ellipse
        cx="61.8436"
        cy="30.1782"
        rx="1.19692"
        ry="1.195"
        fill="#03D74A"
      />
      <ellipse
        cx="57.8537"
        cy="32.568"
        rx="1.19692"
        ry="1.195"
        fill="#03D74A"
      />
      <path
        d="M64.7962 20.9883L64.7961 20.9884L36.9204 37.1819C36.9203 37.182 36.9203 37.182 36.9203 37.182C34.5292 38.5704 31.5773 38.5704 29.1863 37.182L1.397 21.0437C0.204175 20.3508 0.204175 18.624 1.397 17.9312L1.39702 17.9311L29.7754 1.44516C31.9387 0.190888 34.6002 0.190888 36.7635 1.44516L64.7961 17.7256L65.0472 17.2932L64.7962 17.7256C66.0439 18.4501 66.0439 20.2638 64.7962 20.9883Z"
        fill="currentColor"
        stroke="#A277FF"
      />
      <path
        d="M28.6198 16.5164L24.9189 19.2049L38.3904 22.4716L42.0913 19.7831L28.6198 16.5164Z"
        fill="#03D74A"
      />
      <path
        d="M29.5658 25.4431L31.1937 27.6841L33.4458 26.048L31.8178 23.8071L29.5658 25.4431Z"
        fill="#03D74A"
      />
      <path
        d="M33.7174 12.8359L35.3454 15.0769L37.5975 13.4408L35.9695 11.1999L33.7174 12.8359Z"
        fill="#03D74A"
      />
    </svg>
  );
};

export default VirtualMachine;
