import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import type { IObject } from "nebula-galaxy";
import { Button, Table, Modal } from "nebula-galaxy";

import { fetchMembers, removeMemberFromOrganization } from "services";
import { useAlert, useAuth } from "context";
import { routes } from "config";
import { useReactQuery } from "hooks";

import { membersColumns } from "./columns";

import type { QueryParams } from "utils/types";
import { pluralize } from "utils/functions";
import { defaultQueryParams } from "utils/consts";

import styles from "./access-manager.module.scss";

interface MembersProps {
  queryKey: string;
  setIsFormActive: (e: boolean) => void;
}

const Members = ({ queryKey, setIsFormActive }: MembersProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const { userProfile } = useAuth();

  const [isRemoveMemberActive, setIsRemoveMemberActive] = useState(false);
  const [selectedMember, setSelectedMember] = useState<IObject | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    ...defaultQueryParams,
  });
  const { data, isLoading } = useReactQuery({
    queryKey,
    queryParams,
    queryFunction: () => fetchMembers(queryParams),
  });

  const onSuccess = (message: string) => {
    queryClient.invalidateQueries(queryKey);
    setIsRemoveMemberActive(false);
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsUpdating(false);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsUpdating(false);
  };

  const handleRemoveMember = async (userId: string) => {
    setIsUpdating(true);
    removeMemberFromOrganization({ userId }, onSuccess, onError);
  };

  return (
    <>
      <Table
        className={styles.membersTable}
        columns={membersColumns}
        data={data?.docs}
        loading={isLoading}
        pagination={data?.pagination}
        loadData={setQueryParams}
        onRowClick={({ userId }) =>
          navigate(`${routes.settings.accessManager}/${userId}`)
        }
        rowActions={(member) =>
          member.userId !== userProfile?.userId && (
            <Button
              variant="Secondary"
              size="SM"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedMember(member);
                setIsRemoveMemberActive(true);
              }}
            >
              Remove member
            </Button>
          )
        }
        toolbarActions={
          <Button
            leftIcon="Plus"
            size="SM"
            onClick={() => setIsFormActive(true)}
          >
            Invite member
          </Button>
        }
        searchPlaceholder="Search member name or email"
        dataText={pluralize("member", data?.docs?.length)}
      />

      <Modal
        active={isRemoveMemberActive}
        onClose={() => setIsRemoveMemberActive(false)}
        variant="Warning"
        title={`Remove ${selectedMember?.fullName}`}
        actions={
          <>
            <Button
              variant="Tertiary"
              size="SM"
              onClick={() => setIsRemoveMemberActive(false)}
            >
              Cancel
            </Button>

            <Button
              variant="Destructive"
              size="SM"
              onClick={() => handleRemoveMember(selectedMember?.userId)}
              loading={isUpdating}
            >
              Remove member
            </Button>
          </>
        }
      >
        <span className="p5">
          <span className="medium">{selectedMember?.fullName}'s</span> Nebula
          account will be permanently deleted. They will be removed from all
          shared projects and organization. This action is irreversible. Are you
          sure you want to proceed?
        </span>
      </Modal>
    </>
  );
};

export default Members;
