import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "utils/functions";

export type Theme = "dark" | "light";

type ThemeState = {
  theme: Theme;
  updateTheme: (_: Theme) => void;
};

export const initialState: ThemeState = {
  theme: "dark",
  updateTheme: (_: Theme) => {},
};

const ThemeContext = createContext(initialState);

const useTheme = () => {
  return useContext(ThemeContext);
};

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState(initialState.theme);

  const updateTheme = (value: Theme) => {
    setTheme(value);
    setLocalStorage("nebula-theme", value);
  };

  useEffect(() => {
    const savedTheme = getLocalStorage("nebula-theme");

    const checkSystemTheme = () => {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        setTheme("dark");
      } else {
        setTheme("light");
      }
    };

    savedTheme ? setTheme(savedTheme) : checkSystemTheme();

    const handleSystemThemeChange = (e: any) => {
      setTheme(e.matches ? "dark" : "light");
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", handleSystemThemeChange);

    return () => {
      mediaQuery.removeEventListener("change", handleSystemThemeChange);
    };
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      <div id="theme" className={`theme--${theme}`}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, useTheme };
