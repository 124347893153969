import type { IObject } from "nebula-galaxy";

export type API = {
  externalUrl?: string;
  url?: string;
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  body?: IObject | null;
  isAuthRequired?: boolean;
  token?: string;
  signal?: any;
  formData?: boolean;
};

export const api = async ({
  externalUrl,
  url,
  method = "POST",
  body,
  isAuthRequired = true,
  token,
  signal,
  formData,
}: API) => {
  const headers: HeadersInit = new Headers();
  const params: RequestInit = { method };

  const user = localStorage.getItem("nebula-user");
  const parsedUser = user ? JSON.parse(user) : {};

  const { accessToken, refreshToken } = parsedUser?.tokens || {};
  const bearerToken = token || accessToken;

  if (body) {
    params.body = (formData ? body : JSON.stringify(body)) as BodyInit;
  }

  if (signal) {
    params.signal = signal;
  }

  if (!formData) {
    headers.set("Content-Type", "application/json");
  }

  if (bearerToken) {
    headers.set("Authorization", `Bearer ${bearerToken}`);
  }

  params.headers = headers;

  const fetchUrl = externalUrl || `${process.env.REACT_APP_API_URL}${url}`;

  if ((isAuthRequired && bearerToken) || !isAuthRequired) {
    try {
      const response = await fetch(fetchUrl, params);
      const data = await response.json();

      if (data?.message === "jwt expired") {
        await retryWithRefreshToken({
          ...params,
          method: "POST",
          body: JSON.stringify({ refreshToken }),
        });
      } else {
        return data;
      }
    } catch (err: any) {
      if (err?.name === "AbortError") {
        return "Request Aborted ";
      }

      return { success: false };
    }
  }
};

const retryWithRefreshToken = async (params: RequestInit) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}auth/token`,
      params
    );
    const { success, data } = await response.json();

    if (success) {
      const user = localStorage.getItem("nebula-user");
      const parsedUser = user ? JSON.parse(user) : {};

      const newUser = {
        ...parsedUser,
        tokens: {
          ...parsedUser.tokens,
          accessToken: data,
        },
      };

      localStorage.setItem("nebula-user", JSON.stringify(newUser));
    } else {
      localStorage.removeItem("nebula-user");
    }

    window.location.reload();
    return { success };
  } catch (err: any) {
    if (err?.name === "AbortError") {
      return "Request Aborted ";
    }

    return { success: false };
  }
};
