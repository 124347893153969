import type { ChangeEvent } from "react";
import cn from "classnames";

import type { IObject, SelectEvent } from "nebula-galaxy";
import { Button, TextField, Select } from "nebula-galaxy";
import { Paystack } from "components";

import type { FormObject } from "utils/types";
import { getFormValues, getLocalUser, isFormValid } from "utils/functions";
import {
  currentProviderOptions,
  projectAudienceOptions,
  projectDetailsOptions,
} from "utils/data";

import styles from "../onboarding.module.scss";

interface OnboardingProjectProps {
  previousStep: () => void;
  nextStep: () => void;
  formData: FormObject;
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid: boolean
  ) => void;
  paymentRef: string;
  setPaymentRef: (paymentRef: string) => void;
}

const OnboardingProject = ({
  previousStep,
  nextStep,
  formData,
  handleOnChange,
  paymentRef,
  setPaymentRef,
}: OnboardingProjectProps) => {
  const { user } = getLocalUser();

  const { email } = user || {};

  const { name, details, audience, currentProvider } = getFormValues(
    formData
  ) as IObject;

  const onSuccess = ({ reference }: IObject) => {
    setPaymentRef(reference);
    nextStep();
  };

  const onClose = () => {};

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <h5 className={cn("medium", styles.title)}>Add project details</h5>

      <div className={styles.inputs}>
        <TextField
          label="Project name"
          name="name"
          value={name}
          onChange={handleOnChange}
          footnote="This will be your first project in Nebula"
        />
        <Select
          label="What are you building?"
          name="details"
          options={projectDetailsOptions}
          value={details}
          onChange={handleOnChange}
        />
        <Select
          label="Who are you building it for?"
          name="audience"
          options={projectAudienceOptions}
          value={audience}
          onChange={handleOnChange}
        />
        <Select
          label="Current provider"
          name="currentProvider"
          options={currentProviderOptions}
          value={currentProvider}
          onChange={handleOnChange}
        />
      </div>

      <div className={styles.footer}>
        <Button variant="Tertiary" type="button" onClick={previousStep}>
          Previous
        </Button>

        {paymentRef ? (
          <Button
            type="submit"
            disabled={!isFormValid(formData)}
            onClick={nextStep}
          >
            Continue
          </Button>
        ) : (
          <Paystack
            disabled={!isFormValid(formData)}
            amount={5000}
            email={email}
            onSuccess={onSuccess}
            onClose={onClose}
            text="Continue"
          />
        )}
      </div>
    </form>
  );
};

export default OnboardingProject;
