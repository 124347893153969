import type { ChangeEvent } from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";

import { Button, Modal, PasswordTextField } from "nebula-galaxy";

import { useAlert } from "context";
import { changePassword } from "services";

import { getFormValues } from "utils/functions";

import styles from "./profile.module.scss";

const initialFormData = {
  oldPassword: {
    value: "",
    valid: false,
  },
  newPassword: {
    value: "",
    valid: false,
  },
  confirmPassword: {
    value: "",
    valid: false,
  },
};

interface ChangePasswordProps {
  active: boolean;
  close: () => void;
  onError: (message: string) => void;
}

const ChangePassword = ({ active, close, onError }: ChangePasswordProps) => {
  const queryClient = useQueryClient();
  const { clearAlert, showAlert } = useAlert();

  const [formData, setFormData] = useState({ ...initialFormData });
  const [isLoading, setIsLoading] = useState(false);

  const { oldPassword, newPassword, confirmPassword } = getFormValues(formData);

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    close();
    setFormData({ ...initialFormData });
    setIsLoading(false);
  };

  const submit = () => {
    clearAlert();
    setIsLoading(true);
    changePassword(
      { oldPassword, newPassword, confirmPassword },
      onSuccess,
      (message) => {
        onError(message);
        setIsLoading(false);
      }
    );
  };

  return (
    <Modal
      className={styles.changePasswordModal}
      active={active}
      onClose={close}
      title="Change password"
      actions={
        <>
          <Button variant="Tertiary" size="SM" onClick={close}>
            Cancel
          </Button>

          <Button
            size="SM"
            onClick={submit}
            disabled={!newPassword || newPassword !== confirmPassword}
            loading={isLoading}
          >
            Change password
          </Button>
        </>
      }
    >
      <div className={styles.inputs}>
        <PasswordTextField
          label="Old password"
          name="oldPassword"
          onChange={handleOnChange}
          required
        />
        <PasswordTextField
          label="New password"
          name="newPassword"
          onChange={handleOnChange}
          required
        />
        <PasswordTextField
          label="Confirm password"
          name="confirmPassword"
          onChange={handleOnChange}
          required
        />
      </div>
    </Modal>
  );
};

export default ChangePassword;
