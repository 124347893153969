import { CopyElement, IObject } from "nebula-galaxy";

import moment from "moment";

import styles from "./vpcs.module.scss";

export const vpcsColumn = [
  { key: "name", header: "Vpc Name" },
  {
    key: "vpcId",
    header: "ID",
    renderer: ({ vpcId }: IObject) =>
      vpcId ? (
        <CopyElement
          className={styles.copyInstanceId}
          element={vpcId}
          value={vpcId}
        />
      ) : (
        "Not available"
      ),
  },
  {
    key: "cidr",
    header: "IPV4 CIDR"
  },
  {
    key: "createdAt",
    header: "Date Created",
    renderer: ({ createdAt }: IObject) => (
        <>{moment(createdAt).format("MMM D, YYYY, h:mm A")}</>
    ),
  },
];
