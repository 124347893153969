import { CopyElement, IObject, StatusChip } from "nebula-galaxy";

import { statusMap, textMap } from "utils/data";

import styles from "./instances.module.scss";

export const instancesColumn = [
  { key: "serverName", header: "Instance name" },
  {
    key: "serverId",
    header: "Server ID",
    renderer: ({ serverId }: IObject) =>
      serverId ? (
        <CopyElement
          className={styles.copyInstanceId}
          element={serverId}
          value={serverId}
        />
      ) : (
        "Not available"
      ),
  },
  {
    key: "serverStatus",
    header: "Status",
    renderer: ({ serverStatus, instanceState }: IObject) => (
      <StatusChip
        variant={instanceState === 'DELETED' ? 'Neutral' : statusMap[serverStatus?.toLowerCase()]}
        text={instanceState === 'DELETED' ? 'No Data' : textMap[serverStatus?.toLowerCase()]}
      />
    ),
  },
  {
    key: "publicIPv4",
    header: "IP Address",
    renderer: ({ publicIPv4 }: IObject) =>
      publicIPv4 ? (
        <CopyElement
          className={styles.copyInstanceId}
          element={publicIPv4}
          value={publicIPv4}
        />
      ) : (
        "Not available"
      ),
  },
  {
    key: "instanceState",
    header: "State",
    renderer: ({ instanceState }: IObject) => (
      <StatusChip
        variant={statusMap[instanceState?.toLowerCase()]}
        text={instanceState?.toLowerCase() || "Not available"}
      />
    ),
  },
];
