import type { IObject } from "nebula-galaxy";
import { FormObject } from "../types";

export const getFormValues = (formObject: FormObject): IObject => {
  let formValues: IObject = {};

  Object.keys(formObject).forEach((key) => {
    formValues[key] =
      typeof formObject[key] === "string"
        ? formObject[key].value.trim()
        : formObject[key].value;
  });

  return formValues;
};

export const isFormValid = (formObject: FormObject): boolean => {
  return formObject && Object.values(formObject).every(({ valid }) => valid);
};
