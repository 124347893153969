import { IObject } from "nebula-galaxy";
import { api } from "config";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";

export const getSubnets = async (body: IObject) => {
  return await api({ url: "networks/subnets/search", body });
};

export const createSubnets = async (
  body: IObject,
  onSuccess: (message: string, serverId?: string) => void,
  onError: (message: string) => void
) => {
  const { data, success, message } = await api({
    url: "networks/subnets",
    body,
  });

  success
    ? onSuccess(message, data?.subnetId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const getSubnet = async (id: string) => {
  return await api({ url: `networks/subnets/${id}`, method: "GET" });
};

export const updateSubnet = async (
  subnetId: string,
  body: IObject,
  onSuccess: (message: string, subnetId?: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `networks/subnets/${subnetId}`,
    method: "PATCH",
    body,
  });

  success
    ? onSuccess(message, subnetId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const deleteSubnet = async (
  subnetId: string,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `networks/subnets/${subnetId}`,
    method: "DELETE",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};
