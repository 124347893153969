import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import { FullScreenOverlay } from "components";
import {
  Button,
  Callout,
  TextField,
  SelectEvent,
  Divider,
  Select,
} from "nebula-galaxy";

import { createSubnets } from "services";
import { useAlert } from "context";

import {
  getFormValues,
  isFormValid,
  removeKeysWithEmptyValues,
} from "utils/functions";
import { cidrBlockOptions } from "utils/data";

import styles from "./create-subnet.module.scss";

const initialFormData = {
  name: {
    value: "",
    valid: false,
  },
  description: {
    value: "",
    valid: true,
  },
  cidr1: {
    value: "",
    valid: false,
  },
  cidr2: {
    value: "",
    valid: false,
  },
  cidr3: {
    value: "",
    valid: false,
  },
  gatewayIp3: {
    value: "0",
    valid: true,
  },
  gatewayIp4: {
    value: "1",
    valid: true,
  },
};

interface CreateSubnetsProps {
  close: () => void;
  queryKey: string;
  vpcDetails: any;
}

const CreateSubnets = ({
  queryKey,
  close,
  vpcDetails,
}: CreateSubnetsProps) => {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { vpc } = vpcDetails;
  const { id } = useParams();
  const cidrBlock = vpc.cidr?.split(/[./]/) || [];

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    queryClient.invalidateQueries(queryKey);
    setFormData({ ...initialFormData });
    setIsLoading(false);
    close();
  };

  const onError = (message: string) => {
    setError(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsLoading(false);
  };
  const netMasks = cidrBlockOptions.map((option) => ({
    label: option,
    value: option,
  }));

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const { name, description, ...formDataValues } = getFormValues(formData);

    createSubnets(
      removeKeysWithEmptyValues({
        name,
        description,
        cidr: `${cidrBlock[0]}.${cidrBlock[1]}.${formDataValues.cidr1}.${formDataValues.cidr2}/${formDataValues.cidr3}`,
        vpc: id,
        gateway_ip: `${cidrBlock[0]}.${cidrBlock[1]}.${formDataValues.gatewayIp3}.${formDataValues.gatewayIp4}`,
      }),
      onSuccess,
      onError
    );
  };

  return (
    <FullScreenOverlay
      className={styles.createSubnet}
      title="Add new subnet"
      headerMeta={
        <Button variant="Tertiary" leftIcon="CircleHelp" size="SM">
          Help
        </Button>
      }
      handleClose={close}
    >
      <form className={styles.subnetContentWrapper} onSubmit={submit}>
        <div className={styles.header}>
          <p className={styles.title}>{vpc.name}</p>
          <p className={styles.subTitle}>
            Associated VPC CIDR: <span>{vpc.cidr}</span>
          </p>
        </div>

        <Divider className={styles.divider} />

        <div className={styles.header}>
          <p className={styles.title}>Subnet settings</p>
          <p className={styles.subTitle}>
            Please enter the name and specify the IPv4 CIDR block for each of
            your subnets.
          </p>
          <Callout
            title=""
            variant="Warning"
            message="The CIDR block specified for your subnets cannot be changed once created."
            isCloseVisible={false}
          />
        </div>

        <div className={styles.subnetForm}>
          <TextField
            label="Subnet name"
            name="name"
            variant="Outline"
            value={formData.name.value}
            onChange={handleOnChange}
            required
          />

          <TextField
            label="Description (Optional)"
            name="description"
            variant="Outline"
            value={formData.description.value}
            onChange={handleOnChange}
          />

          <div className={styles.cidrBlock}>
            <div className={styles.textBlock}>
              <p className={styles.title}>IPv4 VPC CIDR block</p>
              <p className={styles.instruction}>
                Please specify the IPv4 CIDR block for your PVC
              </p>
            </div>
            <div className={styles.inputGroup}>
              <TextField
                name="blockOne"
                variant="Basic"
                className={styles.inputDisabled}
                type="number"
                value={cidrBlock[0]}
                onChange={handleOnChange}
                placeholder="13"
                disabled
              />
              <TextField
                name="blockTwo"
                type="number"
                variant="Basic"
                className={styles.inputDisabled}
                value={cidrBlock[1]}
                onChange={handleOnChange}
                placeholder="0"
                disabled
              />
              <TextField
                name="cidr1"
                type="number"
                variant="Basic"
                value={formData.cidr1.value}
                onChange={handleOnChange}
                placeholder="0"
              />
              <TextField
                name="cidr2"
                type="number"
                variant="Basic"
                value={formData.cidr2.value}
                onChange={handleOnChange}
                placeholder="0"
              />
              <Select
                variant="SimpleFilled"
                className={styles.selectNetMask}
                placeholder="19"
                name="cidr3"
                value={formData.cidr3.value}
                options={netMasks}
                onChange={handleOnChange}
              />
            </div>
          </div>
          {error && (
            <Callout
              title=""
              variant="Critical"
              message={error}
              isCloseVisible={false}
            />
          )}
          <div className={styles.cidrBlock}>
            <div className={styles.textBlock}>
              <p className={styles.title}>Gateway IP</p>
              <p className={styles.instruction}>
                Please specify the gateway IP for your subnet
              </p>
            </div>
            <div className={styles.inputGroup}>
              <TextField
                name="gatewayIp1"
                variant="Basic"
                className={styles.inputDisabled}
                type="number"
                value={cidrBlock[0]}
                onChange={handleOnChange}
                placeholder="186"
                disabled
              />
              <TextField
                name="gatewayIp2"
                className={styles.inputDisabled}
                type="number"
                variant="Basic"
                value={cidrBlock[1]}
                onChange={handleOnChange}
                placeholder="122"
                disabled
              />
              <TextField
                name="gatewayIp3"
                variant="Basic"
                value={formData.gatewayIp3.value}
                onChange={handleOnChange}
                type="number"
                placeholder="0"
              />
              <TextField
                name="gatewayIp4"
                variant="Basic"
                value={formData.gatewayIp4.value}
                onChange={handleOnChange}
                type="number"
                placeholder="0"
              />
            </div>
          </div>
        </div>

        <div className={styles.formActions}>
          <Button
            size="SM"
            type="submit"
            loading={isLoading}
            disabled={!isFormValid(formData)}
          >
            Create subnet
          </Button>
        </div>
      </form>
    </FullScreenOverlay>
  );
};

export default CreateSubnets;
