import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import moment from "moment";
import cn from "classnames";

import {
  Button,
  CopyElement,
  Divider,
  DropdownMenu,
  Icon,
  Modal,
  Popper,
  StatusChip,
} from "nebula-galaxy";

import { MainLayout } from "layouts";
import {
  Accordion,
  DetailsPageContent,
  DetailsPageHeader,
  PageLoader,
} from "components";
import { routes } from "config";
import { useAlert } from "context";
import { useReactQuery } from "hooks";
import {
  deleteServer,
  fetchServer,
  rebootServer,
  startServer,
  stopServer,
} from "services";
import { instanceActions, statusMap, textMap } from "utils/data";

import styles from "./instances.module.scss";
import DeleteInstance from "./delete-instance";

const queryKey = "instance-details";

const InstanceDetails = () => {
  const { id } = useParams();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [instanceAction, setInstanceAction] = useState("");

  const [isStartingServer, setIsStartingServer] = useState(false);
  const [isRebootingServer, setIsRebootingServer] = useState(false);
  const [isUpdatingServer, setIsUpdatingServer] = useState(false);

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);

  const { data, isLoading } = useReactQuery({
    queryKey,
    queryFunction: () => fetchServer(id as string),
  });

  const closeModals = () => {
    setInstanceAction("");
  };

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });

    queryClient.invalidateQueries(queryKey);
    setIsStartingServer(false);
    setIsRebootingServer(false);
    setIsUpdatingServer(false);
    closeModals();
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsStartingServer(false);
    setIsRebootingServer(false);
    setIsUpdatingServer(false);
  };

  const handleStartServer = () => {
    setIsStartingServer(true);

    startServer(id as string, onSuccess, onError);
  };

  const handleRebootServer = () => {
    setIsRebootingServer(true);

    rebootServer(id as string, onSuccess, onError);
  };

  const handleStopServer = () => {
    setIsUpdatingServer(true);

    stopServer(id as string, onSuccess, onError);
  };

  const handleDeleteServer = () => {
    setIsUpdatingServer(true);

    deleteServer(
      id as string,
      (message) => {
        onSuccess(message);
        navigate(routes.console.nebCompute.instanceDetails);
      },
      onError
    );
  };

  const {
    os,
    instance,
    serverName,
    serverStatus,
    instanceState,
    volume,
    createdAt,
    publicIPv4,
    createdBy,
    vpc,
    subnet,
    password,
    autoAssignIp,
  } = data || {};

  const hasNoData =
    serverStatus === "FAIL" ||
    serverStatus === "INIT" ||
    serverStatus === "IN_PROGRESS";

  const serverStatusLowercase = serverStatus?.toLowerCase();

  return (
    <MainLayout>
      <DetailsPageHeader
        breadcrumbs={[
          {
            label: "Servers",
            url: routes.console.nebCompute.instances,
            urlComponent: ({ url, label }) => (
              <Link to={url as string}>{label}</Link>
            ),
          },
          {
            label: isLoading ? '....' : serverName,
          },
        ]}
      />
      <DetailsPageContent
        className={styles.instanceDetails}
        isLoading={isLoading}
      >
        <div>
          <div className={styles.header}>
            <h5 className={cn("medium", styles.title)}>
              {serverName}
              <StatusChip
                className={styles.status}
                variant={
                  instanceState === "SHUTDOWN"
                    ? "Critical"
                    : statusMap[serverStatusLowercase || "init"]
                }
                text={
                  instanceState === "SHUTDOWN"
                    ? "Stopped"
                    : textMap[serverStatusLowercase || "init"]
                }
              />
            </h5>

            <div className={styles.actions}>
              {!hasNoData &&
              (instanceState === "ACTIVE" || instanceState === "SHUTDOWN") ? (
                <>
                  <Button
                    variant="Secondary"
                    size="SM"
                    disabled={instanceState === "ACTIVE"}
                    onClick={handleStartServer}
                  >
                    Start
                  </Button>
                  <Button
                    variant="Secondary"
                    size="SM"
                    disabled={instanceState === "SHUTDOWN"}
                    onClick={() => setInstanceAction("stop")}
                  >
                    Stop
                  </Button>

                  <Popper
                    className={styles.actionPopper}
                    anchorElement={
                      <Button
                        variant="SecondaryIcon"
                        size="SM"
                        onClick={() => setIsActionsDropdownOpen(true)}
                      >
                        <Icon name="Ellipsis" />
                      </Button>
                    }
                    isOpen={isActionsDropdownOpen}
                    onClose={() => setIsActionsDropdownOpen(false)}
                  >
                    <DropdownMenu
                      className={styles.actionMenu}
                      name="action"
                      options={instanceActions}
                      onChange={({ target }) =>
                        target.value === "reboot"
                          ? handleRebootServer()
                          : setInstanceAction(target.value as string)
                      }
                    />
                  </Popper>
                </>
              ) : (
                (serverStatus === "INIT") && (
                  <Button
                    variant="Secondary"
                    size="SM"
                    leftIcon="RotateCw"
                    onClick={handleRebootServer}
                  >
                    Refresh
                  </Button>
                )
              )}
            </div>
          </div>
          {serverStatus === "INIT" && (
            <div className={styles.statusInfo}>
              <Icon
                name="InfoAlt"
                size="MD"
                className={styles.statusIconInfo}
              />

              <span className="p5">
                Your server instance has been initialized and is queued for
                processing
              </span>
            </div>
          )}
          {serverStatus === "IN_PROGRESS" && (
            <div className={styles.statusInfo}>
              <span className={cn(styles.statusIcon, styles.statusIconPending)}>
                <Icon name="Clock4" size="SM" />
              </span>

              <span className="p5">
                Your server instance is now in progress and is being prepared
                for launch.
              </span>
            </div>
          )}
          {serverStatus === "FAIL" && (
            <div className={styles.statusInfo}>
              <Icon
                name="ExclamationMark"
                size="SM"
                className={styles.statusIconWarning}
              />

              <span className="p5">
                Unfortunately, your server instance has failed to launch due to
                unavailable specifications.
              </span>
            </div>
          )}
          <Divider className={styles.divider} />
          <div className={styles.meta}>
            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>
                Instance ID
              </span>
              <CopyElement
                element={
                  <span className={cn("p5", styles.metaItemValue)}>
                    {instance?.instanceId}
                  </span>
                }
                value={instance?.instanceId}
              />
            </div>

            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>
                IP address
              </span>
              <CopyElement
                element={
                  <span className={cn("p5", styles.metaItemValue)}>
                    {publicIPv4}
                  </span>
                }
                value={publicIPv4}
              />
            </div>

            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>
                Created by
              </span>
              <span className={cn("p5", styles.metaItemValue)}>
                {createdBy?.fullName}
              </span>
            </div>

            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>
                Date created
              </span>
              <span className={cn("p5", styles.metaItemValue)}>
                {moment(createdAt).format("MMM D, YYYY, h:mm A")}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.instanceStatusChecks}>
          <Accordion
            variant="Outlined"
            items={[
              {
                header: (
                  <div className={styles.statusChecksHeader}>
                    <span className="p5 medium">System status</span>
                    {hasNoData || instanceState === "SHUTDOWN" ? (
                      <StatusChip variant="Neutral" size="SM" text="NO DATA" />
                    ) : (
                      <StatusChip
                        variant="Success"
                        size="SM"
                        text="OPERATIONAL"
                      />
                    )}
                  </div>
                ),
                content: (
                  <div className={styles.statusChecksContent}>
                    <span className="cap1">
                      System reachability checks are fully operational.
                    </span>
                  </div>
                ),
                disabled: hasNoData,
              },
              {
                header: (
                  <div className={styles.statusChecksHeader}>
                    <span className="p5 medium">Instance status</span>
                    {hasNoData || instanceState === "SHUTDOWN" ? (
                      <StatusChip variant="Neutral" size="SM" text="NO DATA" />
                    ) : (
                      <StatusChip
                        variant="Success"
                        size="SM"
                        text="OPERATIONAL"
                      />
                    )}
                  </div>
                ),
                content: (
                  <div className={styles.statusChecksContent}>
                    <span className="cap1">
                      Instance reachability checks are fully operational.
                    </span>
                  </div>
                ),
                disabled: hasNoData,
              },
            ]}
          />
        </div>

        <div className={styles.instanceSection}>
          <div className={cn("p4 medium", styles.sectionHeader)}>
            Software image
          </div>
          <div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>
                Operating system
              </span>
              <span className={cn("p5", styles.sectionValue)}>{os?.name}</span>
            </div>
          </div>
        </div>

        <div className={styles.instanceSection}>
          <div className={cn("p4 medium", styles.sectionHeader)}>
            Compute engine
          </div>
          <div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>
                Specification
              </span>
              <span className={cn("p5", styles.sectionValue)}>
                {instance?.instanceId}
              </span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>
                Price per hour
              </span>
              <span className={cn("p5", styles.sectionValue)}>
                ₦{instance?.cost}
              </span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>
                No of instance
              </span>
              <span className={cn("p5", styles.sectionValue)}>
                {volume || "1"}
              </span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>vCPU</span>
              <span className={cn("p5", styles.sectionValue)}>
                {instance?.vCPU}
              </span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>Memory</span>
              <span className={cn("p5", styles.sectionValue)}>
                {instance?.memory}
              </span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>Bandwidth</span>
              <span className={cn("p5", styles.sectionValue)}>
                {instance?.bandwidth}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.instanceSection}>
          <div className={cn("p4 medium", styles.sectionHeader)}>
            Network & security
          </div>
          <div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>VPC</span>
              <span className={cn("p5", styles.sectionValue)}>{vpc?.name}</span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>Subnet</span>
              <span className={cn("p5", styles.sectionValue)}>
                {subnet?.name}
              </span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>
                Auto-assign public IP
              </span>
              <span className={cn("p5", styles.sectionValue)}>
                <StatusChip
                  variant={autoAssignIp ? "Success" : "Neutral"}
                  size="SM"
                  text={autoAssignIp ? "ENABLED" : "DISABLED"}
                />
              </span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>
                Security group
              </span>
              <span className={cn("p5", styles.sectionValue)}>
                <StatusChip
                  variant="Informational"
                  size="SM"
                  text="COMING SOON"
                />
              </span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>Key pair</span>
              <span className={cn("p5", styles.sectionValue)}>
                <StatusChip
                  variant="Informational"
                  size="SM"
                  text="COMING SOON"
                />
              </span>
            </div>
            <div className={styles.sectionItem}>
              <span className={cn("p5", styles.sectionTitle)}>Root access password</span>
              <CopyElement
                element={
                  <span className={cn("p5", styles.sectionValue)}>
                    {password}
                  </span>
                }
                value={password}
              />
            </div>
          </div>
        </div>
      </DetailsPageContent>

      {isStartingServer && <PageLoader title="Starting instance..." isMini />}

      {isRebootingServer && (
        <PageLoader
          title="Rebooting..."
          message="Please don't turn off your computer"
          isMini
        />
      )}

      <Modal
        active={instanceAction === "stop"}
        onClose={closeModals}
        variant="Warning"
        title="Stop web server"
        actions={
          <>
            <Button variant="Tertiary" size="SM" onClick={closeModals}>
              Cancel
            </Button>

            <Button
              variant="Destructive"
              size="SM"
              onClick={handleStopServer}
              loading={isUpdatingServer}
            >
              Stop instance
            </Button>
          </>
        }
      >
        <span className="p5">
          Stopping the web server will temporarily interrupt any running
          services or applications, and may result in data loss if unsaved
          changes are present. Are you sure you want to proceed?
        </span>
      </Modal>

      <DeleteInstance 
        action={instanceAction} 
        close={closeModals}
        submit={handleDeleteServer}
        isLoading={isUpdatingServer}
        serverName={serverName}
      />
    </MainLayout>
  );
};

export default InstanceDetails;
