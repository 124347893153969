import type { ChangeEvent } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import {
  Button,
  Checkbox,
  Modal,
  Select,
  SelectEvent,
  TextArea,
  TextField,
} from "nebula-galaxy";

import { closeAccount } from "services";
import { useAuth } from "context";
import { routes } from "config";

import { getFormValues, isFormValid } from "utils/functions";
import { closeAccountReasons } from "utils/data";

import styles from "./company.module.scss";

const initialFormData = {
  email: {
    value: "",
    valid: false,
  },
  details: {
    value: "",
    valid: true,
  },
  reason: {
    value: "",
    valid: false,
  },
};

interface CloseAccountProps {
  active: boolean;
  close: () => void;
  onError: (message: string) => void;
}

const CloseAccount = ({ active, close, onError }: CloseAccountProps) => {
  const navigate = useNavigate();
  const { userProfile, signOut } = useAuth();

  const { email: ownerEmail, org } = userProfile || {};
  const { orgId } = org || {};

  const [formData, setFormData] = useState({ ...initialFormData });
  const [isAgreementActive, setIsAgreementActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { email, details, reason } = getFormValues(formData);

  const handleOnChange = (
    {
      target,
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectEvent,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const handleOnClose = () => {
    setFormData({ ...initialFormData });
    setIsAgreementActive(false);

    close();
  };

  const submit = () => {
    setIsLoading(true);
    closeAccount(
      orgId,
      { email, details, reason },
      () => {
        signOut();
        navigate(routes.auth.signUp);
      },
      (message) => {
        onError(message);
        setIsLoading(false);
      }
    );
  };

  const closeAccountOptions = closeAccountReasons.map((reason) => ({
    label: reason,
    value: reason,
  }));

  return (
    <Modal
      className={styles.closeAccountModal}
      active={active}
      onClose={handleOnClose}
      variant="Standard"
      title="Close your Nebula account"
      actions={
        <>
          <Button variant="Tertiary" size="SM" onClick={handleOnClose}>
            Cancel
          </Button>

          <Button
            variant="Destructive"
            size="SM"
            onClick={submit}
            loading={isLoading}
            disabled={
              !isFormValid(formData) ||
              email !== ownerEmail ||
              !isAgreementActive
            }
          >
            Close account
          </Button>
        </>
      }
      hideHeader
    >
      <div className={styles.modalHeader}>
        <span className={cn("p3 medium", styles.title)}>
          Close your Nebula account
        </span>
        <span className={cn("p5", styles.description)}>
          You are about to close your Nebula account. All resources, data,
          projects, and teams associated with your Nebula account will be
          permanently removed.
        </span>
      </div>

      <div className={styles.inputs}>
        <Select
          name="reason"
          variant="Filled"
          label="Why are you closing your account?"
          value={reason}
          options={closeAccountOptions}
          onChange={handleOnChange}
          required
        />

        <TextArea
          name="details"
          variant="Filled"
          value={details}
          label="Is there any feedback you'd like to share? (Optional)"
          onChange={handleOnChange}
        />

        <TextField
          variant="Filled"
          label="Confirm with email"
          name="email"
          type="email"
          value={email}
          onChange={handleOnChange}
          required
        />

        <Checkbox
          className={styles.agreement}
          onChange={({ target }) => setIsAgreementActive(target.checked)}
          label="I acknowledge that all resources, data, projects, and teams will be permanently removed and I want to proceed."
        />
      </div>
    </Modal>
  );
};

export default CloseAccount;
