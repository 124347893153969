import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import moment from "moment";

import type { IObject } from "nebula-galaxy";
import {
  Button,
  CopyElement,
  DropdownMenu,
  Icon,
  Popper,
  StatusChip,
  Table,
} from "nebula-galaxy";
import { useAlert, useAuth } from "context";
import {
  deleteProject,
  fetchAPIKey,
  generateAPIKey,
  removeMemberFromProject,
} from "services";
import { routes } from "config";
import { useReactQuery } from "hooks";

import { generateSelectOptions } from "utils/functions";
import { roles } from "utils/data";

import { membersColumns } from "../columns";
import RemoveMember from "./remove-member";
import DeleteProject from "./delete-project";

import styles from "../project-details.module.scss";

const apiKeyQueryKey = "api-key";

interface OverviewProps {
  data: IObject;
  queryKey: string;
}

const Overview = ({ data, queryKey }: OverviewProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const { userProfile } = useAuth();

  const { data: apiKeyData, isLoading: isLoadingAPIKey } = useReactQuery({
    queryKey: apiKeyQueryKey,
    queryFunction: () => fetchAPIKey(),
  });

  const { project, users } = data || {};
  const { projectId, projectOwner, createdAt, name } = project || {};

  const [selectedMember, setSelectedMember] = useState<IObject | null>(null);
  const [isRemoveMemberActive, setIsRemoveMemberActive] = useState(false);
  const [isDeleteProjectActive, setIsDeleteProjectActive] = useState(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);

  const isCurrentProject = projectId === userProfile?.project.projectId;

  const onSuccess = (message: string, key?: string) => {
    queryClient.invalidateQueries(key || queryKey);
    setIsRemoveMemberActive(false);
    setIsDeleteProjectActive(false);
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsUpdating(false);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsUpdating(false);
  };

  const handleGenerateAPIKey = async () => {
    setIsUpdating(true);
    generateAPIKey((message) => onSuccess(message, apiKeyQueryKey), onError);
  };

  const handleRemoveMemberFromProject = async () => {
    setIsUpdating(true);
    removeMemberFromProject(
      { projectId, userId: selectedMember?.userId },
      onSuccess,
      onError
    );
  };

  const handleDeleteProject = async () => {
    setIsUpdating(true);
    deleteProject(
      projectId,
      (message) => {
        onSuccess(message, "projects");
        navigate(routes.settings.projects);
      },
      onError
    );
  };

  return (
    <div className={styles.overview}>
      <div className={styles.overviewHeader}>
        <div className={styles.headerItem}>
          <div className={styles.headerLabel}>
            <span className={cn("cap1", styles.headerLabel)}>Project ID</span>
            {isCurrentProject && (
              <StatusChip
                className={styles.currentProjectChip}
                variant="Informational"
                size="SM"
                text="Current Project"
              />
            )}
          </div>

          <CopyElement
            element={
              <span className={cn("p5", styles.headerValue)}>{projectId}</span>
            }
            value={projectId}
          />
        </div>

        <div className={styles.headerItemWrapper}>
          <div className={styles.headerItem}>
            <span className={cn("cap1", styles.headerLabel)}>Created by</span>
            <span className={cn("p5", styles.headerValue)}>
              {projectOwner?.fullName || "-"}
            </span>
          </div>
          <div className={styles.headerItem}>
            <span className={cn("cap1", styles.headerLabel)}>Date created</span>
            <span className={cn("p5", styles.headerValue)}>
              {moment(createdAt).format("DD MMM, YYYY, h:mm A")}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.apiKey}>
        <span className={cn("p4 medium", styles.apiKeyTitle)}>API key</span>
        <div className={styles.apiKeyDetails}>
          {apiKeyData && (
            <CopyElement
              className={styles.keyInfo}
              element={
                <span className={cn("p5", styles.headerValue)}>
                  {apiKeyData}
                </span>
              }
              value={apiKeyData}
            />
          )}

          <span className={styles.detailsDivider} />

          <Button
            leftIcon="RotateCw"
            variant="Secondary"
            size="SM"
            loading={isLoadingAPIKey || isUpdating}
            onClick={handleGenerateAPIKey}
          >
            New key
          </Button>
        </div>
      </div>

      <div className={styles.membersTable}>
        <div className={styles.membersTableHeader}>
          <span className="p4 medium">Members</span>
        </div>

        <Table
          columns={membersColumns}
          data={users}
          rowActions={(member) => {
            const userId = member?.user?.userId;

            const isCurrentUser = userId === userProfile?.userId;
            const isProjectOwner = userId === projectOwner?.userId;

            return (
              !isProjectOwner && (
                <div
                  className={cn(
                    styles.membersRowAction,
                    styles.membersRowActionSingle
                  )}
                >
                  <Popper
                    anchorElement={
                      <Button
                        className={styles.editButton}
                        variant="SecondaryIcon"
                        size="SM"
                        onClick={() => setIsActionsDropdownOpen(false)}
                      >
                        <Icon name="PenLine" />
                      </Button>
                    }
                    isOpen={isActionsDropdownOpen}
                    onClose={() => setIsActionsDropdownOpen(false)}
                  >
                    <DropdownMenu
                      className={styles.membersRoles}
                      name="edit-role"
                      onChange={() => {}}
                      options={generateSelectOptions(roles, "id", "name")}
                    />
                  </Popper>

                  {!isCurrentUser && (
                    <>
                      <span className={styles.actionDivider}></span>

                      <Button
                        className={styles.deleteButton}
                        variant="SecondaryIcon"
                        size="SM"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedMember(member?.user);
                          setIsRemoveMemberActive(true);
                        }}
                      >
                        <Icon name="Trash2" />
                      </Button>
                    </>
                  )}
                </div>
              )
            );
          }}
          loadData={() => null}
          isMultiSelect={false}
          isToolbarActive={false}
        />
      </div>

      {!isCurrentProject && (
        <div className={styles.deleteProject}>
          <span className={cn("p3 medium", styles.deleteProjectTitle)}>
            Delete project
          </span>
          <span className={cn("p5", styles.deleteProjectDescription)}>
            All shared resources, members, and teams will be permanently
            deleted.
          </span>
          <Button
            variant="Destructive"
            size="SM"
            onClick={() => setIsDeleteProjectActive(true)}
          >
            Delete project
          </Button>
        </div>
      )}

      <RemoveMember
        active={isRemoveMemberActive}
        close={() => setIsRemoveMemberActive(false)}
        isLoading={isUpdating}
        submit={handleRemoveMemberFromProject}
        selectedMember={selectedMember}
        projectName={name}
      />

      <DeleteProject
        active={isDeleteProjectActive}
        close={() => setIsDeleteProjectActive(false)}
        isLoading={isUpdating}
        submit={handleDeleteProject}
        projectName={name}
      />
    </div>
  );
};

export default Overview;
