import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import cn from "classnames";
import {
  Button,
  Callout,
  Modal,
  Radio,
  SelectEvent,
  TextField,
} from "nebula-galaxy";

import { getFormValues, isFormValid } from "utils/functions";

import styles from "./key-pairs.module.scss";
import { createKeyPairs } from "services";
import { useQueryClient } from "react-query";
import { useAlert } from "context";

const initialFormData = {
  name: {
    value: "",
    valid: false,
  },
  algorithm: {
    value: "",
    valid: false,
  }
};

interface CreateKeyPairProps {
  providerId: string;
  queryKey?: string;
  active: boolean;
  close: () => void;
}

const CreateKeyPair = ({ active, close, providerId, queryKey }: CreateKeyPairProps) => {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({ ...initialFormData });
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = (
    {
      target,
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectEvent,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid: !!value,
      },
    });
  };

  const onSuccess = (message: string, data: any) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    queryClient.invalidateQueries(queryKey);
    setFormData({ ...initialFormData });
    setIsLoading(false);
    handleDownload(data)
    close();
  };

  const onError = (message: string) => {
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsLoading(false);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    const { name, algorithm } = getFormValues(formData);
    createKeyPairs(
      {
        name,
        algorithm: `ssh-${algorithm}`,
        provider: providerId,
      },
      onSuccess,
      onError
    );
  };

  const handleDownload = (data: any) => {
    const privateKeyBlob = new Blob([data.private_key], { type: "text/plain" });
  
    const link = document.createElement("a");
    link.href = URL.createObjectURL(privateKeyBlob);
    link.download = `${data.name}-private-key.txt`;

    link.click();

    URL.revokeObjectURL(link.href);
  };

  return (
    <Modal
      className={styles.createKeyPairModal}
      active={active}
      onClose={close}
      variant="Standard"
      title="Create key pair"
      actions={
        <>
          <Button variant="Tertiary" size="SM" onClick={close}>
            Cancel
          </Button>

          <Button
            variant="Primary"
            size="SM"
            onClick={(e: any) => submit(e)}
            loading={isLoading}
            disabled={!isFormValid(formData)}
          >
            Create key pair
          </Button>
        </>
      }
      hideHeader
    >
      <div className={styles.modalHeader}>
        <span className={cn("p3 medium", styles.title)}>Create key pair</span>
        <span className={cn("p5", styles.description)}>
          Your key pair is a unique identity that grants access to your
          instance.
        </span>
      </div>

      <div className={styles.inputs}>
        <TextField
          name="name"
          variant="Filled"
          label="Key pair name"
          onChange={handleOnChange}
          required
        />

        <div className={styles.radioWrapper}>
          <span className={cn("p5", styles.wrapperTitle)}>Key pair type</span>
          <span className={cn("cap1", styles.wrapperDescription)}>
            ED25519 keys are compatible only with Linux instances.
          </span>
          <div className={styles.radioGroup}>
            <Radio
              name="algorithm"
              value="rsa"
              label={<span className={cn("p5", styles.radioLabel)}>RSA</span>}
              onChange={handleOnChange}
            />
            <Radio
              name="algorithm"
              value="ED25519"
              label={
                <span className={cn("p5", styles.radioLabel)}>ED25519</span>
              }
              onChange={handleOnChange}
              disabled={true}
            />
          </div>
        </div>

        <Callout
          variant="Informational"
          title=""
          message="Nebula does not store a copy of your Key Pair. You will not be able to access your instance without the Key Pair, so store this Key safely on your computer."
          isCloseVisible={false}
        />
      </div>
    </Modal>
  );
};

export default CreateKeyPair;
