import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import moment from "moment";
import { useReactQuery } from "hooks";

import cn from "classnames";
import styles from "./vpcs.module.scss";

import { TextField, Divider, Button } from "nebula-galaxy";
import { MainLayout } from "layouts";
import { DetailsPageContent, DetailsPageHeader } from "components";

import DeleteVpc from "./delete-vpc";
import CreateSubnets from "./subnets/create-subnet/create-subnet";

import { routes } from "config";
import { useAlert } from "context";
import { getVPC, updateVPC } from "services";

const queryKey = "vpc-details";

type ErrorType = "name" | "description";
type Error = {
  type: ErrorType;
  message: string;
};

const initialError: Error = {
  type: "description",
  message: "",
};

const VpcDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState<Error>({ ...initialError });
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const [isAddSubnet, setIsAddSubnet] = useState(false);
  const [isDeleteVpcActive, setIsDeleteVpcActive] = useState(false);
  const [isUpdatingVpc, setIsUpdatingVpc] = useState(false);

  const { data, isLoading } = useReactQuery({
    queryKey,
    queryFunction: () => getVPC(id as string),
  });
  const onSuccess = (message: string) => {
    queryClient.invalidateQueries(queryKey);
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsUpdatingVpc(false);
  };

  const { subnets, servers, vpc } = data || {};

  const activeServers = servers?.filter(
    (item: any) => item.instanceState !== "DELETED"
  );
  const { name, description, vpcId, createdBy, createdAt, cidr } = vpc || {};
  const cidrBlock = cidr?.split(/[./]/) || [];

  const [vpcDetails, setVpcDetails] = useState({
    name: name ?? "",
    description: description ?? "",
  });

  const handleUpdateVPC = (type: ErrorType) => {
    setError({ ...initialError });
    setIsUpdatingVpc(true);
    updateVPC(vpcId, { ...vpcDetails }, onSuccess, (message) => {
      setError({ type, message });
      setIsUpdatingVpc(false);
    });
  };

  useEffect(() => {
    setVpcDetails({
      name: name ?? "",
      description: description ?? "",
    });
  }, [name, description]);

  return (
    <MainLayout>
      <DetailsPageHeader
        breadcrumbs={[
          {
            label: "VPCs",
            url: routes.console.nebCompute.vpcs,
            urlComponent: ({ url, label }) => (
              <Link to={url as string}>{label}</Link>
            ),
          },
          {
            label: isLoading ? "...." : name,
          },
        ]}
      />
      <DetailsPageContent
        className={styles.vpcDetails}
        isLoading={isLoading || isUpdatingVpc}
      >
        <div>
          <div className={styles.header}>
            <TextField
              className={styles.title}
              variant="Basic"
              value={vpcDetails.name}
              placeholder="Add a name"
              onChange={({ target }) =>
                setVpcDetails({ ...vpcDetails, name: target.value })
              }
              onBlur={() => handleUpdateVPC("name")}
              hasError={error.type === "name" && !!error.message}
              footnote={error.type === "name" ? error.message : ""}
            />
            <TextField
              variant="Basic"
              value={vpcDetails.description}
              placeholder="Add a description"
              onChange={({ target }) =>
                setVpcDetails({ ...vpcDetails, description: target.value })
              }
              onBlur={() => handleUpdateVPC("description")}
              hasError={error.type === "description" && !!error.message}
              footnote={error.type === "description" ? error.message : ""}
            />
          </div>

          <Divider className={styles.divider} />

          <div className={styles.meta}>
            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>VPC ID</span>
              <span className={cn("p5", styles.metaItemValue)}>{vpcId}</span>
            </div>

            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>
                Created by
              </span>
              <span className={cn("p5", styles.metaItemValue)}>
                {createdBy?.fullName}
              </span>
            </div>

            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>
                Date created
              </span>
              <span className={cn("p5", styles.metaItemValue)}>
                {moment(createdAt).format("MMM D, YYYY, h:mm A")}
              </span>
            </div>
          </div>

          <Divider className={styles.divider} />
        </div>

        <div>
          <div className={cn("p4 medium", styles.cidrBlockTitle)}>
            VPC CIDR block
          </div>

          <div className={styles.cidrBlock}>
            {cidrBlock?.map((item: string, index: number) => (
              <span key={index} className="p5">
                {item}
              </span>
            ))}
          </div>
        </div>

        <div className={styles.vpcSection}>
          <div className={styles.sectionHeader}>
            <p className={cn("p4 medium")}>Subnets</p>
            <Button
              size="SM"
              leftIcon="Plus"
              onClick={() => setIsAddSubnet(true)}
            >
              Add subnet
            </Button>
          </div>

          {subnets?.length ? (
            <div className={styles.components}>
              <p>Components</p>
              <div className={styles.group}>
                {subnets?.map((item: any) => (
                  <Button
                    key={item.subnetId}
                    className={styles.item}
                    onClick={() =>
                      navigate(
                        `${routes.console.nebCompute.subnets}/${item.subnetId}`
                      )
                    }
                    variant="SecondaryLinkButton"
                  >
                    {item.name}
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <span className={cn("p4 medium", styles.emptyStateSubnet)}>
              No subnet is associated with the VPC yet.
            </span>
          )}
        </div>

        {!!subnets?.length && (
          <div className={styles.vpcSection}>
            <div className={cn("p4 medium", styles.sectionHeader)}>
              Route table
            </div>
            <div className={styles.components}>
              <span className={cn("p4")}>Components</span>
              <span className={cn("p4 medium")}>route_default</span>
            </div>
          </div>
        )}

        <div className={styles.vpcSection}>
          <div className={cn("p4 medium", styles.sectionHeader)}>Servers</div>

          {activeServers?.length ? (
            <div className={styles.components}>
              <p>Instances</p>
              <div className={styles.group}>
                {activeServers?.map((servers: any) => (
                  <span className={styles.item} key={servers._id}>
                    {servers.serverName}
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <span className={cn("p4 medium", styles.emptyStateSubnet)}>
              No Instance is associated with the VPC yet.
            </span>
          )}
        </div>

        <div className={styles.deleteVpcContainer}>
          <span className={cn("p3 medium", styles.deleteTitle)}>
            Delete VPC
          </span>
          <span className={cn("p5", styles.description)}>
            {!!subnets?.length
              ? "To delete this VPC, first remove all associated subnets and resources. Start by terminating any instances within each subnet, then delete the subnets before proceeding to delete this VPC."
              : "The VPC and all associated network components will be permanently"}
          </span>
          <Button
            variant="Destructive"
            size="SM"
            className={styles.deleteVpcAction}
            disabled={!!subnets?.length}
            onClick={() => setIsDeleteVpcActive(true)}
          >
            Delete VPC
          </Button>
        </div>
      </DetailsPageContent>

      <DeleteVpc
        active={isDeleteVpcActive}
        close={() => setIsDeleteVpcActive(false)}
        vpcDetails={data}
        servers={activeServers}
      />
      {isAddSubnet && (
        <CreateSubnets
          vpcDetails={data}
          queryKey={queryKey}
          close={() => setIsAddSubnet(false)}
        />
      )}
    </MainLayout>
  );
};

export default VpcDetails;
