import { useState } from "react";
import { useNavigate } from "react-router-dom";

import type { IObject } from "nebula-galaxy";
import { Button, Table } from "nebula-galaxy";

import { MainLayout } from "layouts";
import { PageHeader } from "components";
import { fetchProjects } from "services";
import { useAuth } from "context";
import { useReactQuery } from "hooks";
import { routes } from "config";

import { projectsColumns } from "./columns";
import AddProject from "./add-project";

import type { QueryParams } from "utils/types";
import { pluralize } from "utils/functions";
import { defaultQueryParams } from "utils/consts";

const queryKey = "projects";

const Projects = () => {
  const { userProfile } = useAuth();
  const navigate = useNavigate();

  const [isFormActive, setIsFormActive] = useState(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    ...defaultQueryParams,
  });
  const { data, isLoading } = useReactQuery({
    queryKey,
    queryParams,
    queryFunction: () => fetchProjects(queryParams),
  });

  return (
    <MainLayout>
      <section className="page-content">
        <PageHeader title="Projects" />

        <Table
          columns={projectsColumns(userProfile)}
          data={data?.docs}
          loading={isLoading}
          pagination={data?.pagination}
          loadData={setQueryParams}
          onRowClick={({ projectId }) =>
            navigate(`${routes.settings.projects}/${projectId}`)
          }
          disableRow={({ users }: IObject) =>
            !users.some(({ userId }: IObject) => userId === userProfile?.userId)
          }
          toolbarActions={
            <Button
              leftIcon="Plus"
              size="SM"
              onClick={() => setIsFormActive(true)}
            >
              Add new project
            </Button>
          }
          searchPlaceholder="Search project name or ID"
          dataText={pluralize("project", data?.docs?.length)}
          isMultiSelect={false}
        />

        <AddProject
          queryKey={queryKey}
          active={isFormActive}
          close={() => setIsFormActive(false)}
        />
      </section>
    </MainLayout>
  );
};

export default Projects;
