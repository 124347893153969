import { Link } from "react-router-dom";
import cn from "classnames";
import { Button } from "nebula-galaxy";

import { routes } from "config";
import { Page404Illustration } from "assets";
import { useTheme } from "context";

import styles from "./404.module.scss";

const Page404 = () => {
  const { theme } = useTheme();
  return (
    <div className={styles.page404}>
      <div className={styles.text}>
        <h1 className={cn("h1", styles.title)}>Sorry, we lost that page</h1>
        <span className={cn("p3", styles.description)}>
          The page you're looking for doesn't seem to exist or has been moved.
          Please check the URL or head back to the homepage.
        </span>

        <Link to={routes.console.home} className={styles.action}>
          <Button size="LG">Return home</Button>
        </Link>
      </div>

      <div className={styles.image}>
        <Page404Illustration
          borderColor={theme === "dark" ? "#FEFEFF" : "#202022"}
        />
      </div>
    </div>
  );
};

export default Page404;
