import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { Button, Callout, TextField, SelectEvent, Select } from "nebula-galaxy";
import { FullScreenOverlay } from "components";
import { createVPC } from "services";
import { useAlert } from "context";
import { routes } from "config";

import {
  getFormValues,
  isFormValid,
  removeKeysWithEmptyValues,
} from "utils/functions";
import { cidrBlockOptions } from "utils/data";

import styles from "./add-new-vpc.module.scss";

const initialFormData = {
  name: {
    value: "",
    valid: false,
  },
  description: {
    value: "",
    valid: true,
  },
  blockOne: {
    value: "",
    valid: false,
  },
  blockTwo: {
    value: 0,
    valid: true,
  },
  blockThree: {
    value: 0,
    valid: true,
  },
  blockFour: {
    value: 0,
    valid: true,
  },
  blockFive: {
    value: "",
    valid: false,
  },
};

interface AddNewVPCProps {
  close: () => void;
  providerId: string;
  queryKey: string;
  handleOnSuccess?: () => void;
}

const AddNewVPC = ({
  providerId,
  queryKey,
  close,
  handleOnSuccess,
}: AddNewVPCProps) => {
  const { showAlert, clearAlert } = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ ...initialFormData });
  const newFormData = JSON.parse(JSON.stringify(formData));
  const { name, description } = getFormValues(newFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const onSuccess = (vpcId: string, message?: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    queryClient.invalidateQueries(queryKey);
    setFormData({ ...initialFormData });
    setIsLoading(false);
    close();

    navigate(`${routes.console.nebCompute.vpcs}/${vpcId}`);
    handleOnSuccess?.();
  };

  const onError = (message: string) => {
    setError(message);
    setIsLoading(false);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError("");
    clearAlert();
    setIsLoading(true);

    const { name, description, ...formDataValues } = getFormValues(newFormData);
    const cidr = `${formDataValues.blockOne}.${formDataValues.blockTwo}.${formDataValues.blockThree}.${formDataValues.blockFour}/${formDataValues.blockFive}`;

    createVPC(
      removeKeysWithEmptyValues({
        cidr,
        name,
        description,
        provider: providerId,
      }),
      onSuccess,
      onError
    );
  };

  const netMasks = cidrBlockOptions.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <FullScreenOverlay
      className={styles.addNewVPC}
      title="Add new VPC"
      headerMeta={
        <Button variant="Tertiary" leftIcon="CircleHelp" size="SM">
          Help
        </Button>
      }
      handleClose={close}
    >
      <form className={styles.vpcForm} onSubmit={submit}>
        <Callout
          title=""
          variant="Warning"
          message="The CIDR block specified for the VPC and its subnets cannot be changed once the VPC has been created."
          isCloseVisible={false}
        />

        <div className={styles.vpcFormGroup}>
          <TextField
            label="VPC name"
            name="name"
            variant="Outline"
            value={name}
            onChange={handleOnChange}
            required
          />

          <TextField
            label="Description (Optional)"
            name="description"
            variant="Outline"
            value={description}
            onChange={handleOnChange}
          />

          <div className={styles.cidrBlock}>
            <div className={styles.textBlock}>
              <p className={styles.title}>IPv4 VPC CIDR block</p>
              <p className={styles.instruction}>
                Please specify the IPv4 CIDR block for your PVC
              </p>
            </div>
            <div className={styles.inputGroup}>
              <TextField
                className={styles.cidrTextfield}
                name="blockOne"
                variant="Basic"
                type="number"
                value={formData.blockOne.value}
                onChange={handleOnChange}
                placeholder="13"
              />
              <TextField
                className={styles.cidrTextfield}
                name="blockTwo"
                type="number"
                variant="Basic"
                value={formData.blockTwo.value}
                onChange={handleOnChange}
                placeholder="0"
              />
              <TextField
                className={styles.cidrTextfield}
                name="blockThree"
                type="number"
                variant="Basic"
                value={formData.blockThree.value}
                onChange={handleOnChange}
                placeholder="0"
                disabled
              />
              <TextField
                className={styles.cidrTextfield}
                name="blockFour"
                type="number"
                variant="Basic"
                value={formData.blockFour.value}
                onChange={handleOnChange}
                placeholder="0"
                disabled
              />
              <Select
                className={cn(styles.cidrTextfield, styles.cidrSelect)}
                variant="SimpleFilled"
                placeholder="19"
                name="blockFive"
                value={formData.blockFive.value}
                options={netMasks}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>
        {error && (
          <Callout
            title=""
            variant="Critical"
            message={error}
            isCloseVisible={false}
          />
        )}

        <div className={styles.vpcFormActions}>
          <Button
            size="SM"
            type="submit"
            loading={isLoading}
            disabled={!isFormValid(newFormData)}
          >
            Create VPC
          </Button>
        </div>
      </form>
    </FullScreenOverlay>
  );
};

export default AddNewVPC;
