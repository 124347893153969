import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";

import { Icon } from "nebula-galaxy";

import { MainMenu } from "utils/types";

import styles from "./menu-item.module.scss";

const MenuItem = (props: MainMenu) => {
  const { pathname } = useLocation();
  const { subMenu } = props;
  const hasSubMenu = !!subMenu?.length;

  const [isSubmenuActive, setIsSubMenuActive] = useState(
    subMenu?.some(({ url }) => url === pathname)
  );

  useEffect(() => {
    if (hasSubMenu) {
      setIsSubMenuActive(subMenu.some(({ url }) => url === pathname));
    }
  }, [pathname, hasSubMenu]);

  return (
    <li className={styles.container}>
      <BaseItem
        onClick={() => setIsSubMenuActive(!isSubmenuActive)}
        hasSubMenu={hasSubMenu}
        isSubMenuActive={isSubmenuActive}
        {...props}
      />

      {hasSubMenu && (
        <ul
          className={cn(styles.subMenu, {
            [styles.subMenuActive]: isSubmenuActive,
          })}
        >
          {subMenu.map((props, index) => (
            <BaseItem key={`nav-submenu-${index}`} {...props} />
          ))}
        </ul>
      )}
    </li>
  );
};

interface BaseItemProps extends MainMenu {
  onClick?: () => void;
  hasSubMenu?: boolean;
  isSubMenuActive?: boolean;
}

const BaseItem = ({
  url,
  label,
  icon,
  hasSubMenu,
  isSubMenuActive,
  onClick,
}: BaseItemProps) => {
  const Tag = hasSubMenu ? "button" : NavLink;

  return (
    <Tag
      className={styles.base}
      to={url || "#"}
      onClick={(e) => (hasSubMenu ? onClick?.() : e.stopPropagation())}
    >
      <div className={styles.content}>
        {icon && <Icon className={styles.icon} name={icon} />}
        <span className={cn("p5 medium", styles.text)}>{label}</span>
      </div>

      {hasSubMenu && (
        <Icon
          className={cn(styles.dropdownIcon, {
            [styles.dropdownIconActive]: isSubMenuActive,
          })}
          name="ChevronDown"
        />
      )}
    </Tag>
  );
};

export default MenuItem;
