import { FormEvent, useRef } from "react";
import { useState } from "react";
import cn from "classnames";

import {
  Button,
  Divider,
  Icon,
  PasswordTextField,
  TextField,
  Callout,
  PhoneNumberTextField,
} from "nebula-galaxy";
import { MainLayout } from "layouts";
import { useAlert, useAuth } from "context";
import { updateProfile } from "services";

import { MiniPageLoader, PageHeader } from "components";
import CloseAccount from "./close-account";
import ChangePassword from "./change-password";

import styles from "./profile.module.scss";

const Profile = () => {
  const { showAlert } = useAlert();
  const { userProfile, isLoading, fetchUserProfile } = useAuth();

  const [fullName, setFullName] = useState(userProfile?.fullName || "");
  const [phoneNumber, setPhoneNumber] = useState(
    userProfile?.phoneNumber || ""
  );
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("+234");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCloseAccountActive, setIsCloseAccountActive] = useState(false);
  const [isChangePasswordActive, setIsChangePasswordActive] = useState(false);

  const fullNameRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsUpdating(false);
    fetchUserProfile();
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
  };

  const validateForm = () => {
    const isFullNameChanged = fullName !== userProfile?.fullName;
    const isPhoneNumberChanged = phoneNumber !== userProfile?.phoneNumber;

    return isFullNameChanged || isPhoneNumberChanged;
  };

  const handleUpdateProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsUpdating(true);

    updateProfile({ fullName, phoneNumber }, onSuccess, (message) => {
      onError(message);
      setIsUpdating(false);
    });
  };

  return (
    <MainLayout>
      {isLoading ? (
        <MiniPageLoader />
      ) : (
        <section className={cn("page-content", styles.container)}>
          <PageHeader
            className={styles.header}
            title="Profile"
            description="View and manage your account preferences."
          />

          <Divider className={styles.divider} />

          <div className={styles.content}>
            <form className={styles.form} onSubmit={handleUpdateProfile}>
              <TextField
                ref={fullNameRef}
                variant="Filled"
                label="Full name"
                name="fullName"
                value={fullName}
                rightAddon={
                  <Icon
                    className={styles.textfieldIcon}
                    onClick={() => fullNameRef.current?.focus()}
                    size="SM"
                    name="PenLine"
                  />
                }
                onChange={({ target }) => setFullName(target.value)}
                required
              />
              <PhoneNumberTextField
                variant="Filled"
                ref={phoneNumberRef}
                label="Phone number"
                name="phoneNumber"
                countryCodeValue={phoneNumberCountryCode}
                value={phoneNumber}
                rightAddon={
                  <Icon
                    className={styles.textfieldIcon}
                    onClick={() => phoneNumberRef.current?.focus()}
                    size="SM"
                    name="PenLine"
                  />
                }
                onChange={({ target }) => {
                  setPhoneNumber(target.value);
                  setPhoneNumberCountryCode(target.countryCode);
                }}
              />
              <Button
                size="SM"
                type="submit"
                loading={isUpdating}
                disabled={!validateForm()}
              >
                Update
              </Button>
            </form>

            <div className={styles.loginDetails}>
              <span className={cn("p3 medium", styles.loginDetailsTitle)}>
                Login details
              </span>
              <Divider className={styles.divider} />
              <div className={styles.form}>
                <TextField
                  className={styles.emailField}
                  type="email"
                  value={userProfile?.email}
                  onChange={() => null}
                  disabled
                />
                {!userProfile?.socialGithubLogin &&
                !userProfile?.socialGoogleLogin ? (
                  <PasswordTextField
                    className={styles.passwordField}
                    label="Password"
                    value="1234567"
                    rightAddon={
                      <Icon
                        className={styles.textfieldIcon}
                        onClick={() => setIsChangePasswordActive(true)}
                        size="SM"
                        name="PenLine"
                      />
                    }
                    onChange={() => null}
                    readOnly
                  />
                ) : (
                  <Callout
                    title=""
                    variant="Informational"
                    message="Your login details are currently linked to your social account."
                    isCloseVisible={false}
                  />
                )}
              </div>
            </div>

            <div className={styles.closeAccount}>
              <span className={cn("p3 medium", styles.closeAccountTitle)}>
                Close account
              </span>
              <span className={cn("p5", styles.closeAccountDescription)}>
                Close your Console account and permanently remove access from
                all projects.
              </span>
              <Button
                variant="Destructive"
                size="SM"
                onClick={() => setIsCloseAccountActive(true)}
              >
                Close account
              </Button>
            </div>
          </div>
        </section>
      )}

      <CloseAccount
        active={isCloseAccountActive}
        close={() => setIsCloseAccountActive(false)}
        onError={onError}
      />

      <ChangePassword
        active={isChangePasswordActive}
        close={() => setIsChangePasswordActive(false)}
        onError={onError}
      />
    </MainLayout>
  );
};

export default Profile;
