import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ProjectSelector from "./project-selector";
import AddResource from "./add-resource";
import UserMenu from "./user-menu";
import SettingsMenu from "./settings-menu";

import MenuItem from "./menu-item";

import { mainMenu } from "utils/data/navigation";
import { routes } from "config";

import styles from "./navigation.module.scss";

interface NavigationProps {
  openProjectForm: () => void;
  setIsInstanceFormActive: (e: boolean) => void;
  setIsBucketFormActive: (e: boolean) => void;
}

const Navigation = ({
  openProjectForm,
  setIsInstanceFormActive,
  setIsBucketFormActive,
}: NavigationProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isSettingsPage = pathname.includes("settings");

  const [isSettingsMenuActive, setIsSettingsMenuActive] =
    useState(isSettingsPage);

  return (
    <section className={styles.container}>
      <div>
        <div className={styles.topControl}>
          <ProjectSelector openProjectForm={openProjectForm} />
          <AddResource
            setIsInstanceFormActive={setIsInstanceFormActive}
            setIsBucketFormActive={setIsBucketFormActive}
          />
        </div>

        <nav>
          <ul className={styles.mainMenu}>
            {mainMenu.map(({ url, label, icon, subMenu }, index) => (
              <MenuItem
                key={`menu-${index}`}
                url={url}
                icon={icon}
                label={label}
                subMenu={subMenu}
              />
            ))}
          </ul>
        </nav>
      </div>
      <div className={styles.bottomControl}>
        <UserMenu
          openSettings={() => {
            document.body.click(); // close popper (on click outside)
            setIsSettingsMenuActive(true);
            navigate(routes.settings.company);
          }}
        />

        {/* <Button variant="TertiaryIcon" size="SM">
          <Icon name="CircleHelp" />
        </Button> */}
      </div>

      {isSettingsMenuActive && (
        <SettingsMenu
          close={() => {
            setIsSettingsMenuActive(false);
            navigate(routes.console.home);
          }}
        />
      )}
    </section>
  );
};

export default Navigation;
