import { useState } from "react";

import { Icon, Tabs } from "nebula-galaxy";
import { MainLayout } from "layouts";

import { PageHeader } from "components";

import UserLogs from "./user-logs";
import ResourceLogs from "./resources-logs";

import styles from "./logs.module.scss";

const Logs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      header: (
        <>
          <Icon name="User" />
          <span className="p5 medium">User</span>
        </>
      ),
      content: <UserLogs />,
    },
    {
      header: (
        <>
          <Icon name="LayoutGrid" />
          <span className="p5 medium">Resources</span>
        </>
      ),
      content: <ResourceLogs />,
    },
  ];

  return (
    <MainLayout>
      <section className="page-content">
        <PageHeader title="Logs" />

        <Tabs
          className={styles.tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs.map(({ header }) => header)}
        />

        {tabs[activeTab].content}
      </section>
    </MainLayout>
  );
};

export default Logs;
