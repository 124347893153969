import { useState } from "react";
import cn from "classnames";
import { Button, Callout, IObject, Modal, Table, TextField } from "nebula-galaxy";

import { MainLayout } from "layouts";
import { PageHeader } from "components";
import { pluralize } from "utils/functions";
import type { QueryParams } from "utils/types";

import CreateKeyPair from "./create-key-pair";
import { keyPairsColumn } from "./columns";

import KeyPairsEmptyState from "assets/svgs/key-pairs-empty-state.svg";

import styles from "./key-pairs.module.scss";
import { deleteKeypair, getKeypairs } from "services";
import { useReactQuery } from "hooks";
import { defaultQueryParams, INSTANCES_GUIDE_LINK } from "utils/consts";
import { useAlert, useAuth } from "context";
import { useQueryClient } from "react-query";

const queryKey = 'key-pairs'

const KeyPairs = () => {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const [isFormActive, setIsFormActive] = useState(false);
  const [isDeleteKeyPairActive, setIsDeleteKeyPairActive] = useState(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    ...defaultQueryParams,
  });
  const [isDeletingKeypair, setIsDeletingKeypair] = useState(false)
  const [keyPairName, setKeyPairName] = useState("");
  const [selectedKeypair, setSelectedKeypair] = useState<IObject>({});

  const { data, isLoading } = useReactQuery({
    queryKey,
    queryParams,
    queryFunction: () => getKeypairs(queryParams),
  });

  const { isLoading: isLoadingRegionProviders, regionProviders } =
  useAuth();

  const keyPairs = data?.docs

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsDeletingKeypair(false)
    setIsDeleteKeyPairActive(false)
    queryKey && queryClient.invalidateQueries(queryKey);
  };

  const onError = (message: string) => {
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsDeletingKeypair(false)
  };

  const handleDeleteKeypair = async () => {
    setIsDeletingKeypair(true);
    deleteKeypair(
      selectedKeypair.keyPairId,
      onSuccess,
      onError
    );
  };

  return (
    <MainLayout>
      <section className="page-content">
        <PageHeader
          title="Key pairs"
          description={
            <>
              Create and manage all your key pairs. For further details on how
              key pairs work,{" "}
              <a href={INSTANCES_GUIDE_LINK} target="_blank" rel="noreferrer">
                read the guide.
              </a>
            </>
          }
        />

        {isLoading || keyPairs.length ? (
          <Table
            className={styles.membersTable}
            columns={keyPairsColumn}
            data={keyPairs}
            loading={isLoading}
            pagination={data?.pagination}
            loadData={setQueryParams}
            rowActions={(keypair) => (
              <Button
                variant="Secondary"
                size="SM"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteKeyPairActive(true);
                  setSelectedKeypair(keypair)
                }}
              >
                Delete
              </Button>
            )}
            toolbarActions={
              <Button
                size="SM"
                leftIcon="Plus"
                onClick={() => setIsFormActive(true)}
                disabled={isLoadingRegionProviders || !regionProviders?.length}
              >
                Add key pair
              </Button>
            }
            searchPlaceholder="Search key pair name"
            dataText={pluralize("keypair", keyPairs?.length)}
            isMultiSelect={false}
          />
        ) : (
          <div className={styles.emptyState}>
            <img src={KeyPairsEmptyState} alt="Instances empty state icon" />
            <div>
              <span className="p3 medium">
                You don't have any key pairs created yet.
              </span>
              <span className={cn("p5", styles.emptyStateDescription)}>
                Details of all your keys will appear here once you create at
                least one key pair.
              </span>
            </div>
            <Button 
              size="SM"
              onClick={() => setIsFormActive(true)} 
              disabled={isLoadingRegionProviders || !regionProviders?.length}
            >
              Create key pair
            </Button>
          </div>
        )}
      </section>

      <CreateKeyPair
        active={isFormActive}
        close={() => setIsFormActive(false)}
        providerId={regionProviders?.[0]?.providerId}
      />

      <Modal
        className={styles.deleteKeyPairModal}
        active={isDeleteKeyPairActive}
        onClose={() => setIsDeleteKeyPairActive(false)}
        variant="Standard"
        title=""
        actions={
          <>
            <Button
              variant="Tertiary"
              size="SM"
              onClick={() => setIsDeleteKeyPairActive(false)}
            >
              Cancel
            </Button>

            <Button
              variant="Destructive"
              size="SM"
              loading={isDeletingKeypair}
              onClick={handleDeleteKeypair}
              disabled={keyPairName !== selectedKeypair.name}
            >
              Delete key pair
            </Button>
          </>
        }
        hideHeader
      >
        <Callout
          variant="Warning"
          title={`Delete '${selectedKeypair?.name}'`}
          message={`${selectedKeypair?.name} key pair could be associated with one or more of your existing instances. Type the name of the key pair in the field below to proceed with this action.`}
          isCloseVisible={false}
        />

        <TextField
          className={styles.textfield}
          variant="Filled"
          label="Confirm with key pair name"
          name="keyPairName"
          onChange={({ target }) => setKeyPairName(target.value)}
          required
        />
      </Modal>
    </MainLayout>
  );
};

export default KeyPairs;
