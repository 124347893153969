import { useState } from "react";
// import { useQueryClient } from "react-query";
import cn from "classnames";
import moment from "moment";

import {
  Button,
  CopyElement,
  Divider,
  IObject,
  Icon,
  Radio,
  StatusChip,
} from "nebula-galaxy";
import { useAlert } from "context";
import { updateBucketAccess } from "services";

import styles from "./bucket-details.module.scss";

const Properties = ({
  queryKey,
  nrn,
  bucketId,
  location,
  createdAt,
  isPrivate,
}: IObject) => {
  const { showAlert } = useAlert();
  // const queryClient = useQueryClient();

  const [defaultIsPrivate, setDefaultIsPrivate] = useState(isPrivate);

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });

    // queryClient.invalidateQueries(queryKey);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
  };

  const handlePublicAccess = (value: boolean) => {
    setDefaultIsPrivate(value);

    updateBucketAccess(bucketId, { isPrivate: value }, onSuccess, onError);
  };

  return (
    <div className={styles.properties}>
      <div className={styles.propertiesMeta}>
        {/* <div>
          <span className={cn("cap1", styles.metaTitle)}>
            Nebula resource name (NRN)
          </span>
          {nrn ? (
            <CopyElement
              className={styles.copyElement}
              element={
                <span className={cn("p5", styles.metaValue)}>{nrn}</span>
              }
              value={nrn}
            />
          ) : (
            <span className={cn("p5", styles.metaValue)}>-</span>
          )}
        </div> */}

        <div>
          <span className={cn("cap1", styles.metaTitle)}>Bucket URL</span>
          <CopyElement
            className={styles.copyElement}
            element={
              <span className={cn("p5", styles.metaValue)}>{location}</span>
            }
            value={location}
          />
        </div>

        <div>
          <span className={cn("cap1", styles.metaTitle)}>Created</span>
          <span className={cn("p5", styles.metaValue)}>{moment(createdAt).format("MMM D, YYYY, h:mm A")}</span>
        </div>
      </div>

      <Divider className={styles.divider} />

      <div className={styles.bucketDetailsFormGroup}>
        <div className={styles.bucketDetailsFormHeader}>
          <div>
            <span className={cn("p4 medium", styles.formHeaderTitle)}>
              Control access to objects
            </span>
            <span className={cn("p5", styles.formHeaderDescription)}>
              We recommend disabling public access to your buckets to prevent
              unauthorized access to your store objects.
            </span>
          </div>

          {/* <Button
            className={styles.formHeaderHelp}
            variant="TertiaryIcon"
            size="SM"
          >
            <Icon name="CircleHelp" />
          </Button> */}
        </div>

        <div className={styles.inputsWrapper}>
          <Radio
            name="controlAccess"
            label={
              <span className={cn("p5", styles.radioLabel)}>
                Enable public access
              </span>
            }
            value="enabled"
            checked={!defaultIsPrivate}
            onChange={() => handlePublicAccess(false)}
          />
          <Radio
            name="controlAccess"
            label={
              <span className={cn("p5", styles.radioLabel)}>
                Disable public access
              </span>
            }
            value="disabled"
            checked={!!defaultIsPrivate}
            onChange={() => handlePublicAccess(true)}
          />
        </div>
      </div>

      <Divider className={styles.divider} />

      <div className={styles.bucketDetailsFormGroup}>
        <div className={styles.bucketDetailsFormHeader}>
          <div>
            <div className={styles.formHeaderTitle}>
              <span className="p4 medium">Bucket versioning</span>

              <StatusChip
                variant="Informational"
                text="COMING SOON"
                size="SM"
              />
            </div>
            <span className={cn("p5", styles.formHeaderDescription)}>
              Enable bucket versioning to keep multiple versions of the same
              object in your storage.
            </span>
          </div>

          {/* <Button
            className={styles.formHeaderHelp}
            variant="TertiaryIcon"
            size="SM"
          >
            <Icon name="CircleHelp" />
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default Properties;
