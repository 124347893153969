import cn from "classnames";

import type { IObject } from "nebula-galaxy";
import { StatusChip, CopyElement } from "nebula-galaxy";
import { getLocalUser, pluralize } from "utils/functions";

import styles from "./projects.module.scss";

export const projectsColumns = (userProfile: IObject | null) => [
  { key: "name", header: "Name" },
  {
    key: "projectId",
    header: "Project ID",
    renderer: ({ projectId, users }: IObject) => {
      const user = getLocalUser();
      const isDisabled = !users.some(
        ({ userId }: IObject) => userId === user?.user?.userId
      );

      return (
        <CopyElement
          className={styles.copyProjectId}
          element={
            <span
              className={cn("p5", styles.projectId, {
                [styles.projectIdDisabled]: isDisabled,
              })}
            >
              {projectId}
            </span>
          }
          value={projectId}
        />
      );
    },
  },
  {
    key: "users",
    header: "Members",
    renderer: ({ users }: IObject) => {
      const others = users.length - 1;

      return (
        <>
          <span>{users?.[0]?.fullName}</span>
          {users?.length > 1 && (
            <span>
              {" "}
              +{others} {pluralize("other", others)}
            </span>
          )}
        </>
      );
    },
  },
  {
    key: "access",
    header: "Access",
    renderer: ({ users }: IObject) => {
      const { user } = getLocalUser();
      const hasAccess = users.some(
        ({ userId }: IObject) => userId === user?.userId
      );

      return (
        <StatusChip
          variant={hasAccess ? "Success" : "Critical"}
          text={hasAccess ? "Granted" : "Denied"}
        />
      );
    },
  },
  {
    key: "currentProject",
    header: "",
    renderer: ({ projectId }: IObject) => {
      const { project } = userProfile || {};

      return (
        projectId === project.projectId && (
          <StatusChip variant="Informational" text="Current project" />
        )
      );
    },
  },
];
