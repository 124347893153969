import { Button } from "nebula-galaxy";

import styles from "./file-upload.module.scss";

interface FileUploadProps {
  id: string;
  text: string;
  onUpload: (formData: FormData) => void;
}

const FileUpload = ({ id, text, onUpload }: FileUploadProps) => {
  const handleFileUpload = (files: FileList) => {
    const formData = new FormData();

    Array.from(files).forEach((file) => {
      formData.append("objects", file, file.name);
    });

    onUpload(formData);
  };

  const triggerFileDialog = () => {
    const fileInput = document.getElementById(id) as HTMLInputElement;
    fileInput.click();

    fileInput.onchange = () => {
      if (fileInput.files) {
        handleFileUpload(fileInput.files);
      }
    };
  };

  return (
    <div className={styles.container}>
      <Button size="SM" leftIcon="Plus" onClick={triggerFileDialog}>
        {text}
      </Button>
      <input id={id} type="file" multiple />
    </div>
  );
};

export default FileUpload;
