import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import { Button, Icon, Tabs, TextField } from "nebula-galaxy";
import { MainLayout } from "layouts";
import { DetailsPageContent, DetailsPageHeader } from "components";
import { fetchMembers, fetchProject, updateProject } from "services";
import { routes } from "config";
import { useReactQuery } from "hooks";
import { useAuth, useProjects } from "context";
import { useAlert } from "context";

import AddMember from "./add-member";
import Overview from "./overview";
import CostUsage from "./cost-usage";
import AuditLogs from "./audit-logs";

import styles from "./project-details.module.scss";

const objectDetailsKey = "project-details";
const membersKey = "members";

type ErrorType = "name" | "details";
type Error = {
  type: ErrorType;
  message: string;
};

const initialError: Error = {
  type: "name",
  message: "",
};

const ProjectDetails = () => {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { setCurrentProject, isUpdatingProject } = useProjects();
  const { userProfile } = useAuth();

  const { data, isLoading } = useReactQuery({
    queryKey: objectDetailsKey,
    queryFunction: () => fetchProject(id as string),
  });
  const { data: membersData, isLoading: isLoadingMembers } = useReactQuery({
    queryKey: membersKey,
    queryFunction: () => fetchMembers(),
  });

  const { name, details, projectId } = data?.project || {};

  const [activeTab, setActiveTab] = useState(0);
  const [isAddMemberActive, setIsAddMemberActive] = useState(false);
  const [projectName, setProjectName] = useState(name);
  const [projectDetails, setProjectDetails] = useState(details);
  const [error, setError] = useState<Error>({ ...initialError });

  const onSuccess = (message: string) => {
    queryClient.invalidateQueries(objectDetailsKey);
    queryClient.invalidateQueries(membersKey);
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
  };

  const handleUpdateProject = (type: ErrorType) => {
    setError({ ...initialError });
    updateProject(
      projectId,
      { name: projectName, details: projectDetails },
      onSuccess,
      (message) => setError({ type, message })
    );
  };

  useEffect(() => {
    setProjectName(name);
    setProjectDetails(details);
  }, [name, details]);

  const tabs = [
    {
      header: (
        <>
          <Icon name="Home" />
          <span className="p5 medium">Overview</span>
        </>
      ),
      content: <Overview queryKey={objectDetailsKey} data={data} />,
    },
    {
      header: (
        <>
          <Icon name="ReceiptText" />
          <span className="p5 medium">Cost and usage</span>
        </>
      ),
      content: <CostUsage />,
    },
    {
      header: (
        <>
          <Icon name="FilePen" />
          <span className="p5 medium">Audit logs</span>
        </>
      ),
      content: <AuditLogs members={membersData?.docs} />,
    },
  ];

  return (
    <MainLayout>
      <DetailsPageHeader
        breadcrumbs={[
          {
            label: "Projects",
            url: routes.settings.projects,
            urlComponent: ({ url, label }) => (
              <Link to={url as string}>{label}</Link>
            ),
          },
          {
            label: name,
          },
        ]}
      />

      <DetailsPageContent
        className={styles.container}
        isLoading={isLoading || isLoadingMembers}
      >
        {data && (
          <>
            <div className={styles.header}>
              <div className={styles.titleContainer}>
                <TextField
                  className={styles.title}
                  variant="Basic"
                  value={projectName}
                  placeholder="Add a title"
                  onChange={({ target }) => setProjectName(target.value)}
                  onBlur={() => handleUpdateProject("name")}
                  required
                  hasError={error.type === "name" && !!error.message}
                  footnote={error.type === "name" ? error.message : ""}
                />
                <div className={styles.actions}>
                  <Button
                    size="SM"
                    leftIcon="Plus"
                    onClick={() => setIsAddMemberActive(true)}
                  >
                    Add member
                  </Button>
                  {projectId !== userProfile?.project.projectId && (
                    <Button
                      variant="Secondary"
                      size="SM"
                      onClick={() => setCurrentProject(projectId)}
                      loading={isUpdatingProject}
                    >
                      Switch to project
                    </Button>
                  )}
                </div>
              </div>
              <TextField
                variant="Basic"
                value={projectDetails}
                placeholder="Add a description"
                onChange={({ target }) => setProjectDetails(target.value)}
                onBlur={() => handleUpdateProject("details")}
                hasError={error.type === "details" && !!error.message}
                footnote={error.type === "details" ? error.message : ""}
              />
            </div>

            <Tabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={tabs.map(({ header }) => header)}
            />

            <div className={styles.content}>{tabs[activeTab].content}</div>
          </>
        )}
      </DetailsPageContent>

      <AddMember
        projectId={id as string}
        objectDetailsKey={objectDetailsKey}
        membersKey={membersKey}
        members={membersData?.docs}
        existingMembers={data?.users}
        active={isAddMemberActive}
        close={() => setIsAddMemberActive(false)}
      />
    </MainLayout>
  );
};

export default ProjectDetails;
