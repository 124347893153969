import type { ReactNode } from "react";
import cn from "classnames";
import { Button, NebulaWordMark, ThemeSwitch } from "nebula-galaxy";

import { useTheme } from "context";
import { Theme } from "context/theme/theme";
import { OnboardingBannerPattern } from "assets";

import styles from "./onboarding-layout.module.scss";

interface OnboardingLayoutProps {
  children: ReactNode;
  isBannerActive?: boolean;
  isContentLogoActive?: boolean;
}

const OnboardingLayout = ({
  children,
  isBannerActive = true,
  isContentLogoActive,
}: OnboardingLayoutProps) => {
  const { theme, updateTheme } = useTheme();

  return (
    <div
      className={cn(styles.container, {
        [styles.containerNoBanner]: !isBannerActive,
      })}
    >
      {isBannerActive && (
        <div className={styles.banner}>
          <figure className={styles.bannerPattern}>
            <OnboardingBannerPattern />
          </figure>

          <figure className={styles.bannerLogo}>
            <NebulaWordMark />
          </figure>

          <div className={styles.bannerContent}>
            <h1 className={styles.bannerContentTitle}>A cloud for Africa</h1>
            <span className={cn("p3", styles.bannerContentDescription)}>
              Join Nebula and get access to cutting-edge cloud services designed
              to accelerate innovation, drive efficiency, and foster growth in
              the African market.
            </span>
          </div>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          {isContentLogoActive && (
            <figure className={styles.contentLogo}>
              <NebulaWordMark />
            </figure>
          )}
          {children}
        </div>
        <div className={styles.footer}>
          <div className={styles.footerText}>
            <span className={cn("cap1", styles.footerCopyright)}>
              © Nebula by Okra
            </span>
            <div className={styles.footerLinks}>
              <Button variant="SecondaryLink" size="SM">
                Terms
              </Button>
              <Button variant="SecondaryLink" size="SM">
                Privacy
              </Button>
            </div>
          </div>
          <ThemeSwitch theme={theme} toggleTheme={updateTheme} />
        </div>
      </div>
    </div>
  );
};

export default OnboardingLayout;
