import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import moment from "moment";
import { useReactQuery } from "hooks";

import cn from "classnames";
import styles from "./subnet.module.scss";

import { TextField, Divider, Button } from "nebula-galaxy";
import { MainLayout } from "layouts";
import { DetailsPageContent, DetailsPageHeader } from "components";


import { routes } from "config";
import { useAlert } from "context";
import { getSubnet, updateSubnet } from "services";
import DeleteSubnet from "./delete-subnet";

const queryKey = "vpc-details";

type ErrorType = "name" | "description";
type Error = {
  type: ErrorType;
  message: string;
};

const initialError: Error = {
  type: "description",
  message: "",
};

const SubnetDetails = () => {
  const { id } = useParams();
  const [error, setError] = useState<Error>({ ...initialError });
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const [isDeleteSubnetActive, setIsDeleteSubnetActive] = useState(false);
  const [isUpdatingSubnet, setIsUpdatingSubnet] = useState(false);
  const { data, isLoading } = useReactQuery({
    queryKey,
    queryFunction: () => getSubnet(id as string),
  });
  const { vpc, servers, ...subnet } = data || {};
  
  const activeServers = servers?.filter((item: any) => item.instanceState !== 'DELETED')
  const [subnetDetails, setSubnetDetails] = useState({
    name: subnet.name ?? "",
    description: subnet.description ?? "",
  });

  const onSuccess = (message: string) => {
    queryClient.invalidateQueries(queryKey);
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsUpdatingSubnet(false);
  };

  const cidrBlock = subnet?.cidr?.split(/[./]/) || [];

  const handleUpdateSubnet = (type: ErrorType) => {
    setError({ ...initialError });
    setIsUpdatingSubnet(true);
    updateSubnet(
        subnet.subnetId, 
        { ...subnetDetails }, 
        onSuccess, 
        (message) => {
            setError({ type, message })
            setIsUpdatingSubnet(false);
        }
    );
  };

  useEffect(() => {
    setSubnetDetails({
      name: subnet.name ?? "",
      description: subnet.description ?? "",
    });
  }, [subnet.name, subnet.description]);

  return (
    <MainLayout>
      <DetailsPageHeader
        breadcrumbs={[
          {
            label: "VPCs",
            url: routes.console.nebCompute.vpcs,
            urlComponent: ({ url, label }) => (
              <Link to={url as string}>{label}</Link>
            ),
          },
          {
            url: `${routes.console.nebCompute.vpcs}/${vpc?.vpcId}`,
            urlComponent: ({ url, label }) => (
              <Link to={url as string}>{label}</Link>
            ),
            label: isLoading ? "...." : vpc.name,
          },
          {
            label: isLoading ? "...." : subnet.name,
          },
        ]}
      />
      <DetailsPageContent
        className={styles.subnetDetails}
        isLoading={isLoading || isUpdatingSubnet}
      >
        <div>
          <div className={styles.header}>
            <TextField
              className={styles.title}
              variant="Basic"
              value={subnetDetails.name}
              placeholder="Add a name"
              onChange={({ target }) =>
                setSubnetDetails({ 
                    ...subnetDetails, 
                    name: target.value })
              }
              onBlur={() => handleUpdateSubnet("name")}
              hasError={error.type === "name" && !!error.message}
              footnote={error.type === "name" ? error.message : ""}
            />
            <TextField
              variant="Basic"
              value={subnetDetails.description}
              placeholder="Add a description"
              onChange={({ target }) =>
                setSubnetDetails({
                  ...subnetDetails,
                  description: target.value,
                })
              }
              onBlur={() => handleUpdateSubnet("description")}
              hasError={error.type === "description" && !!error.message}
              footnote={error.type === "description" ? error.message : ""}
            />
          </div>

          <Divider className={styles.divider} />

          <div className={styles.meta}>
            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>
                Subnet ID
              </span>
              <span className={cn("p5", styles.metaItemValue)}>
                {subnet.subnetId}
              </span>
            </div>

            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>
                Created by
              </span>
              <span className={cn("p5", styles.metaItemValue)}>
                {subnet.createdBy?.fullName}
              </span>
            </div>

            <div className={styles.metaItem}>
              <span className={cn("cap1", styles.metaItemTitle)}>
                Date created
              </span>
              <span className={cn("p5", styles.metaItemValue)}>
                {moment(subnet.createdAt).format("MMM D, YYYY, h:mm A")}
              </span>
            </div>
          </div>

          <Divider className={styles.divider} />
        </div>

        <div className={styles.subnetSection}>
          <div className={cn("p4 medium", styles.sectionHeader)}>Subnet CIDR block</div>

          <div className={styles.cidrBlock}>
            {cidrBlock?.map((item: string, index: number) => (
              <TextField
                key={index}
                variant="Basic"
                value={item}
                placeholder="Add a description"
                onChange={() => {}}
                onBlur={() => {}}
              />
            ))}
          </div>
        </div>

        <div className={styles.subnetSection}>
          <div className={cn("p4 medium", styles.sectionHeader)}>Subnet details</div>
          <Divider className={styles.divider} />
          <div className={styles.components}>
            <span className={cn("p4", styles.label)}>Gateway IP</span>
            <span className={cn("p4")}>{subnet.gateway_ip}</span>
          </div>
        </div>

        <div className={styles.subnetSection}>
          <div className={cn("p4 medium", styles.sectionHeader)}>VPC details</div>
          <Divider className={styles.divider} />
          <div className={styles.components}>
            <span className={cn("p4", styles.label)}>Name</span>
            <span className={cn("p4", styles.highlight)}>{vpc?.name}</span>
          </div>
          <div className={styles.components}>
            <span className={cn("p4", styles.label)}>ID</span>
            <span className={cn("p4")}>{vpc?.vpcId}</span>
          </div>
        </div>

        <div className={styles.subnetSection}>
            <div className={cn("p4 medium", styles.sectionHeader)}>Servers</div>
            <Divider className={styles.divider} />
            {activeServers?.length ? (
            <div className={styles.components}>
              <span className={cn("p4", styles.label)}>Instances</span>
              <div className={styles.group}>
                {activeServers?.map((servers: any) => (
                  <span className={styles.item} key={servers._id}>{servers.serverName}</span>
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.components}>
              <span className={cn("p4", styles.label)}>
                No Instance is associated with the VPC yet.
              </span>
            </div>
          )}
          </div>

        <div className={styles.subnetSection}>
            <div className={cn("p4 medium", styles.sectionHeader)}>Route table</div>
            <Divider className={styles.divider} />
            <div className={styles.components}>
              <span className={cn("p4", styles.label)}>Components</span>
              <span className={cn("p4, medium", styles.label)}>route_default</span>
            </div>
          </div>

        <div className={styles.deleteSubnetContainer}>
          <span className={cn("p3 medium", styles.deleteTitle)}>
            Delete subnet
          </span>
          <span className={cn("p5", styles.description)}>
            The subnet will be permanently deleted, and all resources within it
            will be removed.
          </span>
          <Button
            variant="Destructive"
            size="SM"
            className={styles.deleteVpcAction}
            onClick={() => setIsDeleteSubnetActive(true)}
          >
            Delete subnet
          </Button>
        </div>
      </DetailsPageContent>

      <DeleteSubnet
       active={isDeleteSubnetActive}
       close={() => setIsDeleteSubnetActive(false)}
       subnetDetails={subnet}
       servers={activeServers}
      />
    </MainLayout>
  );
};

export default SubnetDetails;
