import { useState } from "react";
import cn from "classnames";
import { Divider, Icon, Popper, ThemeSwitch } from "nebula-galaxy";

import { useAuth, useTheme } from "context";

import styles from "./navigation.module.scss";

interface UserMenuProps {
  openSettings: () => void;
}

const UserMenu = ({ openSettings }: UserMenuProps) => {
  const { theme, updateTheme } = useTheme();
  const { signOut, userProfile } = useAuth();

  const [isActive, setIsActive] = useState(false);

  const { fullName, email } = userProfile || {};

  return (
    <>
      <Popper
        className={styles.userMenuPopper}
        anchorElement={
          <button className={styles.selector} onClick={() => setIsActive(true)}>
            <div className={styles.selectorDisplay}>
              <span className={cn("p5", styles.selectorDisplayText)}>
                {fullName}
              </span>
            </div>

            <Icon className={styles.selectorDisplayIcon} name="ChevronDown" />
          </button>
        }
        isOpen={isActive}
        onClose={() => setIsActive(false)}
      >
        <div className={styles.selectorMenu}>
          <div className={styles.userDetails}>
            <span className={cn("p5 medium", styles.userFullName)}>
              {fullName}
            </span>
            <span className={cn("cap1", styles.userEmail)}>{email}</span>
          </div>

          <Divider className={styles.divider} />

          <div className={styles.selectorMenuList}>
            <button
              className={cn(styles.selectorMenuItem, styles.userMenuItem)}
              onClick={() => updateTheme(theme === "dark" ? "light" : "dark")}
            >
              <div>
                <Icon className={styles.userMenuIcon} name="SunMoon" />
                <span className="p5 medium">Theme</span>
              </div>
              <ThemeSwitch theme={theme} toggleTheme={updateTheme} />
            </button>
            <button
              className={cn(styles.selectorMenuItem, styles.userMenuItem)}
              onClick={openSettings}
            >
              <Icon className={styles.userMenuIcon} name="Settings" />
              <span className="p5 medium">Settings</span>
            </button>
          </div>

          <Divider className={styles.divider} />

          <div className={styles.selectorMenuList}>
            <button
              className={cn(styles.selectorMenuItem, styles.userMenuItem)}
              onClick={signOut}
            >
              <Icon className={styles.userMenuIcon} name="LogOut" />
              <span className="p5 medium">Logout</span>
            </button>
          </div>
        </div>
      </Popper>
    </>
  );
};

export default UserMenu;
