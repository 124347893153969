import { IObject } from "nebula-galaxy";

import { api } from "config";

export const fetchUsersLogs = async (body: IObject) => {
  return await api({ url: "users/search/logs", body });
};

export const fetchResourcesLogs = async (body: IObject) => {
  return await api({ url: "resources/search/logs", body });
};
