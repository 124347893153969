import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import cn from "classnames";

import { Callout, Icon, Tabs } from "nebula-galaxy";

import { MainLayout } from "layouts";
import { DetailsPageHeader, PageLoader } from "components";
import { routes } from "config";
import { useReactQuery } from "hooks";
import { fetchBucket } from "services";

import Objects from "./objects";
import Properties from "./properties";

import styles from "./bucket-details.module.scss";
import { useQueryClient } from "react-query";

const detailsQueryKey = "bucket-details";

const BucketDetails = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [activeTab, setActiveTab] = useState(0);

  const { data } = useReactQuery({
    queryKey: detailsQueryKey,
    queryFunction: () => fetchBucket(id as string),
  });

  const [isUploadingObjects, setIsUploadingObjects] = useState(false);

  const { bucketName, location } = data || {};

  const tabs = [
    {
      header: (
        <>
          <Icon name="Files" />
          <span className="p5 medium">Objects</span>
        </>
      ),
      content: (
        <Objects
          bucketId={id as string}
          setIsUploading={setIsUploadingObjects}
        />
      ),
    },
    {
      header: (
        <>
          <Icon name="FilePen" />
          <span className="p5 medium">Properties</span>
        </>
      ),
      content: <Properties queryKey={detailsQueryKey} {...data} />,
    },
  ];

  useEffect(() => {
    queryClient.invalidateQueries(detailsQueryKey);
  }, [detailsQueryKey, activeTab]);

  return (
    <MainLayout>
      <DetailsPageHeader
        breadcrumbs={[
          {
            label: "Storage",
            url: routes.console.nebStore,
            urlComponent: ({ url, label }) => (
              <Link to={url as string}>{label}</Link>
            ),
          },
          {
            label: `${bucketName}`,
          },
        ]}
      />
      {isUploadingObjects && (
        <Callout
          className={styles.uploadProgress}
          variant="Informational"
          title=""
          message="Uploading files..."
          onClose={() => setIsUploadingObjects(false)}
        />
      )}
      <div className={styles.bucketDetails}>
        <div className={styles.header}>
          <h5 className={cn("medium", styles.title)}>{bucketName || "..."}</h5>
        </div>

        <Tabs
          className={styles.tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs.map(({ header }) => header)}
        />

        <div className={styles.content}>{tabs[activeTab].content}</div>
      </div>
    </MainLayout>
  );
};

export default BucketDetails;
