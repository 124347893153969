import { IObject } from "nebula-galaxy";

import { api } from "config";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";

export const fetchProjects = async (body: IObject) => {
  return await api({ url: "projects/search", body });
};

export const fetchProject = async (id: string) => {
  return await api({ url: `projects/${id}`, method: "GET" });
};

export const fetchAPIKey = async () => {
  return await api({ url: `apikey`, method: "GET" });
};

export const fetchProjectBilling = async (projectId: string, body: IObject) => {
  return await api({ url: `billing/${projectId}/project`, body });
};

export const fetchProjectLogs = async (body: IObject) => {
  return await api({ url: "users/search/logs", body });
};

export const addProject = async (
  body: IObject,
  onSuccess: (message: string, projectId?: string) => void,
  onError: (message: string) => void
) => {
  const { data, success, message } = await api({
    url: "projects",
    body,
  });

  success
    ? onSuccess(message, data?.projectId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const updateProject = async (
  id: string,
  body: IObject,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `projects/${id}`,
    body,
    method: "PATCH",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const addMemberToProject = async (
  body: IObject,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: "projects/user",
    body,
    method: "PUT",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const deleteProject = async (
  projectId: string,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `projects/${projectId}`,
    method: "DELETE",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const generateAPIKey = async (
  onSuccess: (message: string, projectId?: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: "apikey/generate",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};
