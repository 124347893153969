import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";

import styles from "./carousel.module.scss";
import { Button, Icon } from "nebula-galaxy";

interface CarouselProps {
  title: ReactNode;
  items: ReactNode[];
}

const Carousel = ({ title, items }: CarouselProps) => {
  const itemWidth = 120;
  const gap = 12;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  const calculateContainerWidth = () => {
    if (carouselRef.current) {
      setContainerWidth(carouselRef.current.clientWidth);
    }
  };

  useEffect(() => {
    calculateContainerWidth();
    window.addEventListener("resize", calculateContainerWidth);
    return () => window.removeEventListener("resize", calculateContainerWidth);
  }, []);

  const itemsPerView = Math.floor(containerWidth / (itemWidth + gap));
  const maxIndex = Math.max(0, items.length - itemsPerView);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => Math.min(maxIndex, prevIndex + 1));
  };

  return (
    <div ref={carouselRef} className={styles.container}>
      <div className={styles.header}>
        <span className={cn("p5", styles.title)}>{title}</span>

        <div className={styles.navigation}>
          <Button
            variant="TertiaryIcon"
            size="SM"
            onClick={prevSlide}
            disabled={currentIndex === 0}
          >
            <Icon name="ChevronLeft" />
          </Button>

          <Button
            variant="TertiaryIcon"
            size="SM"
            onClick={nextSlide}
            disabled={currentIndex >= maxIndex}
          >
            <Icon name="ChevronRight" />
          </Button>
        </div>
      </div>

      <div className={styles.trackContainer}>
        <ul
          className={styles.track}
          style={{
            transform: `translateX(-${currentIndex * (itemWidth + gap)}px)`,
          }}
        >
          {items.map((item, index) => (
            <li key={`carousel-${index}`} className={styles.item}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Carousel;
