import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAlert } from "context";
import { routes } from "config";

import { Button, Callout, Modal, TextField } from "nebula-galaxy";

import { deleteVPC } from "services";

import styles from "./vpcs.module.scss";

interface DeleteVpcProps {
  active: boolean;
  close: () => void;
  vpcDetails: any;
  servers: any
}

const DeleteVpc = ({ active, close, vpcDetails, servers }: DeleteVpcProps) => {
  const navigate = useNavigate();
  const [isDeletingVPC, setIsDeletingVPC] = useState(false);
  const [vpcName, setVpcName] = useState("");
  const { showAlert } = useAlert();

  const { vpc } = vpcDetails || {};

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsDeletingVPC(false)
    navigate(routes.console.nebCompute.vpcs);
  };

  const onError = (message: string) => {
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsDeletingVPC(false)
  };

  const handleDeleteVPC = async () => {
    setIsDeletingVPC(true);
    deleteVPC(
      vpc.vpcId,
      onSuccess,
      onError
    );
  };

  return (
      <Modal
        className={styles.terminateModal}
        active={active && !servers?.length}
        onClose={close}
        variant="Standard"
        title=""
        actions={
          <>
            <Button variant="Tertiary" size="SM" onClick={close}>
              Cancel
            </Button>

            <Button
              variant="Primary"
              size="SM"
              onClick={handleDeleteVPC}
              disabled={vpc?.name !== vpcName}
              loading={isDeletingVPC}
            >
              Delete VPC
            </Button>
          </>
        }
        hideHeader
      >
        <Callout
          variant="Warning"
          title={`Delete '${vpc?.name}' VPC`}
          message="The VPC and its associated subnet(s) will be permanently deleted. To proceed, please confirm by providing the name of the VPC."
          isCloseVisible={false}
        />

        <TextField
          className={styles.textfield}
          variant="Filled"
          label="Confirm with VPC name"
          name="vpcName"
          onChange={({ target }) => setVpcName(target.value)}
          required
        />
      </Modal>
  );
};

export default DeleteVpc;
