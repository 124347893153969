import moment from "moment";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Button, IObject, StatusChip } from "nebula-galaxy";

import { getRole } from "utils/functions";
import { resourceOptions as resources } from "utils/data";
import { routes } from "config";

import styles from "./project-details.module.scss";

export const membersColumns = [
  {
    key: "fullName",
    header: "Names",
    renderer: ({ user }: IObject) => (
      <Link
        onClick={(e) => e.stopPropagation()}
        to={`${routes.settings.accessManager}/${user?.userId}`}
      >
        <Button variant="PrimaryLinkButton" size="MD">
          {user?.fullName}
        </Button>
      </Link>
    ),
  },
  {
    key: "email",
    header: "Email",
    renderer: ({ user }: IObject) => <>{user?.email}</>,
  },
  {
    key: "role",
    header: "Project role",
    renderer: ({ role }: IObject) => (
      <StatusChip variant="Neutral" text={getRole(role?.name)} />
    ),
  },
];

export const auditLogsColumns = (users?: IObject[]) => {
  const usersOptions = users?.map(({ userId, fullName, email }) => ({
    label: fullName,
    value: userId,
    display: (
      <div className={styles.memberOption}>
        <span className={cn("p5 medium", styles.memberFullName)}>
          {fullName || email}
        </span>
        <span className={cn("cap1", styles.memberEmail)}>{email}</span>
      </div>
    ),
  }));

  const resourceOptions = resources.map(({ label, value }) => ({
    label,
    value,
  }));

  return [
    {
      key: "createdBy",
      header: "Created by",
      thClassName: styles.thCreatedBy,
      filter: usersOptions
        ? { name: "users", options: usersOptions }
        : undefined,
      renderer: ({ email, action, user }: IObject) => (
        <>
          <Link
            className={styles.userLink}
            to={`${routes.settings.accessManager}/${user || ""}`}
          >
            {email}
          </Link>{" "}
          {action}
        </>
      ),
    },
    {
      key: "resource",
      header: "Resource",
      thClassName: styles.thResource,
      filter: { name: "resources", options: resourceOptions },
      renderer: ({ resource }: IObject) =>
        resource ? (
          <StatusChip variant="Neutral" text={resource} />
        ) : (
          "Not available"
        ),
    },
    {
      key: "ip",
      header: "IP Address",
    },
    {
      key: "createdAt",
      header: "Date",
      renderer: ({ createdAt }: IObject) => (
        <>{moment(createdAt).format("MMM D, YYYY, h:mm A")}</>
      ),
    },
  ];
};

export const serverBillingColumns = [
  { key: "serverName", header: "Server name" },
  { key: "instanceType", header: "Instance type" },
  {
    key: "cost",
    header: "Cost per hour",
    renderer: ({ cost }: IObject) => <>₦{cost?.toLocaleString()}</>,
  },
  {
    key: "usage",
    header: "Usage",
    renderer: ({ usage }: IObject) => <>{usage || "0"} HRS</>,
  },
  {
    key: "amount",
    header: "Amount",
    renderer: ({ amount }: IObject) => <>₦{amount?.toLocaleString()}</>,
  },
];

export const storageBillingColumns = [
  { key: "bucketName", header: "Bucket name" },
  {
    key: "cost",
    header: "Cost per 100 GiB p/m",
    renderer: ({ size }: IObject) => <>₦{size?.cost?.toLocaleString()}</>,
  },
  {
    key: "usage",
    header: "Usage",
    renderer: ({ size }: IObject) => <>{size?.usage || "0"} GiB</>,
  },
  {
    key: "amount",
    header: "Amount",
    renderer: ({ size }: IObject) => <>₦{size?.amount?.toLocaleString()}</>,
  },
];
