import cn from "classnames";
import { Icon } from "nebula-galaxy";

import WindowsLogo from "assets/svgs/windows.svg";
import UbuntuLogo from "assets/svgs/ubuntu.svg";
import OracleLogo from "assets/svgs/oracle.svg";
import RedHatLogo from "assets/svgs/red-hat.svg";
import SuseLogo from "assets/svgs/suse.svg";
import DebianLogo from "assets/svgs/debian.svg";

import styles from "pages/console/neb-compute/instances/instances.module.scss";
import bucketStyles from "pages/console/neb-store/buckets/buckets.module.scss";

export const resources = ["Server", "Storage", "Database"];

export const operatingSysOptions = [
  {
    logo: WindowsLogo,
    title: "Windows",
    value: "windows",
    versions: [
      "Windows 14.04-LTS - 64 bit",
      "Windows 16.04-LTS - 64 bit",
      "Windows 18.04-LTS - 64 bit",
      "Windows Server 20.04-LTS - 64 bit",
    ],
  },
  {
    logo: UbuntuLogo,
    title: "Ubuntu",
    value: "ubuntu",
    versions: [
      "Ubuntu 14.04-LTS - 64 bit",
      "Ubuntu 16.04-LTS - 64 bit",
      "Ubuntu 18.04-LTS - 64 bit",
      "Ubuntu Server 20.04-LTS - 64 bit",
    ],
  },
  {
    logo: OracleLogo,
    title: "Oracle",
    value: "oracle",
    versions: [
      "Oracle 14.04-LTS - 64 bit",
      "Oracle 16.04-LTS - 64 bit",
      "Oracle 18.04-LTS - 64 bit",
      "Oracle Server 20.04-LTS - 64 bit",
    ],
  },
  {
    logo: RedHatLogo,
    title: "Red Hat",
    value: "red-hat",
    versions: [
      "Red Hat 14.04-LTS - 64 bit",
      "Red Hat 16.04-LTS - 64 bit",
      "Red Hat 18.04-LTS - 64 bit",
      "Red Hat Server 20.04-LTS - 64 bit",
    ],
  },
  {
    logo: SuseLogo,
    title: "SUSE",
    value: "suse",
    versions: [
      "SUSE 14.04-LTS - 64 bit",
      "SUSE 16.04-LTS - 64 bit",
      "SUSE 18.04-LTS - 64 bit",
      "SUSE Server 20.04-LTS - 64 bit",
    ],
  },
  {
    logo: DebianLogo,
    title: "Debian",
    value: "debian",
    versions: [
      "Debian 14.04-LTS - 64 bit",
      "Debian 16.04-LTS - 64 bit",
      "Debian 18.04-LTS - 64 bit",
      "Debian Server 20.04-LTS - 64 bit",
    ],
  },
];

// export const computeOptions = [
//   {
//     icon: BasicComputeIcon,
//     title: "Basic",
//     value: "basic",
//     description:
//       "Offers standard compute resources for general-purpose workloads.",
//     options: [
//       "basic-a",
//       "standard-a",
//       "av2-series",
//       "d-series",
//       "ds-series",
//       "dv2-series",
//       "dsv2-series",
//     ],
//   },
//   {
//     icon: OptimizedComputeIcon,
//     title: "Compute optimized",
//     value: "compute-optimized",
//     description: "Provides high-performance compute resources.",
//     options: [
//       "basic-a",
//       "standard-a",
//       "av2-series",
//       "d-series",
//       "ds-series",
//       "dv2-series",
//       "dsv2-series",
//     ],
//   },
//   {
//     icon: MemoryOptimizedComputeIcon,
//     title: "Memory optimized",
//     value: "memory-optimized",
//     description:
//       "Tailored for relational database servers and medium to large caches",
//     options: [
//       "basic-a",
//       "standard-a",
//       "av2-series",
//       "d-series",
//       "ds-series",
//       "dv2-series",
//       "dsv2-series",
//     ],
//   },
// ];

// export const instanceOptions = [
//   {
//     id: "basic-a",
//     title: "Basic A",
//     options: [
//       {
//         title: "A0/Basic_A0 - ₦0.30 per hour",
//         value: "basic_a0",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A1/Basic_A1 - ₦0.30 per hour",
//         value: "basic_a1",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A2/Basic_A2 - ₦0.30 per hour",
//         value: "basic_a2",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A3/Basic_A3 - ₦0.30 per hour",
//         value: "basic_a3",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A4/Basic_A4 - ₦0.30 per hour",
//         value: "basic_a4",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//     ],
//   },
//   {
//     id: "standard-a",
//     title: "Standard A",
//     options: [
//       {
//         title: "A0/Standard_A0 - ₦0.30 per hour",
//         value: "standard_a0",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A1/Standard_A1 - ₦0.30 per hour",
//         value: "standard_a1",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A2/Standard_A2 - ₦0.30 per hour",
//         value: "standard_a2",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A3/Standard_A3 - ₦0.30 per hour",
//         value: "standard_a3",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A4/Standard_A4 - ₦0.30 per hour",
//         value: "standard_a4",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//     ],
//   },
//   {
//     id: "av2-series",
//     title: "Av2-series",
//     options: [
//       {
//         title: "A0/Av2-series_A0 - ₦0.30 per hour",
//         value: "av2_series0",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A1/Av2-series_A1 - ₦0.30 per hour",
//         value: "av2_series1",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A2/Av2-series_A2 - ₦0.30 per hour",
//         value: "av2_series2",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A3/Av2-series_A3 - ₦0.30 per hour",
//         value: "av2_series3",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A4/Av2-series_A4 - ₦0.30 per hour",
//         value: "av2_series4",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//     ],
//   },
//   {
//     id: "d-series",
//     title: "D-series",
//     options: [
//       {
//         title: "A0/D_series_A0 - ₦0.30 per hour",
//         value: "d_series_a0",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A1/D_series_A1 - ₦0.30 per hour",
//         value: "d_series_a1",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A2/D_series_A2 - ₦0.30 per hour",
//         value: "d_series_a2",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A3/D_series_A3 - ₦0.30 per hour",
//         value: "d_series_a3",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A4/D_series_A4 - ₦0.30 per hour",
//         value: "d_series_a4",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//     ],
//   },
//   {
//     id: "ds-series",
//     title: "DS-series",
//     options: [
//       {
//         title: "A0/DS_series_A0 - ₦0.30 per hour",
//         value: "ds_series_a0",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A1/DS_series_A1 - ₦0.30 per hour",
//         value: "ds_series_a1",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A2/DS_series_A2 - ₦0.30 per hour",
//         value: "ds_series_a2",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A3/DS_series_A3 - ₦0.30 per hour",
//         value: "ds_series_a3",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A4/DS_series_A4 - ₦0.30 per hour",
//         value: "ds_series_a4",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//     ],
//   },
//   {
//     id: "dv2-series",
//     title: "Dv2-series",
//     options: [
//       {
//         title: "A0/DS_series_A0 - ₦0.30 per hour",
//         value: "dv2_series_a0",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A1/DS_series_A1 - ₦0.30 per hour",
//         value: "dv2_series_a1",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A2/DS_series_A2 - ₦0.30 per hour",
//         value: "dv2_series_a2",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A3/DS_series_A3 - ₦0.30 per hour",
//         value: "dv2_series_a3",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A4/DS_series_A4 - ₦0.30 per hour",
//         value: "dv2_series_a4",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//     ],
//   },
//   {
//     id: "dsv2-series",
//     title: "DSv2-series",
//     options: [
//       {
//         title: "A0/DSv2_series_A0 - ₦0.30 per hour",
//         value: "dsv2_series_a0",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A1/DSv2_series_A1 - ₦0.30 per hour",
//         value: "dsv2_series_a1",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A2/DSv2_series_A2 - ₦0.30 per hour",
//         value: "dsv2_series_a2",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A3/DSv2_series_A3 - ₦0.30 per hour",
//         value: "dsv2_series_a3",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//       {
//         title: "A4/DSv2_series_A4 - ₦0.30 per hour",
//         value: "dsv2_series_a4",
//         vcpu: "1",
//         memory: "768 MB",
//         maxTempDiskSize: "20 GB",
//         maxOSDisk: "300",
//         maxTempStorage: "300",
//         maxDataDisk: "1/1x300",
//         maxNICs: "1",
//       },
//     ],
//   },
// ];

export const computePower = [
  {
    title: "General compute-plus",
    value: "general-compute-plus",
    options: [
      "general-compute-plus-c7n",
      "general-compute-plus-c6s",
      "general-compute-plus-c6",
      "general-compute-plus-c3",
    ],
  },
  {
    title: "General computing",
    value: "general-computing",
    options: [
      "general-computing-c7n",
      "general-computing-c6s",
      "general-computing-c6",
      "general-computing-c3",
    ],
  },
  {
    title: "Memory-optimized",
    value: "memory-optimized",
    options: [
      "memory-optimized-c7n",
      "memory-optimized-c6s",
      "memory-optimized-c6",
      "memory-optimized-c3",
    ],
  },
  {
    title: "Large-memory",
    value: "large-memory",
    options: [
      "large-memory-c7n",
      "large-memory-c6s",
      "large-memory-c6",
      "large-memory-c3",
    ],
  },
  {
    title: "High-performing computing",
    value: "high-performing-computing",
    options: [
      "high-performing-computing-c7n",
      "high-performing-computing-c6s",
      "high-performing-computing-c6",
      "high-performing-computing-c3",
    ],
  },
  {
    title: "Disk-intensive",
    value: "disk-intensive",
    options: [
      "disk-intensive-c7n",
      "disk-intensive-c6s",
      "disk-intensive-c6",
      "disk-intensive-c3",
    ],
  },
];

export const instanceOptions = [
  {
    id: "general-compute-plus-c7n",
    title: "General computing-plus c7n",
    options: [
      {
        title: "c7n.large.2 - ₦0.30 per hour",
        value: "c7n_large_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.large.4 - ₦0.30 per hour",
        value: "c7n_large_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.xlarge.2 - ₦0.30 per hour",
        value: "c7n_xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.xlarge.4 - ₦0.30 per hour",
        value: "c7n_xlarge_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.2xlarge.2 - ₦0.30 per hour",
        value: "c7n_2xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.2xlarge.4 - ₦0.30 per hour",
        value: "c7n_2xlarge_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.3xlarge.2 - ₦0.30 per hour",
        value: "c7n_3xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
    ],
  },
  {
    id: "general-compute-plus-c6s",
    title: "General computing-plus c6s",
    options: [
      {
        title: "c7n.large.2 - ₦0.30 per hour",
        value: "c7n_large_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.large.4 - ₦0.30 per hour",
        value: "c7n_large_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.xlarge.2 - ₦0.30 per hour",
        value: "c7n_xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.xlarge.4 - ₦0.30 per hour",
        value: "c7n_xlarge_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.2xlarge.2 - ₦0.30 per hour",
        value: "c7n_2xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.2xlarge.4 - ₦0.30 per hour",
        value: "c7n_2xlarge_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.3xlarge.2 - ₦0.30 per hour",
        value: "c7n_3xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
    ],
  },
  {
    id: "general-compute-plus-c6",
    title: "General computing-plus c6",
    options: [
      {
        title: "c7n.large.2 - ₦0.30 per hour",
        value: "c7n_large_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.large.4 - ₦0.30 per hour",
        value: "c7n_large_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.xlarge.2 - ₦0.30 per hour",
        value: "c7n_xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.xlarge.4 - ₦0.30 per hour",
        value: "c7n_xlarge_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.2xlarge.2 - ₦0.30 per hour",
        value: "c7n_2xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.2xlarge.4 - ₦0.30 per hour",
        value: "c7n_2xlarge_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.3xlarge.2 - ₦0.30 per hour",
        value: "c7n_3xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
    ],
  },
  {
    id: "general-compute-plus-c3",
    title: "General computing-plus c3",
    options: [
      {
        title: "c7n.large.2 - ₦0.30 per hour",
        value: "c7n_large_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.large.4 - ₦0.30 per hour",
        value: "c7n_large_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.xlarge.2 - ₦0.30 per hour",
        value: "c7n_xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.xlarge.4 - ₦0.30 per hour",
        value: "c7n_xlarge_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.2xlarge.2 - ₦0.30 per hour",
        value: "c7n_2xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.2xlarge.4 - ₦0.30 per hour",
        value: "c7n_2xlarge_4",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
      {
        title: "c7n.3xlarge.2 - ₦0.30 per hour",
        value: "c7n_3xlarge_2",
        vcpu: "2",
        memory: "4 GB",
        cpu: "Intel Ice Lake 2.6GHz",
        maxBandwith: "0.8 / 4 Gbit/s",
        packetPerSec: "400,000 PPS",
      },
    ],
  },
];

export const instanceActions = [
  {
    label: "Reboot",
    value: "reboot",
    display: (
      <span className={styles.actionItem}>
        <Icon className={styles.actionItemIcon} name="RotateCw" />
        <span className={cn("p5 medium", styles.actionItemText)}>Reboot</span>
      </span>
    ),
  },
  {
    label: "Delete",
    value: "delete",
    display: (
      <span className={styles.actionItem}>
        <Icon className={styles.actionItemIcon} name="Trash2" />
        <span className={cn("p5 medium", styles.actionItemText)}>Delete</span>
      </span>
    ),
  },
];

export const bucketActions = [
  {
    label: "Empty bucket",
    value: "empty",
    display: (
      <span className={bucketStyles.actionItem}>
        <Icon className={bucketStyles.actionItemIcon} name="Eraser" />
        <span className={cn("p5 medium", bucketStyles.actionItemText)}>
          Empty bucket
        </span>
      </span>
    ),
  },
  {
    label: "Delete bucket",
    value: "delete",
    display: (
      <span className={bucketStyles.actionItem}>
        <Icon className={bucketStyles.actionItemIcon} name="Trash2" />
        <span className={cn("p5 medium", bucketStyles.actionItemText)}>
          Delete bucket
        </span>
      </span>
    ),
  },
];

export const cidrBlockOptions = Array.from({ length: 33 }, (_, i) => i.toString())

