import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";

import { Button, Callout, PasswordTextField } from "nebula-galaxy";

import { useAlert } from "context";
import { OnboardingLayout } from "layouts";
import { api, routes } from "config";
import { getFormValues, isFormValid } from "utils/functions";

import styles from "../auth.module.scss";

const initialFormData = {
  password: {
    value: "",
    valid: false,
  },
  confirmPassword: {
    value: "",
    valid: false,
  },
};

const ResetPassword = () => {
  const { showAlert } = useAlert();
  const { code } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [error, setError] = useState("");

  const { password, confirmPassword } = getFormValues(formData);

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement>,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError("");
    setIsLoading(true);

    const { success, message } = await api({
      url: `auth/password-resets/${code}`,
      body: { password},
      isAuthRequired: false
    });

    if (success) {
      showAlert({
        active: true,
        variant: "Success",
        title: "Password reset successful",
        message: "You can now login with your new password",
        isMultiLine: true,
      });

      setFormData({ ...initialFormData });

      setTimeout(() => {
        navigate(routes.auth.signIn);
      }, 5000);
    } else {
      console.error(message);
      setError(message);
    }

    setIsLoading(false);
  };

  return (
    <OnboardingLayout isBannerActive={false} isContentLogoActive>
      <div className={styles.header}>
        <h5 className={cn("medium", styles.title)}>Reset your password</h5>
      </div>
      <form onSubmit={submit}>
        <div className={styles.form}>
          {error && (
            <Callout
              title=""
              variant="Critical"
              message={error}
              isCloseVisible={false}
            />
          )}

          <PasswordTextField
            label="Password"
            name="password"
            value={password}
            onChange={handleOnChange}
            required
          />

          <PasswordTextField
            label="Confirm password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleOnChange}
            hasRules={false}
            required
          />

          <Button
            size="LG"
            type="submit"
            loading={isLoading}
            disabled={!isFormValid(formData) || password !== confirmPassword}
          >
            Reset password
          </Button>
        </div>
      </form>
    </OnboardingLayout>
  );
};

export default ResetPassword;
