import type { ReactNode } from "react";
import cn from "classnames";

import { Breadcrumb } from "nebula-galaxy";

import styles from "./details-page-header.module.scss";

type Step = {
  label: ReactNode;
  url?: string;
  onClick?: () => void;
  urlComponent?: (step: Step) => ReactNode;
  disabled?: boolean;
};

interface DetailsPageHeaderProps {
  className?: string;
  breadcrumbs: Step[];
  actions?: ReactNode;
}

const DetailsPageHeader = ({
  className,
  breadcrumbs,
  actions,
}: DetailsPageHeaderProps) => (
  <div className={cn(className, styles.container)}>
    <Breadcrumb steps={breadcrumbs} />
    {actions && <div className={styles.actions}>{actions}</div>}
  </div>
);

export default DetailsPageHeader;
