import { IObject } from "nebula-galaxy";
import { api } from "config";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";

export const getKeypairs = async (body: IObject) => {
    return await api({ url: "networks/key-pairs/search", body });
};

export const createKeyPairs = async (
    body: IObject,
    onSuccess: (message: string, data: any) => void,
    onError: (message: string) => void
  ) => {
    const {success, message, data} = await api({
      url: "networks/key-pairs",
      body,
    });
  
    success
      ? onSuccess(message, data)
      : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const deleteKeypair = async (
    keyPairId: string,
    onSuccess: (message: string) => void,
    onError: (message: string) => void
  ) => {
    const { success, message } = await api({
      url: `networks/key-pairs/${keyPairId}`,
      method: "DELETE",
    });
  
    success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
  };