import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import moment from "moment";

import { Button, CopyElement, Modal, StatusChip } from "nebula-galaxy";
import { MainLayout } from "layouts";
import { DetailsPageContent, DetailsPageHeader, PageLoader } from "components";
import { useReactQuery } from "hooks";
import { deleteObject, fetchBucket, fetchObject } from "services";
import { routes } from "config";
import { formatFileSize, getFileFormat } from "utils/functions";
import { useAlert } from "context";

import styles from "./bucket-details.module.scss";

const bucketQueryKey = "bucket-details";
const objectQueryKey = "object-details";

const ObjectDetails = () => {
  const navigate = useNavigate();
  const { bucketId, objectId } = useParams();
  const { showAlert } = useAlert();

  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [isDeletingObject, setIsDeletingObject] = useState(false);

  const { data: bucketDetails, isLoading: isLoadingBucketDetails } =
    useReactQuery({
      queryKey: bucketQueryKey,
      queryFunction: () => fetchBucket(bucketId as string),
    });

  const { data, isLoading: isLoadingObjectDetails } = useReactQuery({
    queryKey: objectQueryKey,
    queryFunction: () => fetchObject(bucketId as string, objectId as string),
  });
  const { bucketName, isPrivate } = bucketDetails || {};
  const { objectKey, fileSize, createdAt, lastModified, nrn, location } =
    data || {};

  const isLoading = isLoadingBucketDetails || isLoadingObjectDetails;

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsDeletingObject(false);
    setIsDeleteModalActive(false);
    navigate(`${routes.console.nebStore}/${bucketId}`);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsDeletingObject(false);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = location;
    link.download = objectKey;
    link.click();
  };

  const handleDeleteObject = () => {
    setIsDeletingObject(true);

    deleteObject(bucketId as string, objectId as string, onSuccess, onError);
  };

  return (
    <MainLayout>
      {isLoading ? (
        <PageLoader isMini />
      ) : (
        <>
          <DetailsPageHeader
            breadcrumbs={[
              {
                label: "Storage",
                url: routes.console.nebStore,
                urlComponent: ({ url, label }) => (
                  <Link to={url as string}>{label}</Link>
                ),
              },
              {
                label: bucketName,
                url: `${routes.console.nebStore}/${bucketId}`,
                urlComponent: ({ url, label }) => (
                  <Link to={url as string}>{label}</Link>
                ),
              },
              {
                label: objectKey,
              },
            ]}
          />
          <DetailsPageContent
            className={styles.objectDetails}
            isLoading={isLoading}
          >
            <div className={styles.objectHeader}>
              <h5 className={cn("h5 medium", styles.headerTitle)}>
                {objectKey}
              </h5>
              <div className={styles.objectActions}>
                <CopyElement
                  className={styles.copyInstanceId}
                  element={
                    <Button
                      size="SM"
                      rightIcon="Copy"
                      variant="Secondary"
                      onClick={() => {}}
                    >
                      Copy URL
                    </Button>
                  }
                  value={location}
                  showIcon={false}
                />

                <a href={location} target="_blank" rel="noreferrer">
                  <Button
                    variant="Secondary"
                    size="SM"
                    rightIcon="ArrowUpRight"
                  >
                    Open
                  </Button>
                </a>

                <Button
                  variant="Secondary"
                  size="SM"
                  leftIcon="Download"
                  onClick={handleDownload}
                >
                  Download
                </Button>

                <Button
                  variant="Secondary"
                  size="SM"
                  leftIcon="Trash2"
                  onClick={() => setIsDeleteModalActive(true)}
                >
                  Delete
                </Button>
              </div>
            </div>

            <div className={styles.objectMeta}>
              {/* <div>
                <span className={cn("cap1", styles.metaTitle)}>
                  Nebula resource name (NRN)
                </span>
                {nrn ? (
                  <CopyElement
                    className={styles.copyElement}
                    element={
                      <span className={cn("p5", styles.metaValue)}>{nrn}</span>
                    }
                    value={nrn}
                  />
                ) : (
                  <span className={cn("p5", styles.metaValue)}>-</span>
                )}
              </div> */}

              <div>
                <span className={cn("cap1", styles.metaTitle)}>Object URL</span>
                <CopyElement
                  className={cn("p5", styles.copyElement, styles.metaValue)}
                  element={location}
                  value={location}
                />
              </div>
            </div>

            <div className={styles.objectSection}>
              <div className={cn("p4 medium", styles.sectionHeader)}>
                Properties
              </div>
              <div>
                <div className={styles.sectionItem}>
                  <span className={cn("p5", styles.sectionTitle)}>Size</span>
                  <span className={cn("p5", styles.sectionValue)}>
                    {formatFileSize(fileSize)}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={cn("p5", styles.sectionTitle)}>Type</span>
                  <span className={cn("p5", styles.sectionValue)}>
                    {getFileFormat(objectKey)}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={cn("p5", styles.sectionTitle)}>
                    Public access
                  </span>
                  <span className={cn("p5", styles.sectionValue)}>
                    <StatusChip
                      variant={isPrivate ? "Neutral" : "Critical"}
                      text={isPrivate ? "Disabled" : "Enabled"}
                    />
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={cn("p5", styles.sectionTitle)}>
                    Versioning
                  </span>
                  <span className={cn("p5", styles.sectionValue)}>
                    <StatusChip variant="Success" text="Enabled" />
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={cn("p5", styles.sectionTitle)}>Created</span>
                  <span className={cn("p5", styles.sectionValue)}>
                    {moment(createdAt).format("MMM D, YYYY, h:mm A")}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={cn("p5", styles.sectionTitle)}>
                    Last modified
                  </span>
                  <span className={cn("p5", styles.sectionValue)}>
                    {moment(lastModified).format("MMM D, YYYY, h:mm A")}
                  </span>
                </div>
              </div>
            </div>
          </DetailsPageContent>

          <Modal
            active={isDeleteModalActive}
            onClose={() => setIsDeleteModalActive(false)}
            variant="Warning"
            title="Delete object"
            actions={
              <>
                <Button
                  variant="Tertiary"
                  size="SM"
                  onClick={() => setIsDeleteModalActive(false)}
                >
                  Cancel
                </Button>

                <Button
                  variant="Destructive"
                  size="SM"
                  onClick={handleDeleteObject}
                  loading={isDeletingObject}
                >
                  Delete object
                </Button>
              </>
            }
          >
            <span className="p5">
              {objectKey} will be permanently removed from your bucket. Are you
              sure you want to proceed?
            </span>
          </Modal>
        </>
      )}
    </MainLayout>
  );
};

export default ObjectDetails;
