import { createContext, useContext, useState, useEffect } from "react";

import type { IObject } from "nebula-galaxy";

import { api, routes } from "config";
import { useAuth } from "../auth";
import { useAlert } from "../alert";
import { getLocalUser, setLocalUser } from "utils/functions";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";
import { useNavigate } from "react-router-dom";

interface ProjectsType {
  isLoading: boolean;
  isUpdatingProject: boolean;
  projects: IObject[];
  currentProject: IObject;
  setCurrentProject: (e: string) => void;
}

const Projects = createContext<ProjectsType>({
  isLoading: true,
  isUpdatingProject: false,
  projects: [],
  currentProject: {},
  setCurrentProject: () => {},
});

export const useProjects = () => useContext(Projects);

export const ProjectsProvider = ({ children }: IObject) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdatingProject, setIsUpdatingProject] = useState<boolean>(false);
  const [projects, setProjects] = useState<IObject[]>([]);
  const navigate = useNavigate();

  const { userProfile, fetchUserProfile } = useAuth();
  const { showAlert } = useAlert();

  useEffect(() => {
    fetchProjects();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProjects = async () => {
    const response = await api({
      url: "projects/search",
    });

    const { success, data } = response || {};

    if (success) {
      const docs = data?.docs;
      setProjects(docs);
    }

    setIsLoading(false);
  };

  const getCurrentProject = () => {
    const project = projects.find(
      ({ projectId }) => projectId === userProfile?.project?.projectId
    );

    return project || projects?.[0] || {};
  };

  const setCurrentProject = async (projectId: string) => {
    setIsUpdatingProject(true);

    const { data, success, message } = await api({
      url: "users/project",
      body: { projectId },
      method: "PATCH",
    });

    if (success) {
      const localUser = getLocalUser();
      const newUser = { ...localUser, user: data.user };

      showAlert({
        active: true,
        variant: "Success",
        message,
      });

      setLocalUser(newUser);
      fetchUserProfile();
      navigate(routes.console.home);
    } else {
      console.error(message);
      showAlert({
        variant: "Critical",
        message: message || DEFAULT_ERROR_MESSAGE,
        active: true,
      });
    }

    setIsUpdatingProject(false);
  };

  return (
    <Projects.Provider
      value={{
        isLoading,
        isUpdatingProject,
        projects,
        currentProject: getCurrentProject(),
        setCurrentProject,
      }}
    >
      {children}
    </Projects.Provider>
  );
};
