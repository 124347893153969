const OnboardingBannerPattern = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="480"
      height="832"
      fill="none"
      viewBox="0 0 480 832"
    >
      <g clipPath="url(#clip0_58_3151)">
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-850.93-20.69l272.14 272.14 860.75 860.75M-828.37-43.25l272.14 272.14 860.75 860.75M-805.81-65.81l272.14 272.14 860.75 860.75M-783.25-88.37l272.15 272.15 860.74 860.74M-760.69-110.93l272.14 272.14 860.75 860.75M-738.13-133.49l272.14 272.14L394.76 999.4M-715.57-156.05l272.14 272.14 860.75 860.75M-693.01-178.61l272.15 272.15 860.74 860.74M-670.45-201.17L-398.3 70.98l860.74 860.74M-647.89-223.73l272.14 272.14L485 909.16M-625.33-246.29l272.14 272.14L507.56 886.6M-602.77-268.85L-330.62 3.3l860.74 860.74M-580.21-291.41l272.15 272.15 860.74 860.74M-557.65-313.97l272.14 272.14 860.75 860.75M-535.09-336.53l272.14 272.14L597.8 796.36M-512.53-359.09c92.8 89.05 178.62 185 270.88 274.58C-171.12-16.02-100.82 52.45-29.52 120.2c67.1 63.75 132.82 129.89 195.97 197.57 68.4 73.31 138.39 144.43 209.1 215.46 80.72 81.1 165.54 157.99 244.81 240.56M-489.97-381.65c95.47 89.5 168.79 199.24 262.84 290.08 36.48 35.24 70.39 70.14 111.4 100.47C-77.52 37.16-37.6 63.02.23 91.8c73.8 56.16 138.87 126.37 193.4 201.17 29.73 40.78 57.94 82.7 89.73 121.94 32.27 39.83 69.91 75.49 106.93 110.85 81.75 78.09 175.06 142.83 252.64 225.47"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-467.41-404.21c96.98 94.1 154.71 219.88 249.51 315.75 36.33 36.73 73.57 65.44 119.95 88.6C-54.7 21.74-9.38 38.94 33.4 61.5c87.53 46.18 145.26 119.59 189.64 206.42 24.49 47.91 46.38 97.27 74.5 143.23 27.3 44.62 63.08 82.32 102.74 116.24 84.75 72.48 186.93 120.65 265.19 201.28"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-444.85-426.77c98.02 101.4 138.24 243.44 233.39 346.81C-138.68-.9-28 .29 68.4 30.2c45.66 14.16 88.35 35.81 118.9 73.59 32.42 40.1 50.44 90.56 66.37 138.96 35.36 107.48 58.04 220.31 154.13 291.1 89.13 65.67 199.39 94.14 280.24 172.28"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-422.29-449.33c99.18 109.61 121.36 266.78 216.95 378.49 71.5 83.55 211.87 56.62 308.95 69.61 47.32 6.33 99.11 20.06 127.53 61.52 31.34 45.72 43 103.75 53.29 157.25 21.52 111.96 23.59 256.38 130.59 323.3 94.11 58.86 211.02 66.45 295.59 142.72"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-399.73-471.89c101.11 116.63 106.01 287.04 202.67 406.01 35.77 44.01 98.37 47.12 150.92 45.65 61.3-1.71 122.16-12 183.55-11.64 48.08.28 107.43 6.31 134.86 51.27 30.78 50.46 36.42 115.55 41.99 173.05 6.96 71.83 8.71 144.63 22.77 215.59 6.11 30.81 14.7 62.12 31.44 89.01 13.17 21.15 34.08 35.35 55.69 47.08 98.6 53.51 220.77 40.91 308.99 116.88"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-377.17-494.45c104.68 120.09 94 301.57 193.03 424.58 37.24 46.26 102.92 43.59 156.6 37.71 65.57-7.18 129.88-23.8 195.73-28.68 48.91-3.63 111.35-2.4 139.39 44.92 31.41 53.01 32.28 123.73 34.53 183.49 2.9 77.35-.11 155.52 10.79 232.34 4.53 31.97 11.53 65.2 28.5 93.13 12.91 21.26 34.11 35.46 56.07 46.36 101.65 50.47 228.1 20.94 318.25 99.04"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-354.61-517.01c55.97 62.31 77.97 148.19 102.43 226.31 21.5 68.69 41.68 146.03 88.26 202.79C-125.34-40.89-61.48-44.17-6.44-51.5c66.98-8.91 132.39-27.79 199.72-34.27 49.87-4.8 110.81-3.82 140.15 43.86 32.53 52.88 31.65 125.92 32.84 185.85 1.58 79.12-3.16 159.05 6.93 237.75 7.79 60.73 27 111.57 84.39 139.83 102.29 50.36 230.75 13.88 320.68 94.36"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-332.05-539.57c102.69 119.02 99.29 295.41 196.96 417C-98.73-77.31-33.64-77.79 19.33-81.81c63.72-4.83 126.55-18.68 190.47-21.33 48.4-2.01 110.17.98 137.64 47.38 30.89 52.17 33.88 120.33 37.53 179.29 4.65 74.98 3.74 150.83 15.95 225.14 5.18 31.55 12.84 64 29.58 91.62 12.93 21.34 34.04 35.39 55.91 46.61 100.43 51.54 224.89 29.71 314.43 106.41"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-309.49-562.13c100.03 113.52 113.13 277.79 209.16 393.5 35.47 42.74 94.46 49.95 146.76 51.44 58.68 1.67 117.4-4.79 176.06-1.16 47.81 2.95 103.77 12.54 131.56 55.87 30.98 48.3 39.41 110.18 47.11 165.89 9.46 68.42 14.04 137.91 30.18 205.23 13.84 57.72 36.74 103.04 89.18 133.46 96.5 55.98 216.3 52.77 302.88 128.66"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-286.93-584.69C-188.36-479.04-157.47-329-62.13-221.32c72.12 81.46 197.71 68.23 295.05 88.98 46.69 9.96 94.05 27.62 123.42 67.27 31.85 43 46.6 97.4 59.52 148.53 27.93 110.44 39.96 238.93 141.84 307.91 91.69 62.09 205.56 79.8 288.26 156.83"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-264.37-607.25c97.54 97.63 146.39 231.87 241.39 331.39 73.07 76.55 171.75 91.24 265.7 129.87 44.26 18.2 82.99 43.32 114.7 79.46 32.92 37.52 53.91 84.32 72.73 130.05 21.43 52.07 39.72 105.61 65.55 155.75 24.51 47.57 58.19 86.44 100.03 119.68 86.88 69.04 193.38 107.33 272.78 186.66"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-241.81-629.81c96.35 91.44 161.95 209.32 256.47 302.35 36.46 35.89 71.59 67.89 115.42 94.88 40.63 25.02 83.27 46.64 123.45 72.41C333.46-108.91 395.77-36.85 445.1 43.55c27.16 44.26 52.28 89.87 82.32 132.3 29.82 42.11 66.87 78.75 104.99 113.36 83.09 75.43 181 132.11 258.66 213.86"
        ></path>
        <path
          stroke="#5E5E60"
          strokeMiterlimit="10"
          d="M-219.25-652.37c94.39 88.7 174.19 191.32 267.55 281.01C84.69-336.4 118.28-299.75 156.92-267c36.34 30.8 73.96 60.07 110.02 91.2 35.77 30.88 67.01 65.9 101.49 97.93 33.67 31.28 63.92 66 93.33 101.24 31.73 38.02 62.46 76.91 95.56 113.78 34.2 38.09 71.79 73.23 108.19 109.18 80.97 79.97 170.05 151.19 248.12 234.18M-196.69-674.93c90.79 90.65 181.37 181.5 272.14 272.16C362.54-116.03 649.52 170.81 936.2 457.96"
        ></path>
      </g>
    </svg>
  );
};

export default OnboardingBannerPattern;
