import "./styles/index.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import CombineProviders, {
  AlertProvider,
  AuthProvider,
  ProjectsProvider,
  ThemeProvider,
} from "./context";
import { PrivateRoute, PublicRoute, pages } from "./routes";

const queryClient = new QueryClient();

const App = () => {
  return (
    <Router>
      <CombineProviders
        components={[
          ThemeProvider,
          AlertProvider,
          AuthProvider,
          ProjectsProvider,
        ]}
      >
        <QueryClientProvider client={queryClient}>
          <Routes>
            {pages.map((page, index) => (
              <Route
                key={`route-${index}`}
                path={page.path}
                element={page.isPublic ? <PublicRoute /> : <PrivateRoute />}
              >
                <Route {...page} />
              </Route>
            ))}
          </Routes>
        </QueryClientProvider>
      </CombineProviders>
    </Router>
  );
};

export default App;
