import type { ChangeEvent } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { Button, Checkbox, Modal, SelectEvent, TextField } from "nebula-galaxy";

import { deleteProfile } from "services";
import { useAuth } from "context";
import { routes } from "config";

import { getFormValues, isFormValid } from "utils/functions";

import styles from "./profile.module.scss";

const initialFormData = {
  email: {
    value: "",
    valid: false,
  },
};

interface CloseAccountProps {
  active: boolean;
  close: () => void;
  onError: (message: string) => void;
}

const CloseAccount = ({ active, close, onError }: CloseAccountProps) => {
  const navigate = useNavigate();
  const { userProfile, signOut } = useAuth();

  const { email: ownerEmail, org } = userProfile || {};
  const { orgId } = org || {};

  const [formData, setFormData] = useState({ ...initialFormData });
  const [isAgreementActive, setIsAgreementActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { email } = getFormValues(formData);

  const handleOnChange = (
    {
      target,
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectEvent,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const submit = () => {
    setIsLoading(true);
    deleteProfile(
      { email },
      () => {
        signOut();
        navigate(routes.auth.signUp);
      },
      (message) => {
        onError(message);
        setIsLoading(false);
      }
    );
  };

  return (
    <Modal
      className={styles.closeAccountModal}
      active={active}
      onClose={close}
      variant="Standard"
      title="Close your Nebula account"
      actions={
        <>
          <Button variant="Tertiary" size="SM" onClick={close}>
            Cancel
          </Button>

          <Button
            variant="Destructive"
            size="SM"
            onClick={submit}
            loading={isLoading}
            disabled={
              !isFormValid(formData) ||
              email !== ownerEmail ||
              !isAgreementActive
            }
          >
            Close account
          </Button>
        </>
      }
      hideHeader
    >
      <div className={styles.modalHeader}>
        <span className={cn("p3 medium", styles.title)}>
          Close your Nebula account
        </span>
        <span className={cn("p5", styles.description)}>
          Closing your account will permanently delete your entire Nebula
          account. You will be removed from all shared projects and teams. This
          action is irreversible. We recommend migrating all resources and data
          before proceeding. Please enter your email to confirm.
        </span>
      </div>

      <div className={styles.inputs}>
        <TextField
          variant="Filled"
          label="Confirm with email"
          name="email"
          type="email"
          value={email}
          onChange={handleOnChange}
          required
        />

        <Checkbox
          className={styles.agreement}
          onChange={({ target }) => setIsAgreementActive(target.checked)}
          label="I acknowledge that all resources, data, projects, and teams will be permanently removed and I want to proceed."
        />
      </div>
    </Modal>
  );
};

export default CloseAccount;
