import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAlert } from "context";
import { routes } from "config";

import cn from "classnames";
import styles from "./subnet.module.scss";

import { Button, Callout, Modal, TextField, Divider } from "nebula-galaxy";
import DeleteInstance from "../../instances/delete-instance";

import { deleteServer, deleteSubnet } from "services";

interface DeleteVpcProps {
  active: boolean;
  close: () => void;
  subnetDetails: any;
  servers: any
}

const DeleteSubnet = ({ active, close, subnetDetails, servers }: DeleteVpcProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [instanceAction, setInstanceAction] = useState("");
  const [isDeletingServer, setIsDeletingServer] = useState(false);
  const [isDeletingSubnet, setIsDeletingSubnet] = useState(false);
  const [subnetName, setSubnetName] = useState("");
  const { showAlert } = useAlert();

  const { vpc, ...subnet } = subnetDetails || {};

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsDeletingServer(false);
    setInstanceAction("");
    setIsDeletingSubnet(false);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setInstanceAction("");
    setIsDeletingServer(false);
    setIsDeletingSubnet(false);
  };

  const id = servers?.[0]?.serverId;

  const handleDeleteServer = () => {
    setIsDeletingServer(true);
    deleteServer(
      id as string,
      (message) => {
        onSuccess(message);
        queryClient.invalidateQueries("vpc-details");
      },
      onError
    );
  };

  const handleDeleteSubnet = async () => {
    setIsDeletingSubnet(true);
    deleteSubnet(
      subnet.subnetId,
      (message) => {
        onSuccess(message);
        close();
        navigate(-1);
      },
      onError
    );
  };

  return (
    <>
      <Modal
        className={styles.terminateModal}
        active={active && !!servers?.length && !instanceAction}
        onClose={close}
        variant="Warning"
        title="Terminate server"
        actions={
          <>
            <Button variant="Tertiary" size="SM" onClick={close}>
              Cancel
            </Button>

            <Button
              variant="Primary"
              size="SM"
              onClick={() => setInstanceAction("terminate")}
            >
              Continue
            </Button>
          </>
        }
      >
        <span className="p5">
          The following instances are currently associated with this subnet:
          {!!servers?.length && (
            <ul className={styles.serversList}>
              {servers?.map((server: any) => (
                <li key={server._id}>{server.serverName}</li>
              ))}
            </ul>
          )}
          Before you can delete this subnet, please proceed to terminate the
          instances associated with it.
        </span>
      </Modal>
      <Modal
        className={styles.terminateModal}
        active={active && !servers?.length}
        onClose={close}
        variant="Standard"
        title=""
        actions={
          <>
            <Button variant="Tertiary" size="SM" onClick={close}>
              Cancel
            </Button>

            <Button
              variant="Primary"
              size="SM"
              onClick={handleDeleteSubnet}
              disabled={subnet?.name !== subnetName}
              loading={isDeletingSubnet}
            >
              Delete subnet
            </Button>
          </>
        }
        hideHeader
      >
        <div className={styles.section}>
          <p className={cn("p4, medium", styles.title)}>Delete subnet</p>
          <p className={cn("p5")}>
            The {subnetDetails.name} subnet with the details below with be
            deleted
          </p>
          <div className={styles.subnetSection}>
            <div className={cn("p4 medium")}>Subnet details</div>
            <Divider className={styles.divider} />
            <div className={styles.details}>
              <span className={styles.label}>Name</span>
              <span>{subnetDetails?.name}</span>
            </div>
            <div className={styles.details}>
              <span className={styles.label}>ID</span>
              <span>{subnetDetails?.subnetId}</span>
            </div>
            <div className={styles.details}>
              <span className={styles.label}>CIDR block</span>
              <span>{subnetDetails?.cidr}</span>
            </div>
          </div>
        </div>
        <Callout
          variant="Warning"
          title=""
          message="Deleting this subnet will permanently remove it and all associated resources. Ensure you have backed up any important data before proceeding."
          isCloseVisible={false}
        />

        <TextField
          className={styles.textfield}
          variant="Filled"
          label="Confirm with subnet name"
          name="vpcName"
          onChange={({ target }) => setSubnetName(target.value)}
          required
        />
      </Modal>
      <DeleteInstance
        action={instanceAction}
        close={() => setInstanceAction("")}
        submit={handleDeleteServer}
        isLoading={isDeletingServer}
        serverName={servers?.[0]?.serverName}
      />
    </>
  );
};

export default DeleteSubnet;
