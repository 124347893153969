import type { IObject } from "nebula-galaxy";

export const setLocalStorage = (name: string, value: any) => {
  removeLocalStorage(name);
  window.localStorage.setItem(name, JSON.stringify(value));
};

export const getLocalStorage = (name: string) => {
  const item = window.localStorage.getItem(name);
  return item ? JSON.parse(item) : null;
};

export const removeLocalStorage = (name: string) => {
  window.localStorage.removeItem(name);
};

export const setLocalUser = (data: IObject) => {
  setLocalStorage("nebula-user", data);
};

export const getLocalUser = () => {
  return getLocalStorage("nebula-user");
};

export const removeLocalUser = () => {
  return removeLocalStorage("nebula-user");
};
