export const DEFAULT_ERROR_MESSAGE = "An error occured. Please try again.";
export const ACCESS_TOKEN_COOKIE_KEY = `Nebula-Auth-Token-${process.env.NODE_ENV}`;
export const INSTANCES_GUIDE_LINK = "https://docs.usenebula.io/nebcompute";
export const BUCKETS_GUIDE_LINK = "https://docs.usenebula.io/nebstore";

export const defaultQueryParams = {
  page: 1,
  limit: 50,
};

export const dateFilters = [
    {
      label: "7 days",
      value: 7,
    },
    {
      label: "14 days",
      value: 14,
    },
    {
      label: "30 days",
      value: 30,
    },
  ];