import type { ReactNode } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { useAuth } from "context";
import { Navigation } from "components";
import { routes } from "config";
import AddProject from "pages/settings/projects/add-project";
import CreateInstance from "pages/console/neb-compute/instances/create-instance";
import CreateBucket from "pages/console/neb-store/buckets/create-bucket";

import styles from "./main-layout.module.scss";

interface MainLayoutProps {
  className?: string;
  children: ReactNode;
}

const MainLayout = ({ className, children }: MainLayoutProps) => {
  const navigate = useNavigate();
  const [isProjectFormActive, setIsProjectFormActive] = useState(false);

  const [isInstanceFormActive, setIsInstanceFormActive] = useState(false);
  const [isBucketFormActive, setIsBucketFormActive] = useState(false);

  const { regionProviders } = useAuth();

  return (
    <div className={cn(className, styles.container)}>
      <Navigation
        openProjectForm={() => setIsProjectFormActive(true)}
        setIsInstanceFormActive={setIsInstanceFormActive}
        setIsBucketFormActive={setIsBucketFormActive}
      />
      <div className={styles.content}>{children}</div>

      <AddProject
        queryKey={"projects"}
        active={isProjectFormActive}
        close={() => setIsProjectFormActive(false)}
      />

      {isInstanceFormActive && (
        <CreateInstance
          queryKey={"instances"}
          providerId={regionProviders?.[0]?.providerId}
          close={() => setIsInstanceFormActive(false)}
          handleOnSuccess={() => navigate(routes.console.nebCompute.instances)}
        />
      )}

      {isBucketFormActive && (
        <CreateBucket
          queryKey={"buckets"}
          providerId={regionProviders?.[0]?.providerId}
          close={() => setIsBucketFormActive(false)}
          handleOnSuccess={() => navigate(routes.console.nebStore)}
        />
      )}
    </div>
  );
};

export default MainLayout;
