import { useState } from "react";
import { useQueryClient } from "react-query";

import type { IObject } from "nebula-galaxy";
import { Button, Table, Modal } from "nebula-galaxy";

import { fetchInvitedUsers, removeInvitedUser } from "services";
import { useAlert } from "context";
import { useReactQuery } from "hooks";

import { EmptyState } from "components";
import { invitedUsersColumns } from "./columns";

import { UserIcon } from "assets";
import type { QueryParams } from "utils/types";
import { pluralize } from "utils/functions";
import { defaultQueryParams } from "utils/consts";

import styles from "./access-manager.module.scss";

interface InvitedUsersProps {
  queryKey: string;
  setIsFormActive: (e: boolean) => void;
}

const InvitedUsers = ({ queryKey, setIsFormActive }: InvitedUsersProps) => {
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const [isRemoveMemberActive, setIsRemoveMemberActive] = useState(false);
  const [selectedMember, setSelectedMember] = useState<IObject | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [queryParams, setQueryParams] =
    useState<QueryParams>(defaultQueryParams);
  const { data, isLoading } = useReactQuery({
    queryKey,
    queryParams,
    queryFunction: () => fetchInvitedUsers(queryParams),
  });

  const onSuccess = (message: string) => {
    queryClient.invalidateQueries(queryKey);
    setIsRemoveMemberActive(false);
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsUpdating(false);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsUpdating(false);
  };

  const handleRemoveMember = async (inviteId: string) => {
    setIsUpdating(true);
    removeInvitedUser({ inviteId }, onSuccess, onError);
  };

  return (
    <>
      {queryParams?.term || isLoading || data?.docs?.length ? (
        <Table
          className={styles.membersTable}
          columns={invitedUsersColumns}
          data={data?.docs}
          loading={isLoading}
          pagination={data?.pagination}
          loadData={setQueryParams}
          rowActions={(member) => (
            <Button
              variant="Secondary"
              size="SM"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedMember(member);
                setIsRemoveMemberActive(true);
              }}
            >
              Remove member
            </Button>
          )}
          toolbarActions={
            <Button
              leftIcon="Plus"
              size="SM"
              onClick={() => setIsFormActive(true)}
            >
              Invite member
            </Button>
          }
          searchPlaceholder="Search invited user name or email"
          dataText={pluralize("invited user", data?.docs?.length)}
        />
      ) : (
        <EmptyState
          icon={<UserIcon />}
          title="You haven't invited any team members yet."
          description="Your invited team members will appear here once you send at least one invite."
          action={
            <Button size="MD" onClick={() => setIsFormActive(true)}>
              Invite member
            </Button>
          }
        />
      )}

      <Modal
        active={isRemoveMemberActive}
        onClose={() => setIsRemoveMemberActive(false)}
        variant="Warning"
        title={`Remove ${selectedMember?.email}`}
        actions={
          <>
            <Button
              variant="Tertiary"
              size="SM"
              onClick={() => setIsRemoveMemberActive(false)}
            >
              Cancel
            </Button>

            <Button
              variant="Destructive"
              size="SM"
              onClick={() => handleRemoveMember(selectedMember?.inviteId)}
              loading={isUpdating}
            >
              Remove member
            </Button>
          </>
        }
      >
        <span className="p5">
          The invitation link will expire, and the user will not be able to
          create an account to join your team. Are you sure you want to proceed?
        </span>
      </Modal>
    </>
  );
};

export default InvitedUsers;
