import type { ReactNode } from "react";
import cn from "classnames";
import { Button, Icon } from "nebula-galaxy";

import styles from "./full-screen-overlay.module.scss";

interface FullScreenOverlayProps {
  className?: string;
  title: ReactNode;
  headerMeta?: ReactNode;
  children: ReactNode;
  handleClose: () => void;
}

const FullScreenOverlay = ({
  className,
  title,
  headerMeta,
  children,
  handleClose,
}: FullScreenOverlayProps) => {
  return (
    <section className={cn(className, styles.container)}>
      <div className={styles.header}>
        <div>
          <Button variant="TertiaryIcon" size="SM" onClick={handleClose}>
            <Icon name="X" />
          </Button>
          <h6 className={cn("semibold", styles.headerTitle)}>{title}</h6>
        </div>

        <div className={styles.headerMeta}>
          {!!headerMeta && headerMeta}
          {/* <Button variant="Tertiary" leftIcon="CircleHelp" size="SM">
            Help
          </Button> */}
        </div>
      </div>

      <div className={styles.content}>{children}</div>
    </section>
  );
};

export default FullScreenOverlay;
