import type { ChangeEvent, FormEvent } from "react";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import cn from "classnames";

import { Button, Divider, TextField, PasswordTextField, NebulaWordMark } from "nebula-galaxy";

import { routes } from "config";
import { OnboardingLayout } from "layouts";
import { GithubButton, GoogleButton } from "components";
import { useAuth } from "context/auth";
import { getFormValues, isFormValid } from "utils/functions";

import styles from "../auth.module.scss";
import layoutStyles from "../../../layouts/onboarding-layout/onboarding-layout.module.scss";

const initialFormData = {
  email: {
    value: "",
    valid: false,
  },
  password: {
    value: "",
    valid: false,
  },
};

const SignUp = () => {
  const [searchParams] = useSearchParams();
  const { isLoading, isLoadingSocial, authenticate, error } = useAuth();
  const [formData, setFormData] = useState({ ...initialFormData });

  const socialId = searchParams.get("social");

  const { email, password } = getFormValues(formData);

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement>,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    authenticate("auth/session/new", { email, password });
  };

  useEffect(() => {
    socialId && authenticate("auth/session/social/user", { socialId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialId]);

  return (
    <OnboardingLayout>
      <figure className={layoutStyles.contentLogoHidden}>
          <NebulaWordMark />
      </figure>
      <div className={styles.header}>
        <h5 className={cn("medium", styles.title)}>Create your account</h5>
      </div>
      <form className={styles.form} onSubmit={submit}>
        <GoogleButton />
        <GithubButton />

        <Divider text="or" />

        <TextField
          label="Email"
          placeholder="email@example.com"
          name="email"
          type="email"
          value={email}
          onChange={handleOnChange}
          required
          hasError={!!error}
          footnote={error}
        />
        <PasswordTextField
          label="Password"
          name="password"
          type="password"
          value={password}
          onChange={handleOnChange}
          required
        />

        <Button
          size="LG"
          type="submit"
          loading={isLoading && !isLoadingSocial}
          disabled={!isFormValid(formData)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Create account
        </Button>

        <div className={styles.alternative}>
          <span className="p5">Already have an account?</span>

          <Link to={routes.auth.signIn}>
            <Button variant="PrimaryLinkButton" size="MD">
              Sign in
            </Button>
          </Link>
        </div>
      </form>
    </OnboardingLayout>
  );
};

export default SignUp;
