import type { ChangeEvent, FormEvent } from "react";
import { useState, useEffect } from "react";
import { useQueryClient } from "react-query";

import { SelectEvent, Stepper } from "nebula-galaxy";
import { FullScreenOverlay, MiniPageLoader } from "components";

import Configuration from "./configuration";
import Summary from "./summary";
import NetworkSecurity from "./network-security";

import { useAlert } from "context";
import { useReactQuery } from "hooks";
import { createServer, fetchComputeDetails } from "services";
import { defaultQueryParams } from "utils/consts";
import { getFormValues } from "utils/functions";

import styles from "./create-instance.module.scss";

const initialFormData = {
  serverName: {
    value: "",
    valid: false,
  },
  operatingSystem: {
    value: "",
    valid: false,
  },
  osId: {
    value: "",
    valid: false,
  },
  noOfInstance: {
    value: "1",
    valid: true,
  },
  instanceId: {
    value: "",
    valid: false,
  },
  vpcId: {
    value: "",
    valid: false,
  },
  subnetId: {
    value: "",
    valid: false,
  },
};

interface CreateInstanceProps {
  providerId: string;
  queryKey: string;
  close: () => void;
  handleOnSuccess?: () => void;
}

const computeDetailsKey = "create-instance";

const CreateInstance = ({
  providerId,
  queryKey,
  close,
  handleOnSuccess,
}: CreateInstanceProps) => {
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();

  const [formData, setFormData] = useState({ ...initialFormData });
  const [isLoading, setIsLoading] = useState(false);
  const [activeForm, setActiveForm] = useState(0);
  const { data: computeDetails, isLoading: isLoadingComputeDetails } =
    useReactQuery({
      queryKey: computeDetailsKey,
      queryParams: defaultQueryParams,
      queryFunction: () => fetchComputeDetails(providerId),
    });

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid?: boolean
  ) => {
    const { name, value, checked } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid: valid || !!checked,
      },
    });
  };

  // const handleVolumes = (action: "add" | "remove", index?: number) => {
  //   const copyVolumes = JSON.parse(JSON.stringify(formData.volumes.value));

  //   action === "add" ? copyVolumes.push("") : copyVolumes.splice(index, 1);

  //   setFormData({
  //     ...formData,
  //     volumes: {
  //       value: copyVolumes,
  //       valid: true,
  //     },
  //   });
  // };

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    queryClient.invalidateQueries(queryKey);
    setFormData({ ...initialFormData });
    setIsLoading(false);
    close();
    handleOnSuccess?.();
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsLoading(false);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (activeForm) {
      const { operatingSystem, noOfInstance, ...formDataValues } =
        getFormValues(formData);

      setIsLoading(true);

      createServer({ ...formDataValues, providerId }, onSuccess, onError);
    } else {
      scrollToTop();
      setActiveForm(1);
    }
  };

  const scrollToTop = () => {
    const container = document.getElementsByClassName(
      styles.createInstance
    )?.[0];
    container?.scrollTo(0, 0);
  };

  useEffect(() => {
    if (computeDetails) {
      const { vpc, subnet } = computeDetails;

      const vpcId = vpc?.[0]?.vpcId || "";
      const subnetId = subnet?.[0]?.subnetId || "";

      setFormData({
        ...formData,
        vpcId: { value: vpcId, valid: !!vpcId },
        subnetId: { value: subnetId, valid: !!subnetId },
      });
    }
  }, [computeDetails]);

  const forms = [
    <Configuration
      computeDetails={computeDetails}
      formData={formData}
      submit={submit}
      handleOnChange={handleOnChange}
    />,
    <NetworkSecurity
      computeDetails={computeDetails}
      formData={formData}
      goBack={() => {
        scrollToTop();
        setActiveForm(0);
      }}
      submit={submit}
      isLoading={isLoading}
      handleOnChange={handleOnChange}
    />,
  ];

  return (
    <FullScreenOverlay
      className={styles.createInstance}
      title="Create instance"
      headerMeta={
        <Stepper
          className={styles.stepper}
          steps={["Configuration", "Network & Security"]}
          currentStep={activeForm}
          isLabelHidden
        />
      }
      handleClose={close}
    >
      {isLoadingComputeDetails ? (
        <MiniPageLoader />
      ) : (
        <>
          {forms[activeForm]}
          <Summary computeDetails={computeDetails} formData={formData} />
        </>
      )}
    </FullScreenOverlay>
  );
};

export default CreateInstance;
