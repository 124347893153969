import type { RouteProps } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";

import {
  AcceptInvite,
  AccessManager,
  AccessManagerMemberProfile,
  BucketDetails,
  Buckets,
  Company,
  ForgotPassword,
  InstanceDetails,
  Instances,
  VPCs,
  VpcDetails,
  SubnetDetails,
  KeyPairs,
  Logs,
  ObjectDetails,
  Onboarding,
  Overview,
  Page404,
  Profile,
  ProjectDetails,
  Projects,
  ResetPassword,
  SignIn,
  SignUp,
  VerifyEmail,
  VerifyIdentity,
} from "./pages";
import { useAuth } from "./context";
import { routes } from "./config";
import { PageLoader } from "./components";

export type PageProps = {
  title: string;
  description: string;
  isPublic?: boolean;
};

export const pages: (RouteProps & PageProps)[] = [
  //Auth
  {
    path: routes.auth.signIn,
    element: <SignIn />,
    title: "Sign in",
    description: "Sign in",
    isPublic: true,
  },
  {
    path: routes.auth.signUp,
    element: <SignUp />,
    title: "Sign up",
    description: "Sign up",
    isPublic: true,
  },
  {
    path: routes.auth.verifyEmail,
    element: <VerifyEmail />,
    title: "Verify email",
    description: "Verify email",
    isPublic: true,
  },
  {
    path: routes.auth.verifyIdentity,
    element: <VerifyIdentity />,
    title: "Verify identity",
    description: "Verify identity",
    isPublic: true,
  },
  {
    path: routes.auth.forgotPassword,
    element: <ForgotPassword />,
    title: "Forgot password",
    description: "Forgot password",
    isPublic: true,
  },
  // routes.auth.reset should be removed after the BE changes
  // the reset password link to /reset-password/:code
  {
    path: routes.auth.reset,
    element: <ResetPassword />,
    title: "Reset password",
    description: "Reset password",
    isPublic: true,
  },
  {
    path: routes.auth.resetPassword,
    element: <ResetPassword />,
    title: "Reset password",
    description: "Reset password",
    isPublic: true,
  },
  {
    path: routes.auth.acceptInvite,
    element: <AcceptInvite />,
    title: "Accept invite",
    description: "Accept invite",
    isPublic: true,
  },
  // Onboarding
  {
    path: routes.onboarding.home,
    element: <Onboarding />,
    title: "Onboarding",
    description: "Onboarding",
    isPublic: true,
  },
  // Console
  {
    path: routes.console.home,
    element: <Overview />,
    title: "Nebula",
    description: "Welcome to Nebula",
  },
  {
    path: routes.console.nebCompute.instances,
    element: <Instances />,
    title: "NebCompute",
    description: "NebCompute",
  },
  {
    path: routes.console.nebCompute.instanceDetails,
    element: <InstanceDetails />,
    title: "NebCompute - Instance Details",
    description: "NebCompute - Instance Details",
  },
  {
    path: routes.console.nebStore,
    element: <Buckets />,
    title: "NebStore",
    description: "NebStore",
  },
  {
    path: routes.console.nebStoreDetails,
    element: <BucketDetails />,
    title: "NebStore - Bucket Details",
    description: "NebStore - Bucket Details",
  },
  {
    path: routes.console.nebStoreObjectDetails,
    element: <ObjectDetails />,
    title: "NebStore - Object Details",
    description: "NebStore - Object Details",
  },
  {
    path: routes.console.nebCompute.vpcs,
    element: <VPCs />,
    title: "NebCompute - VPCs",
    description: "NebCompute - VPCs",
  },
  {
    path: routes.console.nebCompute.vpcDetails,
    element: <VpcDetails />,
    title: "NebCompute - VPC Details",
    description: "NebCompute - VPC Details",
  },
  {
    path: routes.console.nebCompute.subnetDetails,
    element: <SubnetDetails />,
    title: "NebCompute - Subnet Details",
    description: "NebCompute - Subnet Details",
  },
  {
    path: routes.console.nebCompute.keyPairs,
    element: <KeyPairs />,
    title: "NebCompute - Key Pairs",
    description: "NebCompute - Key Pairs",
  },
  // {
  //   path: routes.console.nebCompute.blueprints,
  //   element: <Overview />,
  //   title: "NebCompute - Blueprints",
  //   description: "NebCompute - Blueprints",
  // },
  // {
  //   path: routes.console.nebCompute.securityGroups,
  //   element: <Overview />,
  //   title: "NebCompute - Security Groups",
  //   description: "NebCompute - Security Groups",
  // },
  // {
  //   path: routes.console.database,
  //   element: <Overview />,
  //   title: "NebCompute - Database",
  //   description: "NebCompute - Database",
  // },
  {
    path: routes.settings.company,
    element: <Company />,
    title: "Settings - Company",
    description: "Settings - Company",
  },
  {
    path: routes.settings.projects,
    element: <Projects />,
    title: "Settings - Projects",
    description: "Settings - Projects",
  },
  {
    path: routes.settings.projectDetails,
    element: <ProjectDetails />,
    title: "Settings - Projects",
    description: "Settings - Projects",
  },
  {
    path: routes.settings.billing,
    element: <Overview />,
    title: "Settings - Billing",
    description: "Settings - Billing",
  },
  {
    path: routes.settings.accessManager,
    element: <AccessManager />,
    title: "Settings - Access Manager",
    description: "Settings - Access Manager",
  },
  {
    path: routes.settings.accessManagerMember,
    element: <AccessManagerMemberProfile />,
    title: "Settings - Access Manager",
    description: "Settings - Access Manager",
  },
  {
    path: routes.settings.profile,
    element: <Profile />,
    title: "Settings - Profile",
    description: "Settings - Profile",
  },
  {
    path: routes.settings.logs,
    element: <Logs />,
    title: "Settings - Logs",
    description: "Settings - Logs",
  },
  {
    path: "*",
    element: <Page404 />,
    title: "404 - Page Not Found",
    description: "404 - Page Not Found",
  },
];

export const PrivateRoute = () => {
  const { isLoadingApp, isAuthenticated } = useAuth();

  if (isLoadingApp) {
    return <PageLoader />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={routes.auth.signIn} />;
};

export const PublicRoute = () => {
  const { isLoadingApp, isAuthenticated } = useAuth();

  if (isLoadingApp) {
    return <PageLoader />;
  }

  return !isAuthenticated ? <Outlet /> : <Navigate to={routes.console.home} />;
};
