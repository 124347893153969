import { FormEvent, useRef } from "react";
import { useState } from "react";
import cn from "classnames";

import { Button, CopyElement, Divider, Icon, TextField } from "nebula-galaxy";
import { MainLayout } from "layouts";
import { useAlert, useAuth } from "context";
import { updateCompanyName } from "services";

import { MiniPageLoader, PageHeader } from "components";
import CloseAccount from "./close-account";

import styles from "./company.module.scss";

const Company = () => {
  const { showAlert } = useAlert();
  const { userProfile, isLoading } = useAuth();
  const { org } = userProfile || {};
  const { name, orgId } = org || {};

  const [companyName, setCompanyName] = useState(name || "");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCloseAccountActive, setIsCloseAccountActive] = useState(false);

  const textfieldRef = useRef<HTMLInputElement>(null);

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsUpdating(false);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
  };

  const handleUpdateCompanyName = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsUpdating(true);

    updateCompanyName(orgId, { name: companyName }, onSuccess, (message) => {
      onError(message);
      setIsUpdating(false);
    });
  };

  return (
    <MainLayout>
      {isLoading ? (
        <MiniPageLoader />
      ) : (
        <section className={cn("page-content", styles.container)}>
          <PageHeader
            className={styles.header}
            title="Company"
            description="Manage company preferences."
          />

          <Divider className={styles.divider} />

          <form className={styles.form} onSubmit={handleUpdateCompanyName}>
            <TextField
              ref={textfieldRef}
              variant="Filled"
              label="Company name"
              name="companyName"
              value={companyName}
              rightAddon={
                <Icon
                  className={styles.textfieldIcon}
                  onClick={() => textfieldRef.current?.focus()}
                  size="SM"
                  name="PenLine"
                />
              }
              onChange={({ target }) => setCompanyName(target.value)}
              required
            />
            <Button
              size="SM"
              type="submit"
              loading={isUpdating}
              disabled={!companyName || companyName === name}
            >
              Update
            </Button>
          </form>

          <div className={styles.companyId}>
            <span className={cn("p5", styles.companyIdTitle)}>Company ID</span>
            <CopyElement
              element={
                <span className={cn("p5 medium", styles.companyIdValue)}>
                  {orgId}
                </span>
              }
              value={orgId}
              showIcon
            />
          </div>

          <div className={styles.closeAccount}>
            <span className={cn("p3 medium", styles.closeAccountTitle)}>
              Close account
            </span>
            <span className={cn("p5", styles.closeAccountDescription)}>
              Permanently delete all resources, data, projects, and teams
              associated with your Nebula account. This action is irreversible.
              We recommend migrating all resources and data before proceeding.{" "}
            </span>
            <Button
              variant="Destructive"
              size="SM"
              onClick={() => setIsCloseAccountActive(true)}
            >
              Close account
            </Button>
          </div>
        </section>
      )}

      <CloseAccount
        active={isCloseAccountActive}
        close={() => setIsCloseAccountActive(false)}
        onError={onError}
      />
    </MainLayout>
  );
};

export default Company;
