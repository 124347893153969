import type { FormEvent } from "react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";

import { Button, TextField, NebulaWordMark } from "nebula-galaxy";

import { OnboardingLayout } from "layouts";
import { useAlert, useAuth } from "context";
import { api } from "config";

import styles from "../auth.module.scss";
import layoutStyles from "../../../layouts/onboarding-layout/onboarding-layout.module.scss";

const VerifyIdentity = () => {
  const [searchParams] = useSearchParams();
  const { isLoading, authenticate, error, userProfile } = useAuth();
  const { showAlert, clearAlert } = useAlert();

  const [code, setCode] = useState(searchParams.get("code") || "");
  const [isResendOTPActive, setIsResendOTPActive] = useState(false);
  const [otpTimer, setOTPTimer] = useState(60);

  const { email, userId } = userProfile || {};

  const resendOTP = async () => {
    clearAlert();

    const { success, message } = await api({
      url: "otp/verification/resend",
      body: {
        userId,
      },
    });

    if (success) {
      showAlert({
        active: true,
        variant: "Success",
        title: "OTP resent",
        message: "You will receive an email with a new OTP",
        isMultiLine: true,
      });

      setCode("");
    } else {
      console.error(message);
      showAlert({
        active: true,
        variant: "Critical",
        title: "Onboarding error",
        message,
      });
    }
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearAlert();

    authenticate("auth/verification/mfa", { code, userId });
  };

  useEffect(() => {
    if (otpTimer) {
      const otpTimerId = setTimeout(() => setOTPTimer(otpTimer - 1), 1000);
      return () => clearTimeout(otpTimerId);
    } else {
      setIsResendOTPActive(true);
    }
  }, [otpTimer]);

  return (
    <OnboardingLayout>
      <>
        <figure className={layoutStyles.contentLogoHidden}>
            <NebulaWordMark />
        </figure>
        <div className={styles.header}>
          <h5 className={cn("medium", styles.title)}>Verify your identity</h5>
          <span className={cn("p5", styles.description)}>
            Please confirm your identity by entering the 4-digit code sent to{" "}
            {email}.
          </span>
        </div>

        <form className={styles.form} onSubmit={submit}>
          <TextField
            label="Code"
            placeholder="Enter code"
            name="code"
            type="number"
            value={code}
            onChange={({ target }) => setCode(target.value)}
            hasError={!!error}
            footnote={error}
            required
          />

          <Button
            size="LG"
            type="submit"
            loading={isLoading}
            disabled={!code || !email}
          >
            Verify identity
          </Button>

          <div className={styles.alternative}>
            <span className="p5">Didn't receive the code?</span>

            {isResendOTPActive ? (
              <Button
                variant="PrimaryLinkButton"
                size="MD"
                type="button"
                onClick={resendOTP}
              >
                Request again
              </Button>
            ) : (
              <span className="p5">
                Try again in 00:{otpTimer < 10 ? `0${otpTimer}` : otpTimer}
              </span>
            )}
          </div>
        </form>
      </>
    </OnboardingLayout>
  );
};

export default VerifyIdentity;
