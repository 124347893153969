import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Icon, Tabs } from "nebula-galaxy";

import { MainLayout } from "layouts";
import { PageHeader } from "components";

import Members from "./members";
import InvitedUsers from "./invited-users";
import InviteMember from "./invite-member";

const membersKey = "members";
const invitedUsersKey = "invited-users";

const AccessManager = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const [activeTab, setActiveTab] = useState(0);
  const [isFormActive, setIsFormActive] = useState(false);

  const tabs = [
    {
      header: (
        <>
          <Icon name="User" />
          <span className="p5 medium">Members</span>
        </>
      ),
      content: (
        <Members queryKey={membersKey} setIsFormActive={setIsFormActive} />
      ),
    },
    {
      header: (
        <>
          <Icon name="Mail" />
          <span className="p5 medium">Invited</span>
        </>
      ),
      content: (
        <InvitedUsers
          queryKey={invitedUsersKey}
          setIsFormActive={setIsFormActive}
        />
      ),
    },
  ];

  useEffect(() => {
    setActiveTab(tab === "invited-users" ? 1 : 0);
  }, [tab]);

  return (
    <MainLayout>
      <section className="page-content">
        <PageHeader
          title="Access manager"
          description="Invite and manage team members' roles."
        />

        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs.map(({ header }) => header)}
        />

        {tabs[activeTab].content}

        {isFormActive && (
          <InviteMember
            queryKey={membersKey}
            close={() => setIsFormActive(false)}
          />
        )}
      </section>
    </MainLayout>
  );
};

export default AccessManager;
