import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";

import {
  Button,
  TextField,
  PasswordTextField,
  NebulaWordMark
} from "nebula-galaxy";

import { OnboardingLayout } from "layouts";
import { useAuth } from "context/auth";
import { getFormValues, isFormValid } from "utils/functions";

import styles from "../auth.module.scss";
import layoutStyles from "../../../layouts/onboarding-layout/onboarding-layout.module.scss";

const initialFormData = {
  fullName: {
    value: "",
    valid: false,
  },
  password: {
    value: "",
    valid: false,
  },
};

const AcceptInvite = () => {
  const [searchParams] = useSearchParams();
  const { isLoading, authenticate } = useAuth();
  const [formData, setFormData] = useState({ ...initialFormData });

  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const { fullName, phoneNumber, password } = getFormValues(formData);

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement>,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    authenticate("users/invitation/acceptance", {
      email,
      fullName,
      phoneNumber,
      password,
      token,
    });
  };

  return (
    <OnboardingLayout>
      <figure className={layoutStyles.contentLogoHidden}>
          <NebulaWordMark />
      </figure>
      <div className={styles.header}>
        <h5 className={cn("medium", styles.title)}>Create your account</h5>
      </div>
      <form className={styles.form} onSubmit={submit}>
        <TextField
          label="Email"
          placeholder="email@example.com"
          name="email"
          type="email"
          value={email as string}
          onChange={handleOnChange}
          disabled
        />
        <TextField
          label="Full name"
          name="fullName"
          value={fullName}
          onChange={handleOnChange}
          required
        />
        <PasswordTextField
          label="Password"
          name="password"
          type="password"
          value={password}
          onChange={handleOnChange}
          required
        />

        <Button
          size="LG"
          type="submit"
          loading={isLoading}
          disabled={!isFormValid(formData)}
        >
          Create account
        </Button>
      </form>
    </OnboardingLayout>
  );
};

export default AcceptInvite;
