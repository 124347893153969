interface CombineProvidersProps {
  components: any[];
  children: any;
}

const CombineProviders = ({
  components = [],
  children,
  ...rest
}: CombineProvidersProps) => {
  return (
    <>
      {components.reduceRight((acc, Provider) => {
        return <Provider {...rest}>{acc}</Provider>;
      }, children)}
    </>
  );
};

export default CombineProviders;
