import { useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import moment from "moment";

import {
  Button,
  DropdownMenu,
  Icon,
  Popper,
  StatusChip,
  Table,
} from "nebula-galaxy";

import { Accordion } from "components";
import { useReactQuery } from "hooks";
import { fetchProjectBilling } from "services";
import { months } from "utils/data";

import { serverBillingColumns, storageBillingColumns } from "../columns";

import styles from "../project-details.module.scss";

const queryKey = "project-billing";

const CostUsage = () => {
  const { id } = useParams();
  const currentYear = new Date().getFullYear();

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filterValue, setFilterValue] = useState({
    month: moment().format("M"),
    year: currentYear,
  });

  const { data, isLoading } = useReactQuery({
    queryKey,
    queryParams: filterValue,
    queryFunction: () => fetchProjectBilling(id as string, {
      month: Number(filterValue.month),
      year: filterValue.year
    }),
  });

  const getMonthDisplay = (month: string) => {
    const selectedMonth = months.find(({ value }) => value === month);
    return selectedMonth?.label;
  };

  const { total, server, storage } = data || {};

  return (
    <div className={styles.costUsage}>
      {isLoading ? (
        <div className={cn(styles.costUsageLoading, "p5 medium")}>
          Loading...
        </div>
      ) : (
        <>
          <div className={styles.costUsageHeader}>
            <div className={styles.headerItemWrapper}>
              <div className={styles.headerItem}>
                <span className={cn("cap1", styles.headerLabel)}>Net cost</span>
                <span className={cn("p2 medium", styles.headerValue)}>
                  ₦{total?.toLocaleString()}
                </span>
              </div>
              {/* <div className={styles.headerItem}>
            <span className={cn("cap1", styles.headerLabel)}>
              Billing status
            </span>
            <StatusChip
              className={styles.currentProjectChip}
              variant="Warning"
              size="SM"
              text="Pending"
            />
          </div> */}
            </div>

            <div className={styles.headerItem}>
              <Popper
                anchorElement={
                  <Button
                    variant="Secondary"
                    size="SM"
                    rightIcon="ChevronDown"
                    onClick={() => setIsFilterActive(true)}
                  >
                    {getMonthDisplay(filterValue.month)} {currentYear}
                  </Button>
                }
                isOpen={isFilterActive}
                onClose={() => setIsFilterActive(false)}
              >
                <DropdownMenu
                  name="filter"
                  options={months}
                  onChange={({ target }) => {
                    setFilterValue({
                      ...filterValue,
                      month: target.value as string,
                    });
                    setIsFilterActive(false);
                  }}
                />
              </Popper>
            </div>
          </div>

          <Accordion
            variant="Outlined"
            itemClassName={styles.costUsageItem}
            items={[
              {
                header: (
                  <div className={styles.itemHeader}>
                    <div>
                      <Icon name="ChevronRight" />
                      <span className="p4 medium">Total server cost</span>
                    </div>

                    <span className="p3 semibold">
                      ₦{server?.total?.toLocaleString()}
                    </span>
                  </div>
                ),
                content: (
                  <Table
                    columns={serverBillingColumns}
                    data={server?.breakdown}
                    loadData={() => null}
                    isMultiSelect={false}
                    isToolbarActive={false}
                  />
                ),
              },
              {
                header: (
                  <div className={styles.itemHeader}>
                    <div>
                      <Icon name="ChevronRight" />
                      <span className="p4 medium">Total storage cost</span>
                    </div>

                    <span className="p3 semibold">
                      ₦{storage?.total?.toLocaleString()}
                    </span>
                  </div>
                ),
                content: (
                  <Table
                    columns={storageBillingColumns}
                    data={storage?.breakdown}
                    loadData={() => null}
                    isMultiSelect={false}
                    isToolbarActive={false}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default CostUsage;
