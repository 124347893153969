import { useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import moment from "moment";

import { Button, ButtonGroup, IObject, Table } from "nebula-galaxy";
import { useReactQuery } from "hooks";
import { fetchMembers, fetchProjectLogs } from "services";

import type { QueryParams } from "utils/types";
import { dateFilters, defaultQueryParams } from "utils/consts";
import { pluralize } from "utils/functions";

import { auditLogsColumns } from "../columns";

import styles from "../project-details.module.scss";

const queryKey = "project-audit-logs";

interface AuditLogsProps {
  members: IObject[];
}

const AuditLogs = ({ members }: AuditLogsProps) => {
  const { id } = useParams();

  const [currentDateFilter, setCurrentDateFilter] = useState(
    dateFilters[0].value
  );
  const [queryParams, setQueryParams] = useState<QueryParams>({
    ...defaultQueryParams,
    startDate: moment()
      .subtract(currentDateFilter, "days")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const { data, isLoading } = useReactQuery({
    queryKey,
    queryParams,
    queryFunction: () =>
      fetchProjectLogs({ projectId: id as string, ...queryParams }),
  });

  const handleDateFilter = (days: number) => {
    setCurrentDateFilter(days);
    setQueryParams({
      ...queryParams,
      startDate: moment().subtract(days, "days").format("YYYY-MM-DD"),
    });
  };

  return (
    <Table
      className={styles.auditLogs}
      columns={auditLogsColumns(members)}
      data={data?.docs}
      loading={isLoading}
      pagination={data?.pagination}
      loadData={setQueryParams}
      toolbarActions={
        <ButtonGroup variant="Neutral" size="SM">
          {dateFilters.map(({ label, value }) => (
            <Button
              key={`date-filter-${value}`}
              className={cn({
                "button--is-active": value === currentDateFilter,
              })}
              variant="GroupButton"
              onClick={() => {
                handleDateFilter(value);
              }}
              type="button"
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      }
      searchPlaceholder="Search by name or email"
      dataText={pluralize("record", data?.docs?.length)}
      isMultiSelect={false}
    />
  );
};

export default AuditLogs;
