import { IObject } from "nebula-galaxy";
import { api } from "config";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";

export const getVPCs = async (body: IObject) => {
  return await api({ url: "networks/vpcs/search", body });
};

export const getVPC = async (id: string) => {
  return await api({ url: `networks/vpcs/${id}`, method: "GET" });
};

export const createVPC = async (
  body: IObject,
  onSuccess: (message: string, serverId?: string) => void,
  onError: (message: string) => void
) => {
  const { data, success, message } = await api({
    url: "networks/vpcs",
    body,
  });

  success && data
    ? onSuccess(data.vpcId, message)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const updateVPC = async (
  vpcId: string,
  body: IObject,
  onSuccess: (message: string, vpcId?: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `networks/vpcs/${vpcId}`,
    method: "PATCH",
    body,
  });

  success
    ? onSuccess(message, vpcId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const deleteVPC = async (
  vpcId: string,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `networks/vpcs/${vpcId}`,
    method: "DELETE",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};
