import type { ChangeEvent } from "react";
import { PhoneNumberTextField, SelectEvent } from "nebula-galaxy";
import { Select, TextField } from "nebula-galaxy";

import type { FormObject } from "utils/types";
import {
  generateCountriesOptions,
  generateStatesOptions,
  getFormValues,
} from "utils/functions";

import styles from "../onboarding.module.scss";

interface PersonalProps {
  formData: FormObject;
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid: boolean
  ) => void;
}

const Personal = ({ formData, handleOnChange }: PersonalProps) => {
  const {
    fullName,
    phoneNumber,
    phoneNumberCountryCode,
    country,
    state,
    city,
    address,
    postalCode,
  } = getFormValues(formData);

  return (
    <div className={styles.inputs}>
      <TextField
        label="Full name"
        name="fullName"
        value={fullName}
        onChange={handleOnChange}
        required
      />
      <PhoneNumberTextField
        variant="Filled"
        label="Phone number"
        name="phoneNumber"
        countryCodeValue={phoneNumberCountryCode}
        value={phoneNumber}
        onChange={handleOnChange}
        required
      />
      <Select
        label="Country"
        name="country"
        options={generateCountriesOptions()}
        value={country}
        onChange={handleOnChange}
        isSearchable
        required
      />
      <Select
        label="State/Province"
        name="state"
        options={generateStatesOptions(country)}
        value={state}
        onChange={handleOnChange}
        isSearchable
        required
      />
      <TextField
        label="City/Town"
        name="city"
        value={city}
        onChange={handleOnChange}
        required
      />
      <TextField
        label="Address"
        name="address"
        value={address}
        onChange={handleOnChange}
        required
      />
      <TextField
        label="Postal code"
        name="postalCode"
        value={postalCode}
        onChange={handleOnChange}
        required
      />
    </div>
  );
};

export default Personal;
