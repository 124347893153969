import { Fragment } from "react";
import cn from "classnames";

import { Icon, IconName, Button, Divider } from "nebula-galaxy";

import { additionalResources } from "utils/data";

import styles from "./overview.module.scss";

const AdditionalResources = () => {
  return (
    <div className={styles.additionalResources}>
      {additionalResources.map(({ icon, title, link }, index) => (
        <Fragment key={`additional-resource-${index}`}>
          <a href={link} target="_blank" rel="noreferrer">
            <Button
              variant="SecondaryLink"
              rightIcon="ArrowUpRight"
              className={styles.additionalResourcesItem}
            >
              <>
                <Icon
                  name={icon as IconName}
                  className={styles.additionalResourcesIcon}
                />
                <span
                  className={cn("p5 medium", styles.additionalResourcesTitle)}
                >
                  {title}
                </span>
              </>
            </Button>
          </a>
          <Divider className={styles.divider} />
        </Fragment>
      ))}
    </div>
  );
};

export default AdditionalResources;
