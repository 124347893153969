import cn from "classnames";
import { Icon } from "nebula-galaxy";

import { routes } from "config";
import { MainMenu } from "../types";

import styles from "components/navigation/navigation.module.scss";

export const resourceOptions = [
  {
    label: "Instance",
    value: "instance",
    display: (
      <span className={styles.resourceItem}>
        <Icon className={styles.resourceItemIcon} name="Server" />
        <span className={cn("p5 medium", styles.resourceItemText)}>
          Instance
        </span>
      </span>
    ),
  },
  // {
  //   label: "Database",
  //   value: "database",
  //   display: (
  //     <span className={styles.resourceItem}>
  //       <Icon className={styles.resourceItemIcon} name="Plus" />
  //       <span className={cn("p5 medium", styles.resourceItemText)}>
  //         Database
  //       </span>
  //     </span>
  //   ),
  // },
  {
    label: "Storage",
    value: "storage",
    display: (
      <span className={styles.resourceItem}>
        <Icon className={styles.resourceItemIcon} name="Folders" />
        <span className={cn("p5 medium", styles.resourceItemText)}>
          Storage
        </span>
      </span>
    ),
  },
  // {
  //   label: "Security group",
  //   value: "security-group",
  //   display: (
  //     <span className={styles.resourceItem}>
  //       <Icon className={styles.resourceItemIcon} name="Plus" />
  //       <span className={cn("p5 medium", styles.resourceItemText)}>
  //         Security group
  //       </span>
  //     </span>
  //   ),
  // },
];

export const mainMenu: MainMenu[] = [
  {
    url: routes.console.home,
    label: "Overview",
    icon: "Home",
  },
  {
    // url: routes.console.nebCompute,
    label: "NebCompute",
    icon: "Server",
    subMenu: [
      { label: "Instances", url: routes.console.nebCompute.instances },
      { label: "VPCs", url: routes.console.nebCompute.vpcs },
      // { label: "Blueprints", url: routes.console.nebCompute.blueprints },
      // { label: "Security groups", url: routes.console.nebCompute.securityGroups },
      { label: "Key pairs", url: routes.console.nebCompute.keyPairs },
    ],
  },
  {
    url: routes.console.nebStore,
    label: "NebStore",
    icon: "Folders",
  },
  // {
  //   url: routes.console.database,
  //   label: "Database",
  //   icon: "Database",
  // },
];

export const settingsMenu: MainMenu[] = [
  {
    url: routes.settings.company,
    label: "Company",
    icon: "Briefcase",
  },
  {
    url: routes.settings.projects,
    label: "Projects",
    icon: "Layers",
  },
  // {
  //   url: routes.settings.billing,
  //   label: "Billing",
  //   icon: "ReceiptText",
  // },
  {
    url: routes.settings.accessManager,
    label: "Access manager",
    icon: "Users",
  },
  {
    url: routes.settings.profile,
    label: "Profile",
    icon: "User",
  },
  {
    url: routes.settings.logs,
    label: "Logs",
    icon: "FileText",
  },
];
