const DatabaseIcon = () => {
  return (
    <svg
      width="73"
      height="76"
      viewBox="0 0 73 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.26419 65.7088V11.637H67.3139V65.7088C64.6546 68.9591 54.7267 75.4595 36.289 75.4595C17.8514 75.4595 7.92346 68.9591 5.26419 65.7088Z"
        fill="currentColor"
        stroke="#824FE7"
        strokeWidth="0.914912"
        strokeMiterlimit="10"
      />
      <path
        d="M35.4889 41.5911H30.3622L40.4255 53.4268H45.5522L35.4889 41.5911Z"
        fill="#03D74A"
      />
      <path
        d="M30.4674 50.3083V53.4126H33.5871V50.3083H30.4674Z"
        fill="#03D74A"
      />
      <path
        d="M42.5351 41.612V44.7163H45.6548V41.612H42.5351Z"
        fill="#03D74A"
      />
      <g filter="url(#filter0_d_4223_30841)">
        <path
          d="M67.3139 27.5928C67.3139 32.9779 53.4236 37.3434 36.289 37.3434C19.1545 37.3434 5.26419 32.9779 5.26419 27.5928"
          stroke="#824FE7"
          strokeWidth="0.914912"
          strokeMiterlimit="10"
        />
      </g>
      <g filter="url(#filter1_d_4223_30841)">
        <path
          d="M67.5601 50.0449C67.5601 55.6478 53.5555 60.1898 36.28 60.1898C19.0045 60.1898 5 55.6478 5 50.0449"
          stroke="#824FE7"
          strokeWidth="0.914912"
          strokeMiterlimit="10"
        />
      </g>
      <g filter="url(#filter2_i_4223_30841)">
        <ellipse
          cx="36.289"
          cy="10.7507"
          rx="31.0248"
          ry="9.75067"
          fill="currentColor"
        />
      </g>
      <ellipse
        cx="36.289"
        cy="10.7507"
        rx="31.0248"
        ry="9.75067"
        stroke="#824FE7"
        strokeWidth="0.914912"
        strokeMiterlimit="10"
      />
      <defs>
        <filter
          id="filter0_d_4223_30841"
          x="0.612661"
          y="24.4472"
          width="71.3528"
          height="18.5962"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.04852" />
          <feGaussianBlur stdDeviation="2.09704" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4223_30841"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4223_30841"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4223_30841"
          x="0.348471"
          y="46.8994"
          width="71.8631"
          height="18.9904"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.04852" />
          <feGaussianBlur stdDeviation="2.09704" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4223_30841"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4223_30841"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_i_4223_30841"
          x="4.80674"
          y="0.54248"
          width="62.9646"
          height="21.4648"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.04852" />
          <feGaussianBlur stdDeviation="1.04852" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4223_30841"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default DatabaseIcon;
