import type { ChangeEvent, FormEvent } from "react";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import cn from "classnames";

import {
  Button,
  Divider,
  TextField,
  PasswordTextField,
  Callout,
} from "nebula-galaxy";

import { routes } from "config";
import { OnboardingLayout } from "layouts";
import { GithubButton, GoogleButton } from "components";
import { useAuth } from "context/auth";
import { getFormValues, isFormValid } from "utils/functions";

import styles from "../auth.module.scss";

const initialFormData = {
  email: {
    value: "",
    valid: false,
  },
  password: {
    value: "",
    valid: false,
  },
};

const SignIn = () => {
  const [searchParams] = useSearchParams();
  const { isLoading, isLoadingSocial, authenticate, error, setError } = useAuth();

  const [formData, setFormData] = useState({ ...initialFormData });

  const socialId = searchParams.get("social");
  const hasError = searchParams.get("error");

  const { email, password } = getFormValues(formData);

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement>,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    authenticate("auth/session/user", { email, password });
  };

  useEffect(() => {
    socialId && authenticate("auth/session/social/user", { socialId });
    hasError === 'true' && setError("You don’t have a Nebula account. Kindly contact the sales team to create one")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialId, hasError]);

  return (
    <OnboardingLayout isBannerActive={false} isContentLogoActive>
      <div className={styles.header}>
        <h5 className={cn("medium", styles.title)}>Sign in to your account</h5>
      </div>
      <form onSubmit={submit}>
        <div className={styles.form}>
          {error && hasError && (
            <Callout
              title=""
              variant="Critical"
              message={error}
              isCloseVisible={false}
            />
          )}

          <GoogleButton />
          <GithubButton />

          <Divider text="or" />

          {error && !hasError &&(
            <Callout
              title=""
              variant="Critical"
              message={error}
              isCloseVisible={false}
            />
          )}

          <TextField
            label="Email"
            placeholder="email@example.com"
            name="email"
            type="email"
            value={email}
            onChange={handleOnChange}
            required
          />
          <PasswordTextField
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={handleOnChange}
            hasRules={false}
            required
          />
        </div>

        <div className={styles.forgotPassword}>
          <Link to={routes.auth.forgotPassword}>
            <Button variant="SecondaryLinkButton" size="MD">
              Forgot password?
            </Button>
          </Link>
        </div>

        <div className={styles.form}>
          <Button
            size="LG"
            type="submit"
            loading={isLoading && !isLoadingSocial}
            disabled={!isFormValid(formData)}
          >
            Sign in
          </Button>

          {/* <div className={styles.alternative}>
            <span className="p5">Don't have an account?</span>
            <Link to={routes.auth.signUp}>
              <Button variant="PrimaryLinkButton" size="MD">
                Sign up
              </Button>
            </Link>
          </div> */}
        </div>
      </form>
    </OnboardingLayout>
  );
};

export default SignIn;
