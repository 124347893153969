import { Icon } from "nebula-galaxy";

import styles from "./mini-page-loader.module.scss";

const MiniPageLoader = () => (
  <div className={styles.container}>
    <Icon name="Loader" size="LG" />
  </div>
);

export default MiniPageLoader;
