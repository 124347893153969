import cn from "classnames";
import { Button } from "nebula-galaxy";
import { useAuth } from "context";

import styles from "./google-button.module.scss";

const GoogleButton = () => {
  const { isLoadingSocial, socialAuthenticate, socialType } = useAuth();

  return (
    <Button
      className={cn(styles.googleButton, styles.googleButtonLoading)}
      leftIcon="Google"
      variant="Secondary"
      size="LG"
      type="button"
      loading={socialType === 'google' && isLoadingSocial}
      onClick={() => socialAuthenticate("auth/session/google", 'google')}
    >
      Continue with Google
    </Button>
  );
};

export default GoogleButton;
