export const initialFormData = {
  contact: {
    fullName: {
      value: "",
      valid: false,
    },
    phoneNumberCountryCode: {
      value: "",
      valid: false,
    },
    phoneNumber: {
      value: "",
      valid: false,
    },
    country: {
      value: "",
      valid: false,
    },
    state: {
      value: "",
      valid: false,
    },
    city: {
      value: "",
      valid: false,
    },
    address: {
      value: "",
      valid: false,
    },
    postalCode: {
      value: "",
      valid: false,
    },
  },
  project: {
    name: {
      value: "",
      valid: false,
    },
    details: {
      value: "",
      valid: false,
    },
    audience: {
      value: "",
      valid: false,
    },
    currentProvider: {
      value: "",
      valid: true,
    },
  },
  billing: {
    cardName: {
      value: "",
      valid: false,
    },
    billingCountry: {
      value: "",
      valid: true,
    },
    billingState: {
      value: "",
      valid: true,
    },
    billingCity: {
      value: "",
      valid: true,
    },
    billingAddress: {
      value: "",
      valid: true,
    },
    billingPostalCode: {
      value: "",
      valid: true,
    },
  },
};

export const projectDetailsOptions = [
  {
    label: "Financial Services",
    value: "Financial Services",
  },
  {
    label: "Telecoms",
    value: "Telecoms",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Healthcare",
    value: "Healthcare",
  },
  {
    label: "Real Estate and Construction",
    value: "Real Estate and Construction",
  },
  {
    label: "Government and NGOs",
    value: "Government and NGOs",
  },
  {
    label: "E-commerce, Manufacturing and Agriculture",
    value: "E-commerce, Manufacturing and Agriculture",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const projectAudienceOptions = [
  {
    label: "Businesses",
    value: "Businesses",
  },
  {
    label: "Consumers",
    value: "Consumers",
  },
  {
    label: "Developers",
    value: "Developers",
  },
  {
    label: "Non-profit organizations",
    value: "Non-profit organizations",
  },
  {
    label: "Government agencies",
    value: "Government agencies",
  },
];

export const currentProviderOptions = [
  {
    label: "AWS",
    value: "AWS",
  },
  {
    label: "Azure",
    value: "Azure",
  },
  {
    label: "GCP",
    value: "GCP",
  },
  {
    label: "Others",
    value: "Others",
  },
];
