import cn from "classnames";
import { Button, Icon, IconName } from "nebula-galaxy";

import { migrationGuides } from "utils/data";

import styles from "./overview.module.scss";

const MigrationGuides = () => (
  <div className={styles.migrationGuides}>
    {migrationGuides.map(
      ({ icon, title, description, link, linkText }, index) => (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          key={`migration-guide-${index}`}
          className={styles.migrationGuideItem}
        >
          <span className={styles.migrationGuideIcon}>
            <Icon name={icon as IconName} size="MD" />
          </span>
          <div>
            <span className={cn("p4 medium", styles.migrationGuideTitle)}>
              {title}
            </span>
            <span className={cn("p5", styles.migrationGuideDescription)}>
              {description}
            </span>

            <Button
              className={styles.migrationGuideButton}
              variant="PrimaryLinkButton"
              size="SM"
              rightIcon="ArrowUpRight"
            >
              {linkText}
            </Button>
          </div>
        </a>
      )
    )}
  </div>
);

export default MigrationGuides;
