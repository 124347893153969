import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import type { IObject } from "nebula-galaxy";
import { getLocalUser, removeLocalUser, setLocalUser } from "utils/functions";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";

import { api, routes } from "config";
import { useAlert } from "../alert";

interface AuthContextType {
  isAuthenticated: boolean;
  isLoadingApp: boolean;
  isLoading: boolean;
  isLoadingSocial: boolean;
  isLoadingRegionProviders: boolean;
  socialType: string;
  userProfile: IObject | null;
  regionProviders: IObject[];
  error: string;
  setError: (message: string) => void;
  fetchUserProfile: () => void;
  authenticate: (url: string, body: IObject) => void;
  socialAuthenticate: (url: string, type: string) => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoadingApp: true,
  isLoading: false,
  isLoadingSocial: false,
  isLoadingRegionProviders: true,
  socialType: "",
  userProfile: null,
  regionProviders: [],
  error: "",
  fetchUserProfile: () => {},
  authenticate: () => {},
  socialAuthenticate: () => {},
  signOut: () => {},
  setError: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: IObject) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showAlert } = useAlert();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoadingApp, setIsLoadingApp] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSocial, setIsLoadingSocial] = useState<boolean>(false);
  const [isLoadingRegionProviders, setIsLoadingRegionProviders] =
    useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<IObject | null>(null);
  const [regionProviders, setRegionProviders] = useState<IObject[]>([]);
  const [error, setError] = useState<string>("");
  const [socialType, setSocialType] = useState("");

  useEffect(() => {
    const user = getLocalUser();

    if (user) {
      if (user.tokens) {
        setIsAuthenticated(true);
        fetchUserProfile();
        fetchRegionProviders();
      } else {
        setIsLoadingApp(false);
      }
      setIsLoading(false);
    } else {
      setIsLoadingApp(false);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setError("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const fetchUserProfile = async () => {
    const { success, data, message } = await api({
      url: "users/profile",
      method: "GET",
    });

    if (success) {
      success && setUserProfile(data);
    } else {
      console.error(message);
      showAlert({
        variant: "Critical",
        message: message || DEFAULT_ERROR_MESSAGE,
        active: true,
      });
    }

    setIsLoadingApp(false);
  };

  const fetchRegionProviders = async (token?: string) => {
    setIsLoadingRegionProviders(true);

    const response = await api({
      url: "providers",
      method: "GET",
      token,
    });

    const { success, data } = response || {};

    if (success) {
      setRegionProviders(data);
    }

    setIsLoadingRegionProviders(false);
  };

  const authenticate = async (url: string, body: IObject) => {
    setIsLoading(true);
    body?.socialId && setIsLoadingSocial(true);
    setError("");

    const response = await api({
      url,
      body,
      isAuthRequired: false,
    });

    handleAuthResponse(response);
  };

  const socialAuthenticate = async (url: string, type: string) => {
    setSocialType(type);
    setIsLoadingSocial(true);
    setError("");
    window.open(`${process.env.REACT_APP_API_URL}${url}`, "_self");
    
    setIsLoadingSocial(false);
  };

  const handleAuthResponse = ({ data, success, message }: IObject) => {
    if (success) {
      const user = data?.user;

      const isNotVerified = user.accountStatus === "pending";
      const isNotOnboarded = user.accountStatus === "onboarding";

      if (isNotVerified) {
        navigate(`${routes.auth.verifyEmail}?userId=${user.userId}`);
      } else if (isNotOnboarded) {
        setLocalUser(data);
        setUserProfile(user);
        navigate(routes.onboarding.home);
      } else {
        setLocalUser(data);
        if (data?.tokens) {
          setIsAuthenticated(true);
          fetchUserProfile();
          fetchRegionProviders(data?.tokens?.accessToken);
        }
      }
    } else {
      console.error(message);
      setError(message || DEFAULT_ERROR_MESSAGE);
    }

    setIsLoading(false);
    setIsLoadingSocial(false);
  };

  const signOut = () => {
    removeLocalUser();
    setIsAuthenticated(false);
    setUserProfile(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoadingApp,
        isLoading,
        isLoadingSocial,
        isLoadingRegionProviders,
        socialType,
        userProfile,
        regionProviders,
        error,
        fetchUserProfile,
        authenticate,
        socialAuthenticate,
        signOut,
        setError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
