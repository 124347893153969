import { Button, IObject, Modal } from "nebula-galaxy";

interface RemoveMemberProps {
  active: boolean;
  close: () => void;
  isLoading: boolean;
  submit: () => void;
  selectedMember: IObject | null;
  projectName: string;
}

const RemoveMember = ({
  active,
  close,
  isLoading,
  submit,
  selectedMember,
  projectName,
}: RemoveMemberProps) => {
  return (
    <Modal
      active={active}
      onClose={close}
      variant="Warning"
      title="Remove from project"
      actions={
        <>
          <Button variant="Tertiary" size="SM" onClick={close}>
            Cancel
          </Button>

          <Button
            variant="Destructive"
            size="SM"
            onClick={submit}
            loading={isLoading}
          >
            Remove member
          </Button>
        </>
      }
    >
      <span className="p5">
        <span className="medium">{selectedMember?.fullName}</span> will lose
        access to resources associated with{" "}
        <span className="medium">{projectName}</span>. Are you sure you want to
        proceed?
      </span>
    </Modal>
  );
};

export default RemoveMember;
