import { FormEvent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import type { SelectEvent, IObject } from "nebula-galaxy";
import { Button, ChipsTextArea, Table } from "nebula-galaxy";
import { FullScreenOverlay } from "components";

import { api, routes } from "config";
import { useAuth } from "context";

import { inviteMembersColumns } from "./columns";

import { roles } from "utils/data";

import styles from "./access-manager.module.scss";

interface InviteMemberProps {
  queryKey: string;
  close: () => void;
  isRerouteActive?: boolean;
}

const InviteMember = ({
  queryKey,
  close,
  isRerouteActive = true,
}: InviteMemberProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  const { projects, email: userProfileEmail } = userProfile || {};

  const [formData, setFormData] = useState<IObject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleEmails = (emails: string[]) => {
    setFormData(
      emails.map((email, index) => {
        const existingEmail = formData.find((data) => data.email === email);

        return {
          id: index,
          email,
          projectId: existingEmail?.projectId || "",
          role: existingEmail?.role || "",
        };
      })
    );
  };

  const handleOnChange = ({ target }: SelectEvent, id: number) => {
    const { name, value } = target;

    const newFormData = formData.map((item) =>
      item.id === id ? { ...item, [name]: value } : item
    );
    setFormData(newFormData);
  };

  const removeEmail = (id: number) => {
    setFormData(formData.filter((data) => data.id !== id));
  };

  const validateForm = () => {
    return (
      formData.length &&
      formData.every(({ projectId, role }) => projectId && role) && !error
    );
  };
  
  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    const { success, message } = await api({
      url: "users/invitation",
      body: formData.map(({ id, ...rest }) => rest),
    });

    if (success) {
      queryClient.invalidateQueries(queryKey);
      close();
      isRerouteActive &&
        navigate(`${routes.settings.accessManager}?tab=invited-users`);
    } else {
      setError(message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const hasCurrentUser = formData.some(
      ({ email }) => email === userProfileEmail
    );
    hasCurrentUser ? setError("You cannot invite yourself as a member") : setError('')
  }, [formData, userProfileEmail]);

  return (
    <FullScreenOverlay title="Invite team member" handleClose={close}>
      <form onSubmit={submit} className={styles.membersForm}>
        <div>
          <ChipsTextArea
            className={styles.chipsTextArea}
            label="Send invite to"
            placeholder="Type or paste emails, separated by commas"
            variant="Outline"
            onChange={handleEmails}
            value={formData.map(({ email }) => email)}
            hasError={!!error}
            footnote={error}
            chipsWithError={[userProfileEmail]}
          />
          {!!formData.length && (
            <Table
              columns={inviteMembersColumns(
                { projects, roles },
                handleOnChange,
                removeEmail
              )}
              data={formData}
              loadData={() => null}
              isMultiSelect={false}
              isToolbarActive={false}
            />
          )}
        </div>

        <div className={styles.formFooter}>
          <Button
            size="SM"
            rightIcon="Send"
            type="submit"
            loading={isLoading}
            disabled={!validateForm()}
          >
            Send invite
          </Button>
        </div>
      </form>
    </FullScreenOverlay>
  );
};

export default InviteMember;
