import { useState } from "react";
import { Button, DropdownMenu, Icon, Popper } from "nebula-galaxy";

import { useAuth } from "context";
import { resourceOptions } from "utils/data/navigation";

import styles from "./navigation.module.scss";

interface AddResourceProps {
  setIsInstanceFormActive: (e: boolean) => void;
  setIsBucketFormActive: (e: boolean) => void;
}

const AddResource = ({
  setIsInstanceFormActive,
  setIsBucketFormActive,
}: AddResourceProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { isLoading: isLoadingRegionProviders } = useAuth();

  const handleActions = (action: string) => {
    switch (action) {
      case "instance":
        setIsInstanceFormActive(true);
        break;

      case "storage":
        setIsBucketFormActive(true);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Popper
        popperClassName={styles.addResourcePopper}
        anchorElement={
          <Button
            variant="PrimaryIcon"
            size="SM"
            onClick={() => setIsDropdownOpen(true)}
            disabled={isLoadingRegionProviders}
          >
            <Icon name="Plus" />
          </Button>
        }
        isOpen={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
      >
        <DropdownMenu
          className={styles.addResourceMenu}
          name="add-resource"
          groupedOptions={[
            {
              title: "Create new resource",
              options: resourceOptions,
            },
          ]}
          onChange={({ target }) => handleActions(target.value as string)}
        />
      </Popper>
    </>
  );
};

export default AddResource;
