import { IObject, StatusChip } from "nebula-galaxy";
import moment from "moment";
import { getFileFormat, getFileName, formatFileSize } from "utils/functions";

export const bucketsColumn = [
  { key: "bucketName", header: "Bucket name" },
  {
    key: "publicAccess",
    header: "Public Access",
    renderer: ({ isPrivate }: IObject) => (
      <StatusChip
        variant={isPrivate ? "Neutral" : "Critical"}
        text={isPrivate ? "Disabled" : "Enabled"}
      />
    ),
  },
  {
    key: "lastModified",
    header: "Last Modified",
    renderer: ({ lastModified, createdAt }: IObject) => (
      <>{moment(lastModified || createdAt).format("MMM D, YYYY, h:mm A")}</>
    ),
  },
];

export const objectsColumn = [
  {
    key: "name",
    header: "Objects",
    renderer: ({ objectKey }: IObject) => {
      return getFileName(objectKey);
    },
  },
  {
    key: "fileFormat",
    header: "File Format",
    renderer: ({ objectKey }: IObject) => {
      return getFileFormat(objectKey);
    },
  },
  {
    key: "size",
    header: "Size",
    renderer: ({ fileSize }: IObject) => {
      return formatFileSize(fileSize);

    },
  },
  {
    key: "createdAt",
    header: "Date Added",
    renderer: ({ createdAt }: IObject) => (
      <>{moment(createdAt).format("MMM D, YYYY, h:mm A")}</>
    ),
  },
];
