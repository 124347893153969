import { IObject } from "nebula-galaxy";

import { api } from "config";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";
import { serverInstances } from "utils/dummy";

export const fetchServers = async (body: IObject) => {
  return await api({ url: "server/search", body });
};

export const fetchServer = async (id: string) => {
  return await api({ url: `server/${id}`, method: "GET" });
};

export const fetchComputeDetails = async (providerId: string) => {
  return await api({ url: `providers/${providerId}/compute`, method: "GET" });
};

export const createServer = async (
  body: IObject,
  onSuccess: (message: string, serverId?: string) => void,
  onError: (message: string) => void
) => {
  const { data, success, message } = await api({
    url: "server",
    body,
  });

  success
    ? onSuccess(message, data?.serverId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const updateServer = async (
  serverId: string,
  type: "start" | "stop" | "restart",
  onSuccess: (message: string, serverId?: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `server/${serverId}/${type}`,
  });

  success
    ? onSuccess(message, serverId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const startServer = async (
  serverId: string,
  onSuccess: (message: string, serverId?: string) => void,
  onError: (message: string) => void
) => {
  const { data, success, message } = await api({
    url: `server/${serverId}/start`,
  });

  success
    ? onSuccess(message, data?.serverId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const rebootServer = async (
  serverId: string,
  onSuccess: (message: string, serverId?: string) => void,
  onError: (message: string) => void
) => {
  const { data, success, message } = await api({
    url: `server/${serverId}/restart`,
  });

  success
    ? onSuccess(message, data?.serverId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const stopServer = async (
  serverId: string,
  onSuccess: (message: string, serverId?: string) => void,
  onError: (message: string) => void
) => {
  const { data, success, message } = await api({
    url: `server/${serverId}/shutdown`,
  });

  success
    ? onSuccess(message, data?.serverId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const deleteServer = async (
  serverId: string,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `server/${serverId}`,
    method: "DELETE",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};
