import { useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Callout,
  DropdownMenu,
  IObject,
  Icon,
  Modal,
  Table,
  TextField,
} from "nebula-galaxy";

import { routes } from "config";
import { MainLayout } from "layouts";
import { PageHeader } from "components";
import { useReactQuery } from "hooks";
import { deleteBucket, emptyBucket, fetchBuckets } from "services";
import { useAlert, useAuth } from "context";

import type { QueryParams } from "utils/types";
import { pluralize } from "utils/functions";
import { BUCKETS_GUIDE_LINK, defaultQueryParams } from "utils/consts";
import { bucketActions } from "utils/data";

import CreateBucket from "./create-bucket";
import { bucketsColumn } from "./columns";

import { BucketsEmptyStateIcon } from "assets";

import styles from "./buckets.module.scss";
import { useQueryClient } from "react-query";

const queryKey = "buckets";

const Buckets = () => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const [isFormActive, setIsFormActive] = useState(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    ...defaultQueryParams,
  });

  const [selectedBucket, setSelectedBucket] = useState<IObject | null>(null);
  const [bucketAction, setBucketAction] = useState("");
  const [bucketName, setBucketName] = useState("");

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);
  const [isUpdatingBucket, setIsUpdatingBucket] = useState(false);

  const { data, isLoading } = useReactQuery({
    queryKey,
    queryParams,
    queryFunction: () => fetchBuckets(queryParams),
  });

  const { isLoading: isLoadingRegionProviders, regionProviders } = useAuth();

  const closeModals = () => {
    setBucketAction("");
  };

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    queryClient.invalidateQueries(queryKey);
    setIsUpdatingBucket(false);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsUpdatingBucket(false);
  };

  const handleEmptyBucket = () => {
    setIsUpdatingBucket(true);

    emptyBucket(selectedBucket?.bucketId as string, onSuccess, onError);
  };

  const handleDeleteBucket = () => {
    setIsUpdatingBucket(true);

    deleteBucket(selectedBucket?.bucketId as string, onSuccess, onError);
  };

  const buckets = data?.docs;

  return (
    <MainLayout>
      <section className="page-content">
        <PageHeader
          title="Storage"
          description={
            <>
              Create and manage all your buckets and folders. To learn more
              about how storage work,{" "}
              <a href={BUCKETS_GUIDE_LINK} target="_blank" rel="noreferrer">
                read the guide.
              </a>
            </>
          }
        />

        {queryParams?.term || isLoading || buckets?.length ? (
          <Table
            className={styles.membersTable}
            columns={bucketsColumn}
            data={buckets}
            loading={isLoading}
            pagination={data?.pagination}
            loadData={setQueryParams}
            onRowClick={({ bucketId }) =>
              navigate(`${routes.console.nebStore}/${bucketId}`)
            }
            toolbarActions={
              <>
                <Button
                  size="SM"
                  leftIcon="Plus"
                  onClick={() => setIsFormActive(true)}
                  disabled={isLoadingRegionProviders}
                >
                  Add new bucket
                </Button>
              </>
            }
            rowPopperActions={(bucket) => (
              <DropdownMenu
                className={styles.actionMenu}
                name="action"
                options={bucketActions}
                onChange={({ target }) => {
                  setSelectedBucket(bucket);
                  setBucketAction(target.value as string);
                }}
              />
            )}
            searchPlaceholder="Search bucket name"
            dataText={pluralize("bucket", buckets?.length)}
            isMultiSelect={false}
          />
        ) : (
          <div className={styles.emptyState}>
            <span className={styles.emptyStateIcon}>
              <BucketsEmptyStateIcon />
            </span>

            <div>
              <span className="p3 medium">
                You don't have any buckets created yet.
              </span>
              <span className={cn("p5", styles.emptyStateDescription)}>
                Your buckets will appear here once you create at least one
                bucket.
              </span>
            </div>
            <div className={styles.emptyStateActions}>
              <Button
                size="SM"
                onClick={() => setIsFormActive(true)}
                disabled={isLoadingRegionProviders}
              >
                Create bucket
              </Button>
              {/* <Button variant="PrimaryLink" size="MD" rightIcon="ArrowUpRight">
                See storage SDKs
              </Button> */}
            </div>
          </div>
        )}

        {isFormActive && (
          <CreateBucket
            queryKey={queryKey}
            providerId={regionProviders?.[0]?.providerId}
            close={() => setIsFormActive(false)}
          />
        )}
      </section>

      <Modal
        className={styles.terminateModal}
        active={bucketAction === "empty"}
        onClose={closeModals}
        variant="Standard"
        title=""
        actions={
          <>
            <Button variant="Tertiary" size="SM" onClick={closeModals}>
              Cancel
            </Button>

            <Button
              variant="Destructive"
              size="SM"
              onClick={handleEmptyBucket}
              disabled={bucketName !== selectedBucket?.bucketName}
              loading={isUpdatingBucket}
            >
              Empty bucket
            </Button>
          </>
        }
        hideHeader
      >
        <Callout
          variant="Warning"
          title={`Empty '${selectedBucket?.bucketName}' bucket`}
          message="All objects within the database bucket will be deleted. This action is irreversible; we recommend migrating all your objects before emptying your bucket."
          isCloseVisible={false}
        />

        <TextField
          className={styles.textfield}
          variant="Filled"
          label="Confirm with bucket name"
          placeholder="Enter bucket name"
          name="bucketName"
          onChange={({ target }) => setBucketName(target.value)}
          required
        />
      </Modal>
    </MainLayout>
  );
};

export default Buckets;
