import cn from "classnames";
import MenuItem from "./menu-item";

import { Button, Icon } from "nebula-galaxy";
import { settingsMenu } from "utils/data/navigation";

import styles from "./navigation.module.scss";

interface SettingsMenuProps {
  close: () => void;
}

const SettingsMenu = ({ close }: SettingsMenuProps) => {
  return (
    <section className={styles.settingsMenuContainer}>
      <div className={cn(styles.topControl, styles.settingsMenuHeader)}>
        <Button variant="TertiaryIcon" size="SM" onClick={close}>
          <Icon name="ChevronLeft" />
        </Button>
        <span className="p3 semibold">Settings</span>
      </div>

      <nav>
        <ul className={styles.settingsMenu}>
          {settingsMenu.map(({ url, label, icon, subMenu }, index) => (
            <MenuItem
              key={`menu-${index}`}
              url={url}
              icon={icon}
              label={label}
              subMenu={subMenu}
            />
          ))}
        </ul>
      </nav>
    </section>
  );
};

export default SettingsMenu;
