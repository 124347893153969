import cn from "classnames";
import { IObject } from "nebula-galaxy";
import { Link } from "react-router-dom";
import moment from "moment";

import { routes } from "config";

import styles from "./logs.module.scss";

export const logsColumns = ({
  users,
  projects,
}: {
  users: IObject;
  projects: IObject;
}) => {
  const usersOptions = users?.map(({ userId, fullName, email }: IObject) => ({
    label: fullName,
    value: userId,
    display: (
      <div className={styles.memberOption}>
        <span className={cn("p5 medium", styles.memberFullName)}>
          {fullName || email}
        </span>
        <span className={cn("cap1", styles.memberEmail)}>{email}</span>
      </div>
    ),
  }));

  const projectOptions = projects?.map(({ name, projectId }: IObject) => ({
    label: name,
    value: projectId,
  }));

  return [
    {
      key: "createdBy",
      header: "Created by",
      thClassName: styles.thCreatedBy,
      filter: usersOptions
        ? {
            name: "users",
            options: usersOptions,
            searchPlaceholder: "Search names or emails",
            isClearFilterActive: true,
          }
        : undefined,
      renderer: ({ email, action }: IObject) => (
        <>
          <Link className={styles.link} to={routes.settings.accessManager}>
            {email}
          </Link>{" "}
          {action}
        </>
      ),
    },
    {
      key: "project",
      header: "Project",
      thClassName: styles.thResource,
      filter: {
        name: "resources",
        options: projectOptions,
        searchPlaceholder: "Search project name",
        isClearFilterActive: true,
      },
      renderer: ({ project }: IObject) => {
        const { projectId, name } = project || {};

        return projectId ? (
          <Link
            className={styles.link}
            to={`${routes.settings.projects}/${projectId}`}
          >
            {name}
          </Link>
        ) : (
          "Not available"
        );
      },
    },
    {
      key: "ip",
      header: "IP Address",
    },
    {
      key: "createdAt",
      header: "Date",
      renderer: ({ createdAt }: IObject) => (
        <>{moment(createdAt).format("MMM D, YYYY, h:mm A")}</>
      ),
    },
  ];
};
