import type { ChangeEvent } from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { Button, TextArea, TextField, Modal } from "nebula-galaxy";

import { routes } from "config";
import { useAlert } from "context";
import { addProject } from "services";

import { getFormValues } from "utils/functions";

import styles from "./projects.module.scss";

const initialFormData = {
  name: {
    value: "",
    valid: false,
  },
  details: {
    value: "",
    valid: false,
  },
};

interface AddProjectProps {
  queryKey: string;
  active: boolean;
  close: () => void;
}

const AddProject = ({ queryKey, active, close }: AddProjectProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();

  const [formData, setFormData] = useState({ ...initialFormData });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { name, details } = getFormValues(formData);

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const onSuccess = (message: string, projectId?: string) => {
    queryClient.invalidateQueries(queryKey);
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    close();
    setFormData({ ...initialFormData });
    setIsLoading(false);
    navigate(`${routes.settings.projects}/${projectId}`);
  };

  const onError = (message: string) => {
    console.error(message);
    setError(message);
    setIsLoading(false);
  };

  const submit = () => {
    setError("");
    setIsLoading(true);
    
    addProject({ name, details }, onSuccess, onError);
  };

  return (
    <Modal
      active={active}
      onClose={close}
      title="Add new project"
      actions={
        <>
          <Button variant="Tertiary" size="SM" onClick={close}>
            Cancel
          </Button>

          <Button size="SM" onClick={submit} loading={isLoading}>
            Add project
          </Button>
        </>
      }
    >
      <div className={styles.inputs}>
        <TextField
          label="Project name"
          name="name"
          value={name}
          onChange={handleOnChange}
          hasError={!!error}
          footnote={error}
          required
        />
        <TextArea
          label="Project description"
          name="details"
          value={details}
          onChange={handleOnChange}
          required
        />
      </div>
    </Modal>
  );
};

export default AddProject;
