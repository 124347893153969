import { useState } from "react";
import cn from "classnames";
import { Button, Checkbox, Modal, TextField } from "nebula-galaxy";

import styles from "../project-details.module.scss";

interface DeleteProjectProps {
  active: boolean;
  close: () => void;
  isLoading: boolean;
  submit: () => void;
  projectName: string;
}

const DeleteProject = ({
  active,
  close,
  isLoading,
  submit,
  projectName,
}: DeleteProjectProps) => {
  const [deleteProjectName, setDeleteProjectName] = useState("");
  const [isAgreementActive, setIsAgreementActive] = useState(false);

  return (
    <Modal
      className={styles.deleteProjectModal}
      active={active}
      onClose={close}
      variant="Standard"
      title={`Delete ${projectName}`}
      actions={
        <>
          <Button variant="Tertiary" size="SM" onClick={close}>
            Cancel
          </Button>

          <Button
            variant="Destructive"
            size="SM"
            onClick={submit}
            loading={isLoading}
            disabled={deleteProjectName !== projectName || !isAgreementActive}
          >
            Delete project
          </Button>
        </>
      }
      hideHeader
    >
      <div className={styles.modalHeader}>
        <span className={cn("p3 medium", styles.title)}>
          Delete {projectName}
        </span>
        <span className={cn("p5", styles.description)}>
          {projectName} will be permanently deleted. Members and teams will lose
          access to all shared resources. This action is irreversible. We
          recommend migrating all resources and data before proceeding. Please
          enter the project name to confirm.
        </span>
      </div>

      <TextField
        variant="Filled"
        label="Project name"
        name="project-name"
        value={deleteProjectName}
        onChange={({ target }) => setDeleteProjectName(target.value)}
        required
      />

      <Checkbox
        className={styles.agreement}
        onChange={({ target }) => setIsAgreementActive(target.checked)}
        label="I acknowledge that all resources, data, projects, and teams will be permanently deleted and I want to proceed."
      />
    </Modal>
  );
};

export default DeleteProject;
