import { IObject } from "nebula-galaxy";

import { api } from "config";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";
import { buckets, objects } from "utils/dummy";

export const fetchBuckets = async (body: IObject) => {
  return await api({ url: "storage/buckets/search", body });
};

export const fetchBucket = async (id: string) => {
  return await api({ url: `storage/buckets/${id}`, method: "GET" });
};

export const fetchMetrics = async (id: string) => {
  return await api({ url: `storage/buckets/${id}/metrics`, method: "GET" });
};

export const createBucket = async (
  body: IObject,
  onSuccess: (message: string, serverId?: string) => void,
  onError: (message: string) => void
) => {
  const { data, success, message } = await api({
    url: "storage/buckets",
    body,
  });

  success
    ? onSuccess(message, data?.serverId)
    : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const updateBucketAccess = async (
  bucketId: string,
  body: IObject,
  onSuccess: (message: string, serverId?: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `storage/buckets/${bucketId}/access`,
    method: "PATCH",
    body,
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const emptyBucket = async (
  bucketId: string,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `storage/buckets/${bucketId}/empty`,
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const deleteBucket = async (
  bucketId: string,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `storage/buckets/${bucketId}`,
    method: "DELETE",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const fetchObjects = async (bucketId: string, body: IObject) => {
  return await api({ url: `storage/buckets/${bucketId}/objects/search`, body });
};

export const fetchObject = async (bucketId: string, objectId: string) => {
  return await api({
    url: `storage/buckets/${bucketId}/objects/${objectId}`,
    method: "GET",
  });
};

export const createObject = async (
  bucketId: string,
  body: IObject,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `storage/buckets/${bucketId}/objects`,
    body,
    formData: true,
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const deleteObject = async (
  bucketId: string,
  objectId: string,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `storage/buckets/${bucketId}/objects/${objectId}`,
    method: "DELETE",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};
