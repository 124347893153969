import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import cn from "classnames";

import {
  Button,
  Divider,
  Icon,
  Radio,
  SelectEvent,
  StatusChip,
  TextField,
} from "nebula-galaxy";
import { FullScreenOverlay } from "components";

import { useAlert } from "context";
import { createBucket } from "services";
import { getFormValues, isFormValid } from "utils/functions";

import styles from "./create-bucket.module.scss";

const initialFormData = {
  bucketName: {
    value: "",
    valid: false,
  },
  publicAccess: {
    value: "disabled",
    valid: true,
  },
};

interface CreateBucketProps {
  providerId: string;
  queryKey: string;
  close: () => void;
  handleOnSuccess?: () => void;
}

const CreateBucket = ({
  providerId,
  queryKey,
  close,
  handleOnSuccess,
}: CreateBucketProps) => {
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();

  const [formData, setFormData] = useState({ ...initialFormData });
  const [isLoading, setIsLoading] = useState(false);
  const { bucketName, publicAccess } = getFormValues(formData);

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid: !!value,
      },
    });
  };

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    queryClient.invalidateQueries(queryKey);
    setFormData({ ...initialFormData });
    setIsLoading(false);
    close();
    handleOnSuccess?.();
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsLoading(false);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    const { publicAccess, ...formDataValues } = getFormValues(formData);
    createBucket(
      {
        ...formDataValues,
        isPrivate: publicAccess === "disabled",
        provider: providerId,
      },
      onSuccess,
      onError
    );
  };

  return (
    <FullScreenOverlay
      className={styles.createBucket}
      title="Create a bucket"
      handleClose={close}
    >
      <form onSubmit={submit} className={styles.bucketForm}>
        <div className={styles.bucketFormGroup}>
          <div className={styles.bucketFormHeader}>
            <div>
              <span className={cn("p4 medium", styles.formHeaderTitle)}>
                Choose a globally unique name.
              </span>
              <span className={cn("p5", styles.formHeaderDescription)}>
                We will be checking your bucket name against other bucket names
                on Nebula. For guidance on bucket naming conventions, please see
                the naming guidelines.
              </span>
            </div>
          </div>

          <TextField
            label="Bucket name"
            name="bucketName"
            value={bucketName}
            onChange={handleOnChange}
            customRegex={/[^\w\s\u00C0-\u017F]/u}
            required
          />
        </div>

        <div className={styles.bucketFormGroup}>
          <div className={styles.bucketFormHeader}>
            <div>
              <span className={cn("p4 medium", styles.formHeaderTitle)}>
                Control access to objects
              </span>
              <span className={cn("p5", styles.formHeaderDescription)}>
                We recommend disabling public access to your buckets to prevent
                unauthorized access to your store objects.
              </span>
            </div>

            {/* <Button
              className={styles.formHeaderHelp}
              variant="TertiaryIcon"
              size="SM"
            >
              <Icon name="CircleHelp" />
            </Button> */}
          </div>

          <div className={styles.inputsWrapper}>
            <Radio
              name="publicAccess"
              label={
                <span className={cn("p5", styles.radioLabel)}>
                  Enable public access
                </span>
              }
              value="enabled"
              onChange={handleOnChange}
            />
            <Radio
              name="publicAccess"
              label={
                <div className={styles.disableAccessLabel}>
                  <span className={cn("p5", styles.radioLabel)}>
                    Disable public access
                  </span>
                  <StatusChip
                    variant="Informational"
                    text="RECOMMENDED"
                    size="SM"
                  />
                </div>
              }
              value="disabled"
              checked={publicAccess === "disabled"}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <Divider className={styles.divider} />

        <div className={styles.bucketFormGroup}>
          <div className={styles.bucketFormHeader}>
            <div>
              <div className={styles.formHeaderTitle}>
                <span className="p4 medium">Bucket versioning</span>

                <StatusChip
                  variant="Informational"
                  text="COMING SOON"
                  size="SM"
                />
              </div>
              <span className={cn("p5", styles.formHeaderDescription)}>
                Enable bucket versioning to keep multiple versions of the same
                object in your storage.
              </span>
            </div>

            {/* <Button
              className={styles.formHeaderHelp}
              variant="TertiaryIcon"
              size="SM"
            >
              <Icon name="CircleHelp" />
            </Button> */}
          </div>
        </div>

        <div className={styles.bucketFormActions}>
          <Button
            size="SM"
            type="submit"
            loading={isLoading}
            disabled={!isFormValid(formData)}
          >
            Create bucket
          </Button>
        </div>
      </form>
    </FullScreenOverlay>
  );
};

export default CreateBucket;
