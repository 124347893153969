import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import cn from "classnames";

import {
  Button,
  CopyElement,
  DropdownMenu,
  IObject,
  Icon,
  Modal,
  Popper,
  Table,
} from "nebula-galaxy";

import { FileUpload } from "components";
import {
  createObject,
  deleteObject,
  fetchMetrics,
  fetchObjects,
} from "services";
import { routes } from "config";
import { formatFileSize, pluralize } from "utils/functions";
import { useAlert } from "context";
import { useReactQuery } from "hooks";
import { QueryParams } from "utils/types";
import { defaultQueryParams } from "utils/consts";

import { objectsColumn } from "../columns";

import styles from "./bucket-details.module.scss";

interface ObjectsProps {
  bucketId: string;
  setIsUploading: (e: boolean) => void;
}

const metricsQueryKey = "bucket-metrics";
const objectsQueryKey = "bucket-objects";

const Objects = ({ bucketId, setIsUploading }: ObjectsProps) => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [isDeletingObject, setIsDeletingObject] = useState(false);
  const [selectedObject, setSelectedObject] = useState<IObject>();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    ...defaultQueryParams,
  });

  const { data: metrics, isLoading: isLoadingMetrics } = useReactQuery({
    queryKey: metricsQueryKey,
    queryFunction: () => fetchMetrics(bucketId as string),
  });

  const { data: objects, isLoading: isLoadingObjects } = useReactQuery({
    queryKey: objectsQueryKey,
    queryParams,
    queryFunction: () => fetchObjects(bucketId as string, queryParams),
  });

  const { totalObjects, totalFileSize, averageFileSize } = metrics || {};

  const onSuccess = (message: string) => {
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsUploading(false);
  };

  const handleFileUpload = (formData: FormData) => {
    setIsUploading(true);

    createObject(bucketId, formData, onSuccess, onError);
  };

  const handleObjectActions = async (action: string, object: IObject) => {
    const objectUrl = object?.location;
    setSelectedObject(object);

    if (action === "copy") {
      await navigator.clipboard.writeText(objectUrl);

      showAlert({
        variant: "Success",
        message: "Copied to clipboad",
        active: true,
      });
    } else if (action === "open" || action === "download") {
      window.location = objectUrl;
    } else if (action === "delete") {
      handleDeleteObject(object);
    }
  };

  const handleDeleteObject = (object?: IObject) => {
    setIsDeletingObject(true);

    deleteObject(
      bucketId as string,
      (object || selectedObject)?.objectId,
      onSuccess,
      onError
    );
  };

  return (
    <div className={styles.objects}>
      <div className={styles.objectsMeta}>
        <div>
          <span className={cn("cap1", styles.metaTitle)}>Total objects</span>
          <span className={cn("p2 medium", styles.metaValue)}>
            {totalObjects?.toLocaleString() || "-"}
          </span>
        </div>
        <div>
          <span className={cn("cap1", styles.metaTitle)}>Avg. object size</span>
          <span className={cn("p2 medium", styles.metaValue)}>
            {formatFileSize(averageFileSize)}
          </span>
        </div>
        <div>
          <span className={cn("cap1", styles.metaTitle)}>
            Total bucket size
          </span>
          <span className={cn("p2 medium", styles.metaValue)}>
            {formatFileSize(totalFileSize)}
          </span>
        </div>
      </div>

      <Table
        className={styles.objectsTable}
        columns={objectsColumn}
        data={objects?.docs}
        pagination={objects?.pagination}
        loading={isLoadingObjects}
        loadData={setQueryParams}
        toolbarActions={
          <>
            {/* <CopyElement
              className={styles.copyInstanceId}
              element={
                <Button
                  size="SM"
                  rightIcon="Copy"
                  variant="Secondary"
                  onClick={() => {}}
                >
                  Copy URL
                </Button>
              }
              value={url}
              showIcon={false}
            /> */}

            <FileUpload
              id="upload-objects"
              text="Add objects"
              onUpload={handleFileUpload}
            />
          </>
        }
        onRowClick={({ objectId }) =>
          navigate(`${routes.console.nebStore}/${bucketId}/objects/${objectId}`)
        }
        rowPopperActions={(object) => (
          <DropdownMenu
            className={styles.actionMenu}
            name="action"
            options={[
              {
                label: "Copy URL",
                value: "copy",
              },
              {
                label: "Open",
                value: "open",
              },
              {
                label: "Download",
                value: "download",
              },
              {
                label: "Delete",
                value: "delete",
              },
            ]}
            onChange={({ target }) => {
              handleObjectActions(target.value as string, object);
            }}
          />
        )}
        searchPlaceholder="Search object or folder name"
        dataText={pluralize("object", objects?.length)}
        isMultiSelect={false}
      />

      <Modal
        active={isDeleteModalActive}
        onClose={() => setIsDeleteModalActive(false)}
        variant="Warning"
        title="Delete object"
        actions={
          <>
            <Button
              variant="Tertiary"
              size="SM"
              onClick={() => setIsDeleteModalActive(false)}
            >
              Cancel
            </Button>

            <Button
              variant="Destructive"
              size="SM"
              onClick={() => handleDeleteObject()}
              loading={isDeletingObject}
            >
              Delete object
            </Button>
          </>
        }
      >
        <span className="p5">
          {selectedObject?.objectKey} will be permanently removed from your
          bucket. Are you sure you want to proceed?
        </span>
      </Modal>
    </div>
  );
};

export default Objects;
