import cn from "classnames";
import { PaystackButton } from "react-paystack";

import styles from "./paystack.module.scss";

interface PaystackProps {
  amount: number;
  email: string;
  onSuccess: (ref: any) => void;
  onClose: () => void;
  disabled: boolean;
  text: string;
}

const Paystack = ({
  amount,
  email,
  onSuccess,
  onClose,
  disabled,
  text,
}: PaystackProps) => {
  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email,
    amount,
    channels: ["card"],
    publicKey: process.env.REACT_APP_PAYSTACK_KEY as string,
  };

  const handlePaystackSuccessAction = (response: any) => {
    onSuccess(response);
  };

  const handlePaystackCloseAction = () => {
    onClose();
  };

  return (
    <div className={styles.paystack}>
      <PaystackButton
        {...paystackConfig}
        className={cn(styles.button, {
          [styles.buttonDisabled]: disabled,
        })}
        text={text}
        onSuccess={handlePaystackSuccessAction}
        onClose={handlePaystackCloseAction}
      />
    </div>
  );
};

export default Paystack;
