import { ChangeEvent, FormEvent } from "react";
import cn from "classnames";

import {
  Select,
  Divider,
  SelectEvent,
  Radio,
  IObject,
  TextField,
} from "nebula-galaxy";
import { Button } from "nebula-galaxy";
import { Accordion, Carousel } from "components";

import {
  generateSoftwareImageOptions,
  getFormValues,
  getOSLogo,
  isFormValid,
} from "utils/functions";

import styles from "./create-instance.module.scss";

interface ConfigurationProps {
  computeDetails: IObject;
  formData: IObject;
  submit: (e: FormEvent<HTMLFormElement>) => void;
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid?: boolean
  ) => void;
  // handleVolumes: (action: "add" | "remove", index?: number) => void;
}

const Configuration = ({
  computeDetails,
  formData,
  submit,
  handleOnChange,
}: ConfigurationProps) => {
  const { os, instance } = computeDetails || {};
  const { serverName, operatingSystem, osId, noOfInstance, instanceId } =
    getFormValues(formData);

  return (
    <form onSubmit={submit} className={styles.instanceForm}>
      <TextField
        label="Instance name"
        name="serverName"
        value={serverName}
        onChange={handleOnChange}
        customRegex={/[^\w\s\u00C0-\u017F]/u}
        required
      />

      <div className={styles.instanceFormHeader}>
        <div>
          <span className={cn("p4 medium", styles.formHeaderTitle)}>
            Choose software image
          </span>
          <span className={cn("p5", styles.formHeaderDescription)}>
            Select the option that best suits your needs, whether it's a
            specific application environment or a preferred operating system
            configuration.
          </span>
        </div>

        {/* <Button
          className={styles.formHeaderHelp}
          variant="TertiaryIcon"
          size="SM"
        >
          <Icon name="CircleHelp" />
        </Button> */}
      </div>

      <Carousel
        title="Choose option"
        items={os?.map(({ _id, name }: IObject) => (
          <button
            key={`os-${_id}`}
            className={cn(styles.instanceOS, {
              [styles.instanceOSActive]: _id === operatingSystem,
            })}
            onClick={() =>
              handleOnChange(
                {
                  target: { name: "operatingSystem", value: _id },
                },
                true
              )
            }
            type="button"
          >
            <img src={getOSLogo(name)} alt={`${name} logo`} />
            <span className={cn("p5 medium", styles.osTitle)}>{name}</span>
          </button>
        ))}
      />

      <Select
        label="Operating system"
        placeholder="Choose option"
        name="osId"
        value={osId}
        onChange={handleOnChange}
        options={generateSoftwareImageOptions(operatingSystem, os)}
        required
      />

      <Divider className={styles.divider} />

      <div className={styles.instanceFormHeader}>
        <div>
          <span className={cn("p4 medium", styles.formHeaderTitle)}>
            Choose instance type
          </span>
          <span className={cn("p5", styles.formHeaderDescription)}>
            Specify the computing power needed for your instance. Determine the
            CPU and memory resources required to efficiently run your
            applications and workloads.
          </span>
        </div>

        {/* <Button
          className={styles.formHeaderHelp}
          variant="TertiaryIcon"
          size="SM"
        >
          <Icon name="CircleHelp" />
        </Button> */}
      </div>

      <div className={cn(styles.inputsWrapper, styles.noMargin)}>
        <TextField
          label="No of instance"
          name="noOfInstance"
          onChange={handleOnChange}
          value={noOfInstance}
          required
          disabled
        />
      </div>

      <div className={styles.instanceOptions}>
        {/* <div className={styles.instanceOptionsHeader}>
          {instanceOptions.map(({ title, id }) => (
            <button
              key={`instance-${id}`}
              className={cn(styles.instance, {
                [styles.instanceActive]: instance === id,
              })}
              onClick={() =>
                handleOnChange({ target: { name: "instance", value: id } })
              }
              type="button"
            >
              {title}
            </button>
          ))}
        </div> */}

        <Accordion
          items={renderInstanceOptions({
            instanceOptions: instance,
            instanceId,
            handleOnChange,
          })}
        />
      </div>

      {/* <div className={styles.instanceFormHeader}>
        <div>
          <span className={cn("p4 medium", styles.formHeaderTitle)}>
            Configure storage
          </span>
          <span className={cn("p5", styles.formHeaderDescription)}>
            Specify the storage options for the instance.
          </span>
        </div>

        <Button
          className={styles.formHeaderHelp}
          variant="TertiaryIcon"
          size="SM"
        >
          <Icon name="CircleHelp" />
        </Button>
      </div>

      <div className={styles.configureStorage}>
        {volumes.map((volume: string | number, index: number) => (
          <div>
            <span className={cn("p5", styles.configureStorageText)}>1 X</span>
            <TextField
              label="Volume"
              name="volume"
              value={volume}
              onChange={() => null}
              disabled={index === 0}
              className={styles.configureStorageVolume}
            />
            <span className={cn("p5", styles.configureStorageText)}>GiB</span>
            <div className={styles.configureStorageAction}>
              {index === 0 ? (
                <span className="cap1" style={{ paddingRight: "12px" }}>
                  Root volume
                </span>
              ) : (
                <Button
                  variant="TertiaryIcon"
                  size="SM"
                  onClick={() => handleVolumes("remove", index)}
                >
                  <Icon name="Minus" />
                </Button>
              )}
            </div>
          </div>
        ))}

        <Button
          variant="Tertiary"
          size="SM"
          leftIcon="Plus"
          onClick={() => handleVolumes("add")}
        >
          Add volume
        </Button>
      </div> */}

      <div className={styles.instanceFormActions}>
        <Button
          size="SM"
          type="submit"
          disabled={
            !isFormValid({
              serverName: formData?.serverName,
              osId: formData?.osId,
              instanceId: formData?.instanceId,
            })
          }
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

export const renderInstanceOptions = ({
  instanceOptions,
  instanceId: currentInstanceId,
  handleOnChange,
}: {
  instanceOptions: IObject[];
  instanceId: string;
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid?: boolean
  ) => void;
}) => {
  return instanceOptions?.map(
    ({ cost, instanceId, vCPU, memory, bandwidth }: IObject) => ({
      header: (
        <div className={styles.featureHeader}>
          <Radio
            name="instanceId"
            value={instanceId}
            checked={currentInstanceId === instanceId}
            onChange={handleOnChange}
          />
          <span className="p5">
            {instanceId} - ₦{cost} per hour
          </span>
        </div>
      ),
      content: (
        <div className={styles.featureContent}>
          <div className={styles.featureItem}>
            <span className={cn("p5", styles.featureTitle)}>vCPU</span>
            <span className={cn("p5", styles.featureValue)}>{vCPU}</span>
          </div>
          <div className={styles.featureItem}>
            <span className={cn("p5", styles.featureTitle)}>Memory</span>
            <span className={cn("p5", styles.featureValue)}>{memory} GiB</span>
          </div>
          <div className={styles.featureItem}>
            <span className={cn("p5", styles.featureTitle)}>Bandwidth</span>
            <span className={cn("p5", styles.featureValue)}>{bandwidth}</span>
          </div>
        </div>
      ),
    })
  );
};

export default Configuration;
