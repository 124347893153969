import type { ChangeEvent } from "react";
import { useEffect, useMemo, useState } from "react";

import type {
  IObject,
  SelectValue,
} from "nebula-galaxy";
import { Stepper } from "nebula-galaxy";

import { OnboardingLayout } from "layouts";

import OnboardingContact from "./contact";
import OnboardingProject from "./project";
import OnboardingBilling from "./billing";

import { useAlert, useAuth } from "context";
import type { OnboardingForm } from "utils/types";
import { getFormValues, getLocalUser } from "utils/functions";
import { initialFormData } from "utils/data";

import styles from "./onboarding.module.scss";

const Onboarding = () => {
  const { user } = getLocalUser();
  const { authenticate, error } = useAuth();
  const { showAlert, clearAlert } = useAlert();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [formData, setFormData] = useState<IObject>({ ...initialFormData });
  const [accountType, setAccountType] = useState("");
  const [paymentRef, setPaymentRef] = useState("");

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const goNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const clearFormData = (type: OnboardingForm) => {
    setFormData({
      ...formData,
      [type]: {
        ...initialFormData[type],
      },
    });
  };

  const handleOnChange = (
    {
      target,
    }:
      | ChangeEvent<HTMLInputElement & { countryCode?: string }>
      | {
          target: {
            name: string;
            value?: SelectValue;
            checked?: boolean;
            countryCode?: string;
          };
        },
    valid: boolean,
    type: OnboardingForm
  ) => {
    const { name, value, countryCode } = target;

    setFormData({
      ...formData,
      [type]: {
        ...formData[type],
        [name as string]: {
          value: value as string,
          valid,
        },
        ...(countryCode
          ? { phoneNumberCountryCode: { value: countryCode, valid: true } }
          : {}),
      },
    });
  };

  const submit = () => {
    const { userId } = user || {};
    const {
      country,
      state,
      city,
      address,
      postalCode,
      phoneNumberCountryCode,
      phoneNumber,
      ...contactFormValues
    } = getFormValues(formData.contact);
    const projectFormValues = getFormValues(formData.project);
    const {
      billingCountry,
      billingState,
      billingCity,
      billingAddress,
      billingPostalCode,
      ...billingFormValues
    } = getFormValues(formData.billing);

    authenticate("auth/accounts/verify", {
      ...contactFormValues,
      phoneNumber: `${phoneNumberCountryCode}${phoneNumber}`,
      userId,
      accountType,
      physicalAddress: {
        country,
        state,
        city,
        address,
        postalCode,
      },
      ...projectFormValues,
      billing: {
        ...billingFormValues,
        paymentRef,
        paymentProvider: "paystack",
        billingAddress: {
          country: billingCountry || country,
          state: billingState || state,
          city: billingCity || city,
          address: billingAddress || address,
          postalCode: billingPostalCode || postalCode,
        },
      },
    });
  };

  useEffect(() => {
    error
      ? showAlert({
          active: true,
          variant: "Critical",
          title: "Onboarding error",
          message: error,
        })
      : clearAlert();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const steps = useMemo(() => {
    return [
      {
        title: "Contact",
        component: (
          <OnboardingContact
            clearFormData={clearFormData}
            nextStep={goNext}
            formData={formData.contact}
            handleOnChange={(e, valid) => handleOnChange(e, valid, "contact")}
            setAccountType={setAccountType}
          />
        ),
      },
      {
        title: "Project",
        component: (
          <OnboardingProject
            previousStep={goBack}
            nextStep={goNext}
            formData={formData.project}
            handleOnChange={(e, valid) => handleOnChange(e, valid, "project")}
            paymentRef={paymentRef}
            setPaymentRef={setPaymentRef}
          />
        ),
      },
      {
        title: "Billing",
        component: (
          <OnboardingBilling
            previousStep={goBack}
            nextStep={submit}
            formData={formData.billing}
            handleOnChange={(e, valid) => handleOnChange(e, valid, "billing")}
          />
        ),
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, formData, accountType, paymentRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <OnboardingLayout isBannerActive={false} isContentLogoActive>
      <Stepper
        className={styles.stepper}
        steps={steps.map(({ title }) => title)}
        currentStep={currentStep}
        isLabelHidden
      />

      {steps[currentStep].component}
    </OnboardingLayout>
  );
};

export default Onboarding;
