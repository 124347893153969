import { ReactNode } from "react";
import cn from "classnames";

import styles from "./empty-state.module.scss";

interface EmptyStateProps {
  icon?: ReactNode;
  title: string;
  description?: string;
  action?: ReactNode;
}

const EmptyState = ({ icon, title, description, action }: EmptyStateProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <figure className={styles.icon}>{icon}</figure>
        <div>
          <span className={cn("p3 medium", styles.title)}>{title}</span>
          {description && (
            <span className={cn("p5", styles.description)}>{description}</span>
          )}
        </div>
        {action && <div>{action}</div>}
      </div>
    </div>
  );
};

export default EmptyState;
