import { useState } from "react";
import cn from "classnames";
import moment from "moment";

import { Button, ButtonGroup, Table } from "nebula-galaxy";
import { useReactQuery } from "hooks";
import { fetchMembers, fetchUsersLogs } from "services";
import { useProjects } from "context";

import type { QueryParams } from "utils/types";
import { dateFilters, defaultQueryParams } from "utils/consts";
import { pluralize } from "utils/functions";

import { logsColumns } from "./columns";

import styles from "./logs.module.scss";

const queryKey = "user-logs";

const UserLogs = () => {
  const { projects } = useProjects();

  const [currentDateFilter, setCurrentDateFilter] = useState(
    dateFilters[0].value
  );
  const [queryParams, setQueryParams] = useState<QueryParams>({
    ...defaultQueryParams,
    startDate: moment()
      .subtract(currentDateFilter, "days")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const { data, isLoading } = useReactQuery({
    queryKey,
    queryParams,
    queryFunction: () => fetchUsersLogs(queryParams),
  });
  const { data: membersData } = useReactQuery({
    queryKey: "members",
    queryFunction: () => fetchMembers(),
  });

  const handleDateFilter = (days: number) => {
    setCurrentDateFilter(days);
    setQueryParams({
      ...queryParams,
      startDate: moment().subtract(days, "days").format("YYYY-MM-DD"),
    });
  };

  return (
    <Table
      className={styles.logs}
      columns={logsColumns({ users: membersData?.docs, projects })}
      data={data?.docs}
      loading={isLoading}
      pagination={data?.pagination}
      loadData={setQueryParams}
      toolbarActions={
        <ButtonGroup variant="Neutral" size="SM">
          {dateFilters.map(({ label, value }) => (
            <Button
              key={`date-filter-${value}`}
              className={cn({
                "button--is-active": value === currentDateFilter,
              })}
              variant="GroupButton"
              onClick={() => {
                handleDateFilter(value);
              }}
              type="button"
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      }
      searchPlaceholder="Search by name or email"
      dataText={pluralize("record", data?.docs?.length)}
      isMultiSelect={false}
    />
  );
};

export default UserLogs;
