export const routes = {
  auth: {
    signIn: "/sign-in",
    signUp: "/waitlist-signup-2024",
    forgotPassword: "/forgot-password",
    reset: "/reset/:code",
    resetPassword: "/reset-password/:code",
    verifyEmail: "/verify-email",
    verifyIdentity: "/verify-identity",
    acceptInvite: "/accept-invite",
  },
  onboarding: {
    home: "/onboarding",
  },
  console: {
    home: "/",
    // nebCompute: "/neb-compute",
    // nebComputeDetails: "/neb-compute/:id",
    nebStore: "/neb-store",
    nebStoreDetails: "/neb-store/:id",
    nebStoreObjectDetails: "/neb-store/:bucketId/objects/:objectId",
    nebCompute: {
      instances: "/neb-compute/instances",
      instanceDetails: "/neb-compute/instances/:id",
      vpcs: "/neb-compute/vpcs",
      vpcDetails: "/neb-compute/vpcs/:id",
      subnets: "/neb-compute/vpcs/subnets",
      subnetDetails: "/neb-compute/vpcs/subnets/:id",
      keyPairs: "/neb-compute/key-pairs",
    //   blueprints: "/neb-compute/blueprints",
    //   securityGroups: "/neb-compute/security-groups",
    },
    // database: "/database",
  },
  settings: {
    company: "/settings/company",
    projects: "/settings/projects",
    projectDetails: "/settings/projects/:id",
    billing: "/settings/billing",
    accessManager: "/settings/access-manager",
    accessManagerMember: "/settings/access-manager/:id",
    profile: "/settings/profile",
    logs: "/settings/logs",
  },
};
