import { IObject } from "nebula-galaxy";

import { api } from "config";
import { DEFAULT_ERROR_MESSAGE } from "utils/consts";

export const updateProfile = async (
  body: IObject,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `users/profile`,
    body,
    method: "PUT",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const changePassword = async (
  body: IObject,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `users/profile/password`,
    body,
    method: "PUT",
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};

export const deleteProfile = async (
  body: IObject,
  onSuccess: (message: string) => void,
  onError: (message: string) => void
) => {
  const { success, message } = await api({
    url: `users/profile`,
    method: "DELETE",
    body,
  });

  success ? onSuccess(message) : onError(message || DEFAULT_ERROR_MESSAGE);
};
