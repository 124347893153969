import { ReactNode, useEffect } from "react";
import cn from "classnames";
import { Icon } from "nebula-galaxy";

import styles from "./page-loader.module.scss";

interface PageLoaderProps {
  title?: ReactNode;
  message?: ReactNode;
  isMini?: boolean;
}

const PageLoader = ({ title, message, isMini }: PageLoaderProps) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div className={cn(styles.container, { [styles.containerMini]: isMini })}>
      <Icon name="Loader" size="LG" />
      <div className={styles.content}>
        {title && (
          <span className={cn("p4 medium", styles.title)}>{title}</span>
        )}
        {message && <span className={cn("p5", styles.message)}>{message}</span>}
      </div>
    </div>
  );
};

export default PageLoader;
