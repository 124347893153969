import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import { Button, TextField, Callout } from "nebula-galaxy";

import { useAlert } from "context";
import { OnboardingLayout } from "layouts";
import { api, routes } from "config";
import { getFormValues, isFormValid } from "utils/functions";

import styles from "../auth.module.scss";

const initialFormData = {
  email: {
    value: "",
    valid: false,
  },
};

const ForgotPassword = () => {
  const { showAlert } = useAlert();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [error, setError] = useState("");

  const { email } = getFormValues(formData);

  const handleOnChange = (
    { target }: ChangeEvent<HTMLInputElement>,
    valid?: boolean
  ) => {
    const { name, value } = target;

    setFormData({
      ...formData,
      [name]: {
        value,
        valid,
      },
    });
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError("");
    setIsLoading(true);

    const { success, message } = await api({
      url: "auth/password-resets",
      body: { email },
      isAuthRequired: false
    });

    if (success) {
      showAlert({
        active: true,
        variant: "Success",
        title: "Recovery email sent",
        message:
          "If an account with that email exists, you will receive an email with instructions on how to reset your password.",
        isMultiLine: true,
      });

      setFormData({ ...initialFormData });
    } else {
      console.error(message);
      setError(message);
    }

    setIsLoading(false);
  };

  return (
    <OnboardingLayout isBannerActive={false} isContentLogoActive>
      <div className={styles.header}>
        <h5 className={cn("medium", styles.title)}>Recover password</h5>
        <span className={cn("p5", styles.description)}>
          Please enter your email address. We will send you a link to reset your
          password.
        </span>
      </div>
      <form onSubmit={submit}>
        <div className={styles.form}>
          {error && (
            <Callout
              title=""
              variant="Critical"
              message={error}
              isCloseVisible={false}
            />
          )}

          <TextField
            label="Email"
            placeholder="email@example.com"
            name="email"
            type="email"
            value={email}
            onChange={handleOnChange}
            required
          />

          <Button
            size="LG"
            type="submit"
            loading={isLoading}
            disabled={!isFormValid(formData)}
          >
            Send me a recovery link
          </Button>

          <div className={styles.alternative}>
            <span className="p5">Return to</span>
            <Link to={routes.auth.signIn}>
              <Button variant="PrimaryLinkButton" size="MD">
                Sign in
              </Button>
            </Link>
          </div>
        </div>
      </form>
    </OnboardingLayout>
  );
};

export default ForgotPassword;
