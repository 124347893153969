import { ReactNode, useState } from "react";
import cn from "classnames";

import styles from "./accordion.module.scss";
import { Button, Icon } from "nebula-galaxy";

type AccordionVariant = "Filled" | "Outlined";

interface AccordionItemProps {
  itemClassName?: string;
  header: ReactNode;
  content: ReactNode;
  disabled?: boolean;
}

interface AccordionProps {
  className?: string;
  itemClassName?: string;
  variant?: AccordionVariant;
  items?: AccordionItemProps[];
}

const Accordion = ({
  className,
  itemClassName,
  variant = "Filled",
  items,
}: AccordionProps) => {
  return (
    <div className={className}>
      {items?.map(({ header, content, disabled }, index) => (
        <AccordionItem
          key={index}
          itemClassName={itemClassName}
          variant={variant}
          header={header}
          content={content}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

const AccordionItem = ({
  itemClassName,
  variant,
  header,
  content,
  disabled,
}: AccordionItemProps & { variant: AccordionVariant }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={cn(
        itemClassName,
        styles.itemContainer,
        styles[`itemContainer${variant}`],
        {
          [styles.itemContainerDisabled]: disabled,
        }
      )}
    >
      {variant === "Outlined" && (
        <span className={cn(styles.icon, { [styles.iconIsOpen]: isOpen })}>
          <Icon size="SM" name="ChevronRight" />
        </span>
      )}

      <div className={styles.item}>
        <div className={styles.header} onClick={toggleAccordion}>
          {header}

          {variant === "Filled" && (
            <Button variant="TertiaryIcon" size="SM" className={styles.icon}>
              <Icon name={isOpen ? "Minus" : "Plus"} />
            </Button>
          )}
        </div>
        {isOpen && <div className={styles.content}>{content}</div>}
      </div>
    </div>
  );
};

export default Accordion;
