import cn from "classnames";
import { Button } from "nebula-galaxy";
import { useAuth } from "context";

import styles from "./github-button.module.scss";

const GithubButton = () => {
  const { isLoadingSocial, socialAuthenticate, socialType } = useAuth();

  return (
    <Button
      className={cn(styles.githubButton, styles.githubButtonLoading)}
      leftIcon="Github"
      variant="Secondary"
      size="LG"
      type="button"
      loading={socialType === 'github' && isLoadingSocial}
      onClick={() => socialAuthenticate("auth/session/github", 'github')}
    >
      Continue with Github
    </Button>
  );
};

export default GithubButton;
