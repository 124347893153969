import cn from "classnames";

import { MainLayout } from "layouts";
import { DetailsPageContent } from "components";
import { useAuth } from "context";

import Features from "./features";
import MigrationGuides from "./migration-guides";
import AdditionalResources from "./additional-resources";

import styles from "./overview.module.scss";

const Home = () => {
  const { userProfile } = useAuth();

  const { fullName } = userProfile || {};

  return (
    <MainLayout className={styles.overview}>
      <DetailsPageContent className={styles.container}>
        <h5 className={cn("medium", styles.header)}>Welcome {fullName}</h5>

        <Features />

        <span className={cn("p3 medium", styles.header)}>Helpful resources</span>
        <MigrationGuides />

        <span className={cn("p3 medium", styles.header)}>
          Additional resources
        </span>
        <AdditionalResources />
      </DetailsPageContent>
    </MainLayout>
  );
};

export default Home;
