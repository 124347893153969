import { Link } from "react-router-dom";
import moment from "moment";
import type { IObject, SelectEvent } from "nebula-galaxy";
import { Button, Icon, Select, StatusChip } from "nebula-galaxy";

import { routes } from "config";
import { generateSelectOptions, getRole } from "utils/functions";
import { statusMap } from "utils/data";

import styles from "./access-manager.module.scss";

export const membersColumns = [
  { key: "fullName", header: "Name" },
  { key: "email", header: "Email" },
  {
    key: "project",
    header: "Project",
    renderer: ({ projects }: IObject) => (
      <div className={styles.projects}>
        <Link
          onClick={(e) => e.stopPropagation()}
          to={`${routes.settings.projects}/${projects?.[0]?.projectId}`}
        >
          <Button variant="PrimaryLinkButton" size="MD">
            {projects?.[0]?.name}
          </Button>
        </Link>

        {projects?.length > 1 && (
          <span className="cap1">+{projects.length - 1} others</span>
        )}
      </div>
    ),
  },
  {
    key: "lastLogin",
    header: "Last Login",
    renderer: ({ lastLogin }: IObject) => (
      <>{moment(lastLogin).format("MMM D, YYYY, h:mm A")}</>
    ),
  },
  {
    key: "dateCreated",
    header: "Date Created",
    renderer: ({ createdAt }: IObject) => (
      <>{moment(createdAt).format("MMM D, YYYY, h:mm A")}</>
    ),
  },
];

export const invitedUsersColumns = [
  { key: "email", header: "Email" },
  {
    key: "role",
    header: "Role",
    renderer: ({ role }: IObject) => getRole(role),
  },
  {
    key: "project",
    header: "Project",
    renderer: ({ project }: IObject) => (
      <Link
        onClick={(e) => e.stopPropagation()}
        to={`${routes.settings.projects}/${project?.projectId}`}
      >
        <Button variant="PrimaryLinkButton" size="MD">
          {project?.name}
        </Button>
      </Link>
    ),
  },
  {
    key: "invitationStatus",
    header: "Status",
    renderer: ({ invitationStatus }: IObject) => (
      <StatusChip
        variant={statusMap[invitationStatus]}
        text={invitationStatus}
      />
    ),
  },
  {
    key: "dateCreated",
    header: "Date Created",
    renderer: ({ createdAt }: IObject) => (
      <>{moment(createdAt).format("MMM D, YYYY, h:mm A")}</>
    ),
  },
];

export const inviteMembersColumns = (
  data: {
    projects: IObject[];
    roles: IObject[];
  },
  handleOnChange: (e: SelectEvent, id: number) => void,
  removeEmail: (id: number) => void
) => [
  { key: "email", header: "Recipient" },
  {
    key: "add-to",
    header: "Add to",
    renderer: ({ id }: IObject) => (
      <Select
        className={styles.select}
        name="projectId"
        variant="SimpleFilled"
        placeholder="Select project"
        options={generateSelectOptions(data.projects, "projectId", "name")}
        onChange={(e) => handleOnChange(e, id)}
        isSearchable
      />
    ),
  },
  {
    key: "add-as",
    header: "Add as",
    renderer: ({ id }: IObject) => (
      <div className={styles.addAs}>
        <Select
          className={styles.select}
          name="role"
          variant="SimpleFilled"
          placeholder="Select project role"
          options={generateSelectOptions(data.roles, "id", "name")}
          onChange={(e) => handleOnChange(e, id)}
        />
        <span className={styles.divider} />
        <Button
          variant="TertiaryIcon"
          size="SM"
          onClick={() => removeEmail(id)}
        >
          <Icon name="Minus" />
        </Button>
      </div>
    ),
  },
];

export const memberProjectsColumn = (
  data: {
    roles: IObject[];
  },
  handleOnChange: (e: SelectEvent, id: string) => void,
  removeFromProject: (e: IObject) => void
) => [
  {
    key: "name",
    header: "Projects",
    renderer: ({ project }: IObject) => (
      <Link to={`${routes.settings.projects}/${project.projectId}`}>
        <Button variant="PrimaryLinkButton" size="MD">
          {project.name}
        </Button>
      </Link>
    ),
  },
  {
    key: "role",
    header: "",
    renderer: ({ role, project }: IObject) => (
      <div className={styles.addAs}>
        <Select
          className={styles.select}
          name="role"
          value={role.name}
          variant="SimpleFilled"
          placeholder="Select project role"
          options={generateSelectOptions(data.roles, "id", "name")}
          onChange={(e) => handleOnChange(e, project.projectId)}
        />
      </div>
    ),
  },
  {
    key: "action",
    header: "",
    renderer: ({ project }: IObject) => (
      <div className={styles.action}>
        <Button
          variant="TertiaryIcon"
          size="SM"
          onClick={() => removeFromProject(project)}
        >
          <Icon name="X" />
        </Button>
      </div>
    ),
  },
];
