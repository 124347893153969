import "nebula-galaxy/dist/styles/index.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import Nebula from "nebula";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Nebula />
  </React.StrictMode>
);
