import { IObject } from "nebula-galaxy";
import { useQuery } from "react-query";

export const useReactQuery = ({
  queryKey,
  queryParams,
  queryFunction,
}: {
  queryKey: string;
  queryParams?: IObject;
  queryFunction: () => Promise<any> | void;
}) => {
  const {
    data: queryData,
    isLoading,
    isFetching,
    isRefetching,
  } = useQuery([queryKey, queryParams], queryFunction, {
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0,
  });

  const { data, success, message } = queryData || {};

  return {
    isLoading: isLoading || isFetching || isRefetching,
    data,
    success,
    message,
  };
};
