import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import cn from "classnames";

import type { SelectEvent } from "nebula-galaxy";
import { Button, Checkbox, Divider, Select, TextField } from "nebula-galaxy";

import { useAuth } from "context";
import { FormObject } from "utils/types";
import {
  generateCountriesOptions,
  generateStatesOptions,
  getFormValues,
  isFormValid,
} from "utils/functions";

import styles from "../onboarding.module.scss";

interface OnboardingBillingProps {
  previousStep: () => void;
  nextStep: () => void;
  formData: FormObject;
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid: boolean
  ) => void;
}

const OnboardingBilling = ({
  previousStep,
  nextStep,
  formData,
  handleOnChange,
}: OnboardingBillingProps) => {
  const { isLoading } = useAuth();
  const [isAddressDifferent, setIsAddressDifferent] = useState(false);

  const {
    cardName,
    billingCountry,
    billingState,
    billingCity,
    billingAddress,
    billingPostalCode,
  } = getFormValues(formData);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <form onSubmit={submit}>
      <h5 className={cn("medium", styles.title)}>Add billing details</h5>

      <div className={styles.inputs}>
        <TextField
          label="Name on card"
          name="cardName"
          value={cardName}
          onChange={handleOnChange}
          required
        />

        <Checkbox
          checked={isAddressDifferent}
          label="Billing address different from contact address"
          onChange={({ target }) => setIsAddressDifferent(target.checked)}
        />

        {isAddressDifferent && (
          <>
            <Divider text="Billing address" />

            <Select
              label="Country"
              name="billingCountry"
              options={generateCountriesOptions()}
              value={billingCountry}
              onChange={handleOnChange}
              isSearchable
            />
            <Select
              label="State/Province"
              name="billingState"
              options={generateStatesOptions(billingCountry)}
              value={billingState}
              onChange={handleOnChange}
              isSearchable
            />
            <TextField
              label="City/Town"
              name="billingCity"
              value={billingCity}
              onChange={handleOnChange}
            />
            <TextField
              label="Address"
              name="billingAddress"
              value={billingAddress}
              onChange={handleOnChange}
            />
            <TextField
              label="Postal code"
              name="billingPostalCode"
              value={billingPostalCode}
              onChange={handleOnChange}
            />
          </>
        )}

        <div className={styles.footer}>
          <Button
            variant="Tertiary"
            type="button"
            onClick={previousStep}
            disabled={isLoading}
          >
            Previous
          </Button>
          <Button
            type="submit"
            loading={isLoading}
            disabled={
              !isFormValid(formData) ||
              (isAddressDifferent &&
                (!billingCountry ||
                  !billingState ||
                  !billingCity ||
                  !billingAddress ||
                  !billingPostalCode))
            }
          >
            Go to console
          </Button>
        </div>
      </div>
    </form>
  );
};

export default OnboardingBilling;
