import { operatingSysOptions } from "../data";

export const pluralize = (singularText: string, quantifier: number) => {
  return quantifier > 1 ? `${singularText}s` : singularText;
};

export const getOSLogo = (os: string) => {
  return operatingSysOptions.find(({ title }) => title === os)?.logo;
};

export const getMonthlyEstimate = (cost: number = 0) => {
  const HOURS_IN_MONTH = 720;

  return (cost * HOURS_IN_MONTH).toLocaleString();
};

export const formatFileSize = (sizeInBytes: number): string => {
  if (!sizeInBytes) return "-";

  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`;
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(2)} KB`;
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  } else {
    return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
};

export const getFileFormat = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf(".");

  if (
    lastDotIndex === -1 ||
    lastDotIndex === 0 ||
    lastDotIndex === fileName.length - 1
  ) {
    return "-";
  }

  return fileName.substring(lastDotIndex + 1).toUpperCase();
};

export const getFileName = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf(".");
  const lastSlashIndex = Math.max(
    fileName.lastIndexOf("/"),
    fileName.lastIndexOf("\\")
  );

  if (
    lastDotIndex === -1 ||
    lastDotIndex === 0 ||
    lastSlashIndex >= lastDotIndex
  ) {
    return fileName.substring(lastSlashIndex + 1);
  }

  return fileName.substring(lastSlashIndex + 1, lastDotIndex);
};
