import { Button, Callout, Modal, TextField } from "nebula-galaxy";
import { useState } from "react";

import styles from "./instances.module.scss";

interface DeleteInstanceProps {
  close: () => void;
  action: string;
  serverName: string;
  isLoading: boolean;
  submit: () => void;
}

const DeleteInstance = ({
  action,
  submit,
  close,
  isLoading,
  serverName,
}: DeleteInstanceProps) => {
  const [instanceName, setInstanceName] = useState("");

  return (
    <Modal
      className={styles.terminateModal}
      active={["delete", "terminate"].includes(action)}
      onClose={close}
      variant="Standard"
      title=""
      actions={
        <>
          <Button variant="Tertiary" size="SM" onClick={close}>
            Cancel
          </Button>

          <Button
            variant="Destructive"
            size="SM"
            onClick={submit}
            disabled={instanceName !== serverName}
            loading={isLoading}
          >
            <p><span className={styles.action}>{action}</span> instance</p>
          </Button>
        </>
      }
      hideHeader
    >
      <Callout
        variant="Warning"
        title={
          action === "delete"
            ? "Delete web server"
            : `Terminate '${serverName}' instance`
        }
        message={`Your ${action === "delete" ? 'web server' : serverName} instance will be permanently deleted. All data and resources associated with this instance will be removed. Please enter the instance name to confirm this action.`}
        isCloseVisible={false}
      />

      <TextField
        className={styles.textfield}
        variant="Filled"
        label="Confirm with instance name"
        name="instanceName"
        onChange={({ target }) => setInstanceName(target.value)}
        required
      />
    </Modal>
  );
};

export default DeleteInstance;
