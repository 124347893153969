import type { ReactNode } from "react";
import cn from "classnames";

import styles from "./page-header.module.scss";

interface PageHeaderProps {
  className?: string;
  title: ReactNode;
  description?: ReactNode;
}

const PageHeader = ({ className, title, description }: PageHeaderProps) => (
  <div className={cn(className, styles.container)}>
    <h5 className={cn("medium", styles.title)}>{title}</h5>
    {description && (
      <span className={cn("p5", styles.description)}>{description}</span>
    )}
  </div>
);

export default PageHeader;
