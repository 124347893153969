import { CopyElement, IObject } from "nebula-galaxy";

import moment from "moment";

import styles from "./key-pairs.module.scss";

export const keyPairsColumn = [
  { key: "name", header: "Name" },
  { key: "algorithm", header: "Type" },
  {
    key: "fingerprint",
    header: "Fingerprint",
    renderer: ({ fingerprint }: IObject) =>
      fingerprint ? (
        <CopyElement
          className={styles.copyText}
          element={fingerprint}
          value={fingerprint}
        />
      ) : (
        "Not available"
      ),
  },
  {
    key: "id",
    header: "ID",
    renderer: ({ keyPairId }: IObject) =>
      keyPairId ? (
        <CopyElement
          className={styles.copyText}
          element={keyPairId}
          value={keyPairId}
        />
      ) : (
        "Not available"
      ),
  },
  {
    key: "createdAt",
    header: "Date Created",
    renderer: ({ createdAt }: IObject) => (
      <>{moment(createdAt).format("MMM D, YYYY, h:mm A")}</>
  ),
  },
];
