import { useState } from "react";
import { MainLayout } from "layouts";
import { PageHeader } from "components";
import { getVPCs } from "services";
import { useReactQuery } from "hooks";
import { defaultQueryParams } from "utils/consts";
import type { QueryParams } from "utils/types";
import { Button, Table } from "nebula-galaxy";
import { useNavigate } from "react-router-dom";
import { pluralize } from "utils/functions";
import cn from "classnames";
import { routes } from "config";
import { VpcsEmptyStateIcon } from "assets";
import { useAuth } from "context";

import styles from "./vpcs.module.scss";
import { vpcsColumn } from "./columns";
import AddNewVPC from "./add-new-vpc/add-new-vpc";

const queryKey = "vpcs";

const Vpcs = () => {
    const navigate = useNavigate();
    const [isFormActive, setIsFormActive] = useState(false);

    const [queryParams, setQueryParams] = useState<QueryParams>({
        ...defaultQueryParams,
    });

    const { isLoading: isLoadingRegionProviders, regionProviders } =
    useAuth();

    const { data, isLoading } = useReactQuery({
        queryKey,
        queryParams,
        queryFunction: () => getVPCs(queryParams),
    });

    const vpcs = data?.docs;

    return (
        <MainLayout>
            <section className="page-content">
            <PageHeader
                title="Virtual private cloud"
            />

            {queryParams?.term || isLoading || vpcs?.length ? (
                <Table
                className=''
                columns={vpcsColumn}
                data={vpcs}
                loading={isLoading}
                pagination={data?.pagination}
                loadData={setQueryParams}
                onRowClick={({ vpcId }) =>
                    navigate(`${routes.console.nebCompute.vpcs}/${vpcId}`)
                }
                toolbarActions={
                    <>
                    <Button
                        size="SM"
                        leftIcon="Plus"
                        onClick={() => setIsFormActive(true)}
                        disabled={
                            isLoadingRegionProviders || !regionProviders?.length
                        }
                    >
                        Add new VPC
                    </Button>
                    </>
                }
                searchPlaceholder="Search VPC name or ID"
                dataText={pluralize("VPC", vpcs?.length)}
                isMultiSelect={false}
                />
            ) : (
                <div className={styles.emptyState}>
                    <span className={styles.emptyStateIcon}>
                    <VpcsEmptyStateIcon />
                    </span>

                    <div>
                    <span className="p3 medium">
                        You don't have any VPC created yet. 
                    </span>
                    <span className={cn("p5", styles.emptyStateDescription)}>
                    Details of all your VPCs will appear here once you create at least a VPC.
                    </span>
                    </div>
                    <Button
                        size="SM"
                        onClick={() => setIsFormActive(true)}
                    >
                    Create VPC
                    </Button>
                </div>
            )}

            {isFormActive && (
                <AddNewVPC
                    queryKey={queryKey}
                    providerId={regionProviders?.[0]?.providerId}
                    close={() => setIsFormActive(false)}
                />
            )}
            </section>
        </MainLayout>
    )
}

export default Vpcs;
