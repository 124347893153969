import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import cn from "classnames";

import type { IObject, SelectEvent } from "nebula-galaxy";
import { Button, Divider, Table, Modal } from "nebula-galaxy";

import { MainLayout } from "layouts";
import { DetailsPageContent, DetailsPageHeader } from "components";
import { routes } from "config";
import {
  fetchMember,
  removeMemberFromOrganization,
  removeMemberFromProject,
  updateProjects,
} from "services";
import { useAlert, useAuth } from "context";
import { useReactQuery } from "hooks";

import { memberProjectsColumn } from "./columns";
import { roles } from "utils/data";

import styles from "./access-manager.module.scss";

const queryKey = "member-profile";

const MemberProfile = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const { showAlert } = useAlert();
  const { userProfile } = useAuth();

  const { data, success, message, isLoading } = useReactQuery({
    queryKey,
    queryFunction: () => fetchMember(id as string),
  });

  const [formData, setFormData] = useState<IObject[]>([]);
  const [selectedProject, setSelectedProject] = useState<IObject | null>(null);
  const [isRemoveFromProjectActive, setIsRemoveFromProjectActive] =
    useState(false);
  const [isRemoveMemberActive, setIsRemoveMemberActive] = useState(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { user, projects } = data || {};
  const { fullName, email, userId } = user || {};

  const checkIfChanged = () => {
    return formData.some(({ projectId, role }) => {
      const project = projects.find(
        (prj: IObject) => prj.project.projectId === projectId
      );

      return project?.role.roleId !== role;
    });
  };

  const handleOnChange = ({ target }: SelectEvent, projectId: string) => {
    const { value } = target;

    const newFormData = formData.map((item) =>
      item.projectId === projectId ? { ...item, role: value as string } : item
    );
    setFormData(newFormData);
  };

  const onSuccess = (message: string) => {
    queryClient.invalidateQueries(queryKey);
    setIsRemoveFromProjectActive(false);
    setIsRemoveMemberActive(false);
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    setIsUpdating(false);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsUpdating(false);
  };

  const handleUpdateProjects = async () => {
    setIsUpdating(true);
    updateProjects(formData, onSuccess, onError);
  };

  const handleRemoveMemberFromProject = async () => {
    setIsUpdating(true);
    removeMemberFromProject(
      { projectId: selectedProject?.projectId, userId },
      onSuccess,
      onError
    );
  };

  const handleRemoveMemberFromOrganization = async () => {
    setIsUpdating(true);
    removeMemberFromOrganization(
      { userId },
      (message) => {
        onSuccess(message);
        navigate(routes.settings.accessManager);
      },
      onError
    );
  };

  useEffect(() => {
    setFormData(
      projects?.map(({ project, role }: IObject) => ({
        userId,
        projectId: project.projectId,
        role: role.roleId,
      })) || []
    );
  }, [projects, userId]);

  useEffect(() => {
    success === false && onError(message);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, success, message]);

  return (
    <MainLayout>
      <DetailsPageHeader
        breadcrumbs={[
          {
            label: "Access manager",
            url: routes.settings.accessManager,
            urlComponent: ({ url, label }) => (
              <Link to={url as string}>{label}</Link>
            ),
          },
          {
            label: fullName,
          },
        ]}
      />
      <DetailsPageContent
        className={styles.memberProfile}
        isLoading={isLoading}
      >
        {data && (
          <>
            <h5 className={cn("medium", styles.title)}>{fullName}</h5>
            <span className={cn("p5", styles.description)}>{email}</span>
            <Divider className={styles.divider} />

            {!!projects?.length && (
              <>
                <Table
                  className={styles.projectsTable}
                  columns={memberProjectsColumn(
                    { roles },
                    handleOnChange,
                    (project: IObject) => {
                      // open remove from project modal
                      setIsRemoveFromProjectActive(true);
                      setSelectedProject(project);
                    }
                  )}
                  data={projects}
                  loadData={() => null}
                  isMultiSelect={false}
                  isToolbarActive={false}
                />

                <Button
                  size="SM"
                  onClick={handleUpdateProjects}
                  loading={isUpdating}
                  disabled={!checkIfChanged()}
                >
                  Update
                </Button>
              </>
            )}

            {userId !== userProfile?.userId && (
              // prevents logged in user from deleting themselves
              <div className={styles.removeMemberContainer}>
                <span className={cn("p3 medium", styles.title)}>
                  Remove {fullName}
                </span>
                <span className={cn("p5", styles.description)}>
                  Remove <span className="medium">{fullName}</span> from your
                  organization
                </span>
                <Button
                  variant="Destructive"
                  size="SM"
                  className={styles.removeMemberAction}
                  onClick={() => setIsRemoveMemberActive(true)}
                >
                  Remove member
                </Button>
              </div>
            )}
          </>
        )}
      </DetailsPageContent>

      <Modal
        active={isRemoveFromProjectActive}
        onClose={() => setIsRemoveFromProjectActive(false)}
        variant="Warning"
        title="Remove from project"
        actions={
          <>
            <Button
              variant="Tertiary"
              size="SM"
              onClick={() => setIsRemoveFromProjectActive(false)}
            >
              Cancel
            </Button>

            <Button
              variant="Destructive"
              size="SM"
              onClick={handleRemoveMemberFromProject}
              loading={isUpdating}
            >
              Remove member
            </Button>
          </>
        }
      >
        <span className="p5">
          <span className="medium">{fullName}</span> will lose access to
          resources associated with{" "}
          <span className="medium">{selectedProject?.name}</span>. Are you sure
          you want to proceed?
        </span>
      </Modal>

      <Modal
        active={isRemoveMemberActive}
        onClose={() => setIsRemoveMemberActive(false)}
        variant="Warning"
        title={`Remove ${fullName}`}
        actions={
          <>
            <Button
              variant="Tertiary"
              size="SM"
              onClick={() => setIsRemoveMemberActive(false)}
            >
              Cancel
            </Button>

            <Button
              variant="Destructive"
              size="SM"
              onClick={handleRemoveMemberFromOrganization}
              loading={isUpdating}
            >
              Remove member
            </Button>
          </>
        }
      >
        <span className="p5">
          <span className="medium">{fullName}'s</span> Nebula account will be
          permanently deleted. They will be removed from all shared projects and
          organization. This action is irreversible. Are you sure you want to
          proceed?
        </span>
      </Modal>
    </MainLayout>
  );
};

export default MemberProfile;
