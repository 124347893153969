import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import cn from "classnames";

import { Icon, IObject, Select, SelectEvent } from "nebula-galaxy";
import { Button, Modal } from "nebula-galaxy";
import { useAlert } from "context";
import { addMemberToProject } from "services";

import { generateSelectOptions } from "utils/functions";
import { roles } from "utils/data";

import styles from "./project-details.module.scss";
import InviteMember from "../../access-manager/invite-member";

type FormData = {
  userId: string;
  role: string;
};

interface AddMemberProps {
  projectId: string;
  objectDetailsKey: string;
  membersKey: string;
  members?: IObject[];
  existingMembers?: IObject[];
  active: boolean;
  close: () => void;
}

const initialFormData: FormData[] = [
  {
    userId: "",
    role: "",
  },
];

const AddMember = ({
  projectId,
  objectDetailsKey,
  membersKey,
  members,
  existingMembers,
  active,
  close,
}: AddMemberProps) => {
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();

  const [formData, setFormData] = useState<FormData[]>([...initialFormData]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMemberFormActive, setIsMemberFormActive] = useState(false);

  const handleClose = () => {
    setFormData([...initialFormData]);
    close();
  };

  const addRow = () => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData.push(initialFormData[0]);

    setFormData(newFormData);
  };

  const removeRow = (index: number) => {
    setFormData(formData.filter((_, id) => id !== index));
  };

  const isFormValid = formData.every(item =>
    Object.values(item).every(value => value.trim() !== "")
  );
  
  const handleOnChange = ({ target }: SelectEvent, index: number) => {
    const { name, value } = target;
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData[index][name as keyof FormData] = value as string;

    setFormData(newFormData);
  };

  const onSuccess = (message: string) => {
    queryClient.invalidateQueries(objectDetailsKey);
    queryClient.invalidateQueries(membersKey);
    showAlert({
      variant: "Success",
      message,
      active: true,
    });
    handleClose();
    setIsLoading(false);
  };

  const onError = (message: string) => {
    console.error(message);
    showAlert({
      variant: "Critical",
      message,
      active: true,
    });
    setIsLoading(false);
  };

  const submit = () => {
    const body = formData.map((item) => ({ projectId, ...item }));

    setIsLoading(true);
    addMemberToProject(body, onSuccess, onError);
  };

  const validateForm = () => {
    return formData.every((item) =>
      Object.values(item).every((value) => !!value)
    );
  };

  const exemptedMembers = useMemo(() => {
    const existingMembersIds = existingMembers?.map(existing => existing.user.userId) || [];
    const selectedMembersIds = formData.map(form => form.userId);
    
    return [...existingMembersIds, ...selectedMembersIds];
  }, [existingMembers, formData]);
  

  const membersOptions = useMemo(
    () =>
      members?.map(({ fullName, email, userId }) => {
        const isDisabled = exemptedMembers.includes(userId);

        return {
          label: fullName || email,
          value: userId,
          disabled: isDisabled,
          display: (
            <div className={cn(styles.memberOption, {[styles.memberOptionDisabled]: isDisabled})}>
              <span className={cn("p5 medium", styles.memberFullName)}>
                {fullName || email}
              </span>
              <span className={cn("cap1", styles.memberEmail)}>{email}</span>
            </div>
          ),
        }
      }),
    [members, exemptedMembers]
  );

  return (
    <Modal
      className={styles.addMember}
      active={active}
      variant="Standard"
      title="Add team members"
      onClose={handleClose}
      actions={
        <>
          <Button variant="Tertiary" size="SM" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            size="SM"
            onClick={submit}
            loading={isLoading}
            disabled={!validateForm()}
          >
            Add team members
          </Button>
        </>
      }
    >
      <div className={styles.inputs}>
        {formData.map(({ userId, role }, index) => (
          <div key={`member-${index}`}>
            <Select
              variant="Outline"
              placeholder="Select team members"
              name="userId"
              value={userId}
              options={membersOptions}
              onChange={(e) => handleOnChange(e, index)}
              isSearchable
              actions={
                <Button size="SM" onClick={() => setIsMemberFormActive(true)}>
                  Invite member
                </Button>
              }
            />
            <Select
              variant="Outline"
              placeholder="Select project role"
              name="role"
              value={role}
              options={generateSelectOptions(roles, "id", "name")}
              onChange={(e) => handleOnChange(e, index)}
            />
            <div className={styles.removeButton}>
              <Button variant="SecondaryLinkButton" onClick={() => removeRow(index)}>
              {index > 0  &&
                <Icon name="Minus" className={styles.icon}/>
              }
              </Button>
            </div>
          </div>
        ))}

        <Button variant="Tertiary" size="SM" leftIcon="Plus" onClick={addRow} disabled={!isFormValid}>
          Add more
        </Button>
      </div>

      {isMemberFormActive && (
        <InviteMember
          queryKey={membersKey}
          close={() => setIsMemberFormActive(false)}
          isRerouteActive={false}
        />
      )}
    </Modal>
  );
};

export default AddMember;
