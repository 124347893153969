import { useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Table } from "nebula-galaxy";
import { useQueryClient } from "react-query";

import { routes } from "config";
import { MainLayout } from "layouts";
import { PageHeader } from "components";
import { useReactQuery } from "hooks";
import { fetchServers } from "services";
import { useAuth } from "context";

import type { QueryParams } from "utils/types";
import { pluralize } from "utils/functions";
import { INSTANCES_GUIDE_LINK, defaultQueryParams } from "utils/consts";

import CreateInstance from "./create-instance";
import { instancesColumn } from "./columns";

import { InstancesEmptyStateIcon } from "assets";

import styles from "./instances.module.scss";

const queryKey = "instances";

const Instances = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isFormActive, setIsFormActive] = useState(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    ...defaultQueryParams,
  });
  const { data, isLoading } = useReactQuery({
    queryKey,
    queryParams,
    queryFunction: () => fetchServers(queryParams),
  });

  const { isLoading: isLoadingRegionProviders, regionProviders } =
    useAuth();

  const instances = data?.docs;

  return (
    <MainLayout>
      <section className="page-content">
        <PageHeader
          title="Instances"
          description={
            <>
              Create and manage all your instances. To learn more about how
              instances work,{" "}
              <a href={INSTANCES_GUIDE_LINK} target="_blank" rel="noreferrer">
                read the guide.
              </a>
            </>
          }
        />

        {queryParams?.term || isLoading || instances?.length ? (
          <Table
            className={styles.membersTable}
            columns={instancesColumn}
            data={instances}
            loading={isLoading}
            pagination={data?.pagination}
            loadData={setQueryParams}
            onRowClick={({ serverId }) =>
              navigate(`${routes.console.nebCompute.instances}/${serverId}`)
            }
            toolbarActions={
              <>
                <Button
                  size="SM"
                  leftIcon="Plus"
                  onClick={() => setIsFormActive(true)}
                  disabled={
                    isLoadingRegionProviders || !regionProviders?.length
                  }
                >
                  Add new instance
                </Button>
                <Button
                  variant="SecondaryIcon"
                  size="SM"
                  onClick={() => queryClient.invalidateQueries(queryKey)}
                >
                  <Icon name="RotateCw" />
                </Button>
              </>
            }
            searchPlaceholder="Search instance name or IP address"
            dataText={pluralize("instance", instances?.length)}
            isMultiSelect={false}
          />
        ) : (
          <div className={styles.emptyState}>
            <span className={styles.emptyStateIcon}>
              <InstancesEmptyStateIcon />
            </span>

            <div>
              <span className="p3 medium">
                You don't have any servers created yet.
              </span>
              <span className={cn("p5", styles.emptyStateDescription)}>
                All your servers will appear here once you create at least one
                server.
              </span>
            </div>
            <Button
              size="SM"
              rightIcon="Zap"
              onClick={() => setIsFormActive(true)}
              disabled={isLoadingRegionProviders || !regionProviders?.length}
            >
              Launch server
            </Button>
          </div>
        )}

        {isFormActive && (
          <CreateInstance
            queryKey={queryKey}
            providerId={regionProviders?.[0]?.providerId}
            close={() => setIsFormActive(false)}
          />
        )}
      </section>
    </MainLayout>
  );
};

export default Instances;
