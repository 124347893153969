import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import type { IObject } from "nebula-galaxy";
import { Alert } from "nebula-galaxy";
import type { AlertProps } from "nebula-galaxy/dist/components/alert/alert";

interface AlertContextType {
  showAlert: (details: AlertProps) => void;
  clearAlert: () => void;
}

const AlertContext = createContext<AlertContextType>({
  showAlert: () => {},
  clearAlert: () => {},
});

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }: IObject) => {
  const location = useLocation();

  const [alertDetails, setAlertDetails] = useState<AlertProps>({
    active: false,
    variant: "Informational", // using this as a default because variant is required
    message: "",
  });

  const showAlert = (details: AlertProps) => {
    setAlertDetails(details);
  };

  const clearAlert = () => {
    setAlertDetails({
      ...alertDetails,
      active: false,
    });
  };

  useEffect(() => {
    clearAlert();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <AlertContext.Provider
      value={{
        showAlert,
        clearAlert,
      }}
    >
      {children}
      <Alert className="alert" onClose={clearAlert} {...alertDetails} />
    </AlertContext.Provider>
  );
};
