import { ChangeEvent, FormEvent, useEffect } from "react";
import { useMemo, useState } from "react";
import cn from "classnames";

import type { SelectEvent } from "nebula-galaxy";
import { Button, ButtonGroup, Icon } from "nebula-galaxy";

import type { FormObject, OnboardingForm } from "utils/types";
import { isFormValid } from "utils/functions";

import Business from "./business";
import Personal from "./personal";

import styles from "../onboarding.module.scss";

interface OnboardingContactProps {
  clearFormData: (type: OnboardingForm) => void;
  nextStep: () => void;
  formData: FormObject;
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement> | SelectEvent,
    valid: boolean
  ) => void;
  setAccountType: (accountType: string) => void;
}

const OnboardingContact = ({
  clearFormData,
  nextStep,
  formData,
  handleOnChange,
  setAccountType,
}: OnboardingContactProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = useMemo(() => {
    return [
      {
        type: "business",
        title: "Business",
        icon: "Briefcase",
        component: (
          <Business formData={formData} handleOnChange={handleOnChange} />
        ),
      },
      {
        type: "personal",
        title: "Personal",
        icon: "User",
        component: (
          <Personal formData={formData} handleOnChange={handleOnChange} />
        ),
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setAccountType(tabs[currentTab].type);
    nextStep();
  };

  return (
    <form onSubmit={submit}>
      <h5 className={cn("medium", styles.title)}>Add contact details</h5>

      <ButtonGroup variant="Neutral">
        {tabs.map(({ type, title, icon }, index) => (
          <Button
            className={cn({ "button--is-active": index === currentTab })}
            key={`tab-${type}`}
            variant="GroupButton"
            onClick={() => {
              setCurrentTab(index);
              currentTab !== index && clearFormData("contact");
            }}
            type="button"
          >
            <Icon name={icon as any} />
            <span>{title}</span>
          </Button>
        ))}
      </ButtonGroup>

      {tabs[currentTab].component}

      <div className={styles.footer}>
        <Button type="submit" disabled={!isFormValid(formData)}>
          Continue
        </Button>
      </div>
    </form>
  );
};

export default OnboardingContact;
